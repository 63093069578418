.contact-us-subject {
  height: 56px;
}

.contact-us-desc {
  height: 128px;
}

/** float container */
.contact-float-container {
  border-radius: 8px;
  border: 1px solid rgba(223, 227, 232, 1);
  /* background-color: rgb(55, 65, 81); */
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  margin-bottom: 16px;
  padding: 0 8px;
  position: relative;
  width: 100%;
  /** active label */
}
.contact-float-container input {
  /* border: none;
      font-size: 16px;
      outline: 0;
      padding: 16px 0 10px;
      width: 100%; */

  border: none;
  outline: 0;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  /* height: 56px; */
  width: 100%;
  margin-top: 16px;
}

.contact-float-container textarea {
  border: none;
  outline: 0;
  resize: none;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  height: 70%;
  margin-top: 16px;
  width: 100%;
}
.contact-float-container label {
  font-size: 16px;
  position: absolute;
  transform-origin: top left;
  transform: translate(0, 16px) scale(1);
  transition: all 0.1s ease-in-out;

  opacity: 1;
  color: rgba(21, 24, 27, 1);
  /* background-color: rgb(55, 65, 81); */
  font-family: "Graphik-Regular";
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.35px;
  text-align: left;
  line-height: 20px;
}
.contact-float-container.active label {
  transform: translate(0, 4px) scale(0.75);
}

.contact-float-container_dark {
  border-radius: 8px;
  /* border: 1px solid rgba(223,227,232, 1); */
  background-color: rgb(55, 65, 81);

  box-sizing: border-box;
  margin-bottom: 16px;
  padding: 0 8px;
  position: relative;
  width: 100%;
  /** active label */
}
.contact-float-container_dark input {
  /* border: none;
      font-size: 16px;
      outline: 0;
      padding: 16px 0 10px;
      width: 100%; */

  border: none;
  outline: 0;
  opacity: 1;
  /* background-color: rgba(255,255,255, 1); */
  background-color: rgb(55, 65, 81);
  color: white;
  /* height: 56px; */
  width: 100%;
  margin-top: 16px;
}
.contact-float-container_dark textarea {
  border: none;
  /* font-size: 16px; */
  outline: 0;
  /* padding: 16px 0 10px;
      width: 100%; */
  resize: none;
  opacity: 1;
  /* background-color: rgba(255,255,255, 1); */
  background-color: rgb(55, 65, 81);
  color: white;
  height: 70%;
  /* width: 937px; */
  margin-top: 16px;
  /* margin-left: 16px; */
  width: 100%;
}
.contact-float-container_dark label {
  font-size: 16px;
  position: absolute;
  transform-origin: top left;
  transform: translate(0, 16px) scale(1);
  transition: all 0.1s ease-in-out;

  opacity: 1;
  /* color: rgba(21,24,27,1); */
  color: rgba(255, 255, 255, 1);
  background-color: rgb(55, 65, 81);
  font-family: "Graphik-Regular";
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.35px;
  text-align: left;
  line-height: 20px;
}
.contact-float-container_dark.active label {
  transform: translate(0, 4px) scale(0.75);
}
.dark-contact-nav-tabs.active {
  background-color: rgb(33, 43, 54);
}

.hAXbWy {
  background-color: rgb(239, 242, 245);
  margin: 30px 0px 0px 0px !important;
  padding: 10px;
  height: 50px;
  display: flex;
  border-radius: 5px;
  -webkit-box-align: center;
  align-items: center;
}

.cFOIUY {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.bRDpfN {
  margin: 0px 0px 0px 20px;
  display: flex;
}

.dAhoyD {
  font-size: 12px;
  width: 100px;
}

.kbxoxF {
  color: rgb(145, 158, 171);
  font-size: 10px;
}

.la-dTuR {
  width: 350px;
}

.css-fbkt3g {
  font-weight: 500 !important;
  width: 42px;
  margin-right: 5px;
  margin-left: 5px;
  color: RGB(128, 0, 204);
  font-size: 32px;
}

.contact-us-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.support-text {
  margin-top: 24px;
  margin-left: 10px;
  font-size: 12px;
  width: 210px;
}

.cursor {
  cursor: pointer;
}

.cGODdJ {
  margin: 0px 10px 0px 0px;
}

.fJlQtE {
  background-color: transparent;
  padding: 5px;
  display: flex;
  border: 1px solid rgb(145, 158, 171);
  border-radius: 3px;
  -webkit-box-align: center;
  align-items: center;
}

.jzuRnk {
  font-size: 12px;
  margin: 0px 5px 0px 0px;
  width: 60px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.success-message {
  color: green !important;
  font-size: 12px !important;
  margin-top: 5px !important;
  margin-bottom: 0px !important;
}
