html,
body {
  height: 100%;
  margin: 0;
}

.landing-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 auto;
}
.landing-container .left-section {
  width: 72%;
  height: 100vh;
  display: block;
}
.landing-container .main-content {
  padding: 30px 0 0 48px;
  position: relative;
  height: calc(100% - 72px);
}
.landing-container .aboutSynops {
  display: flex;
  align-items: center;
}
.landing-container .aboutSynops h2 {
  color: rgb(33, 43, 54);
  font-size: 38px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.28px;
  margin: 0px;
}
.landing-container .aboutSynops p {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  margin: 0px;
}
.landing-container .aboutSynops img {
  width: 50px;
  height: 50px;
  margin-right: 2px;
}
.landing-container .titleInfo {
  line-height: 42px;
  padding-top: 4%;
}
.landing-container .titleInfo .demoLink {
  width: 220px;
  height: 48px;
  position: relative;
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  background: linear-gradient(
    -225deg,
    rgb(161, 0, 255) 0%,
    rgb(117, 0, 192) 52%,
    rgb(70, 0, 115) 100%
  );
  border-radius: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 48px;
  padding-left: 20px;
  line-height: 46px;
  margin-top: 12px;
}
.landing-container .titleInfo .demoLink:hover {
  background: linear-gradient(-225deg, rgb(117, 0, 192) 0%, rgb(161, 0, 255) 100%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}
span.demoLink i {
  position: absolute;
  right: 18px;
  font-size: 20px;
  line-height: 46px;
}
.landing-container .titleInfo .blackText {
  color: #454545;
  font-size: 42px;
  font-family: "graphik-super";
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: -1px;
}
.landing-container .titleInfo .blueText {
  color: rgb(1, 142, 255);
  font-size: 42px;
  font-family: "graphik-super";
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: -1px;
}
.landing-container .right-section {
  position: relative;
  width: 28%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: unset;
  margin-left: unset;
  padding: 0;
  flex: unset;
}
.landing-container .login-profile {
  /* height: 100px;
    position: absolute;
    width: 100%;
    top: 0px; */
  padding: 4% 0px;
  position: relative;
}
.landing-container .right-section::before {
  content: "";
  width: 1px;
  background: rgb(99, 115, 129);
  display: block;
  height: 100%;
  position: absolute;
}
.landing-container .footer-links {
  height: 72px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 28%);
}
/* .landing-container .footer-links::before {
  content: "";
  height: 1px;
  background: rgb(99, 115, 129);
  display: block;
  width: 100%;
  top: 0px;
} */
.landing-container .right-section-content {
  position: relative;
  height: 100%;
  /* position: absolute;
    top: 100px;
    width: 100%;
    height: calc(100% - 100px); */
}
.landing-container .right-section-content .login-profile::before {
  content: "";
  position: absolute;
  height: 1px;
  background: rgb(99, 115, 129);
  display: inline-block;
  width: 100%;
  bottom: 0px;
}
.landing-container .list-group-data ul {
  margin: 0px;
  padding-left: 26px;
  list-style: none;
}
.landing-container .list-group-data ul li {
  margin-bottom: 1%;
  line-height: 18px;
}
.landing-container .list-group-data ul li:last-child {
  margin-bottom: 0px;
}

.landing-container .list-group-data h2 {
  color: rgb(161, 0, 255);
  font-size: 16px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -0.32px;
  margin: 0px;
  position: relative;
  padding: 22px 0px 10px 26px;
  display: inline-block;
}
.landing-container .list-group-data h2 span {
  position: absolute;
  display: inline-block;
  margin-left: 18px;
  top: 16px;
}
.landing-container .list-group-data h2 span::after {
  content: "";
  background: url("../Images/link-arrow.png");
  display: inline-block;
  width: 24px;
  height: 24px;
}
.landing-container .list-group-data h2::before {
  background-color: rgb(0, 0, 0);
  height: 35px;
  width: 5px;
  content: "";
  left: -2px;
  display: block;
  position: absolute;
  top: 12px;
}
.landing-container .list-group-data ul li span {
  display: block;
}
.landing-container .list-group-data ul li span.topText {
  color: rgb(52, 59, 64);
  font-size: 13px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.31px;
}
.landing-container .list-group-data ul li span.bottomText {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Black;
  font-weight: 900;
  letter-spacing: 0px;
  position: relative;
}
.bottomText.chevron-icon-green::after {
  content: "";
  display: inline-block;
  position: absolute;
  background: url("../Images/chevron-icon-green.png");
  width: 20px;
  height: 20px;
  margin-left: 8px;
  top: 1px;
}
.bottomText.chevron-icon-orange::after {
  content: "";
  display: inline-block;
  position: absolute;
  background: url("../Images/chevron-icon-orange.png");
  width: 20px;
  height: 20px;
  margin-left: 8px;
  top: 1px;
}
.landing-container .profileInfo {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 26px;
}
.landing-container .profileInfo span.name {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
}
.landing-container .profileInfo i {
  color: rgb(99, 115, 129);
  margin-right: 6px;
  font-size: 20px;
}

.landing-container .list-group-data .hyperlink h2:hover {
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(1, 142, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.landing-container .list-group-data .hyperlink h2:hover::after {
  content: "";
  display: block;
  width: calc(100% - 27px);
  height: 1px;
  position: absolute;
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(1, 142, 255) 100%);
}
.clientJourneyOfferings {
  padding-top: 10%;
}
.clientJourneyOfferings ul {
  display: flex;
  margin: 0;
}

.clientJourneyOfferings ul li {
  padding-right: 80px;
  line-height: 34px;
}

.clientJourneyOfferings ul li span {
  display: block;
}

.clientJourneyOfferings ul li .topText {
  color: rgb(33, 43, 54);
  font-size: 32px;
  font-family: Graphik-Bold;
  font-weight: bold;
  letter-spacing: -0.35px;
}
.clientJourneyOfferings ul li .topText::after {
  content: "";
  display: block;
  width: 80px;
  height: 2px;
  background-color: rgb(1, 142, 255);
}

.clientJourneyOfferings ul li .bottomText {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: -0.35px;
}
.mySuccessStories {
  background: linear-gradient(-225deg, rgb(117, 0, 192) 0%, rgb(161, 0, 255) 100%);
  margin-top: 10px;
  padding-bottom: 5px;
}
.mySuccessStories h2 {
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -0.29px;
  padding: 16px 10px 16px 26px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mySuccessStories h2 .seeAll {
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.21px;
  /* padding-left: 28px; */
  position: relative;
  cursor: pointer;
  text-decoration: underline;
}
.mySuccessStories h2 .seeAll::after {
  /*  content: '';
  background: url(../Images/seeAll-Icon.svg);
  position: absolute;
  display: inline-block;
  width: 17px;
  height: 17px;
  top: -3px;
  right: -22px; */
}
.client-carousel-root {
  display: flex;
  padding: 0 26px 14px 26px;
  width: 100%;
}
.client-carousel-root .owl-carousel .owl-stage-outer {
  margin: 0px;
  padding: 0px;
}
.client-carousel-root .owl-carousel .owl-item .item {
  border: 1.5px solid rgb(255, 255, 255);
  border-radius: 8px;
  cursor: pointer;
  height: 135px;
  position: relative;
  background: linear-gradient(
    -225deg,
    rgb(161, 0, 255) 0%,
    rgb(117, 0, 192) 52%,
    rgb(70, 0, 115) 100%
  );
  /* width: 160px !important; */
  /* padding: 8px 8px 8px 10px; */
}
.client-carousel-root .owl-carousel .owl-item .item:hover {
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.4);
  background: linear-gradient(-225deg, rgb(117, 0, 192) 0%, rgb(161, 0, 255) 100%);
}
.client-carousel-root .owl-carousel {
  height: 135px;
}
.clientOffering-Sec {
  height: calc(100% - 72%);
  position: absolute;
  bottom: 0;
  width: 100%;
}
.client-carousel-root .owl-carousel .owl-nav [class*="owl-"],
.client-carousel-root .owl-carousel .owl-nav [class*="owl-"]:hover {
  background: transparent;
  box-shadow: none;
  right: -30px;
  transition-duration: 1s;
}
.client-carousel-root .owl-carousel .owl-nav .owl-next:before,
.client-carousel-root .owl-carousel .owl-nav .owl-prev:before {
  background: transparent url(../Images/landingHome-carousel-arrow.png) no-repeat scroll center;
}
.client-carousel-root .owl-carousel .owl-nav .owl-next:before {
  transform: rotate(180deg);
}
.client-carousel-root .owl-carousel .owl-nav .owl-prev:before {
  transform: rotate(0deg);
}
.client-carousel-root .owl-carousel .owl-nav .owl-prev {
  left: auto;
}
.client-carousel-root .owl-carousel .owl-nav [class*="owl-"]:hover {
  opacity: 1;
}
.client-carousel-root .owl-carousel .owl-nav [class*="owl-"].disabled {
  opacity: 0;
}
.client-carousel-root .owl-carousel .owl-item img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.client-carousel-root .owl-carousel .owl-item h4 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.25px;
  margin: 10px 0 0 0;
}
.clientName-Sec {
  padding: 8px 8px 8px 10px;
}
.clientOffering-Sec .offeringVal {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 10px;
}
.clientOffering-Sec::before {
  height: 1px;
  content: "";
  display: inline-block;
  width: 100%;
  background: rgb(215, 215, 215);
  position: absolute;
}

.clientOffering-Sec .offeringVal span {
  width: 21px;
  height: 21px;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.29px;
  color: rgb(161, 0, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
}

.OfferingPopupData {
  position: absolute;
  top: 0px;
  left: 0px;
  display: none;
  z-index: 9999;
  /* max-height: 242px; */
  /* height: 242px; */
  width: 242px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  border-radius: 8px;
  z-index: 9999 !important;
  padding: 4px;
}
.OfferingPopupData .wrapper {
  overflow: auto;
  padding: 4px 8px 8px 8px;
  height: 242px !important;
}
.OfferingPopupData::after {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  margin-top: -4px;
  border-right-style: solid;
  border-right-width: 6px;
  border-right-color: #fff;
  content: "";
  display: block;
  position: absolute;
  right: -6px;
  transform: rotate(180deg);
  bottom: 8px;
}
.OfferingPopupData .offering-tooltip .offeringtooltip-heading {
  border-bottom: 1px solid #ccc;
  padding: 5px;
}
.OfferingPopupData .offering-tooltip .offeringtooltip-heading h6 {
  color: #212b36;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.29px;
  margin-bottom: 0;
}
.OfferingPopupData .offeringtooltip-body {
  padding: 8px 10px;
}
.OfferingPopupData .offeringtooltip-body ul {
  margin: 5px 0;
}
.OfferingPopupData .offeringtooltip-body ul li {
  margin-bottom: 15px;
  display: flex;
}
.OfferingPopupData .offeringtooltip-body ul li img {
  margin-right: 8px;
}
.OfferingPopupData .offeringtooltip-body p {
  color: #212b36;
  font-family: graphik;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.31px;
  margin-bottom: 0;
}
.OfferingPopupData .offeringtooltip-body span {
  color: #637381;
  font-size: 12px;
}
.oferringpopup-overlay {
  position: fixed !important;
  max-width: 100% !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.footer-links .vip-links {
  padding: 22px 24px 22px 48px;
  display: flex;
  align-items: center;
}
.footer-links .vip-links ul {
  margin: 0px;
  display: flex;
}
.footer-links .vip-links ul li,
.footer-links .vip-links ul li a,
.footer-links .vip-links ul li a:hover,
.footer-links .vip-links ul li a:active {
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: -0.31px;
  padding-left: 16px;
  cursor: pointer;
}
.footer-links .vip-links ul li a,
.footer-links .vip-links ul li a:hover,
.footer-links .vip-links ul li a:active {
  padding-left: 0px;
}
.footer-links .vip-links img {
  position: relative;
  top: -7px;
}
/* Landing Page R4 CSS Start */
.landingpage-slider {
  margin-top: 2rem;
}
.landingpage-slider .carousel-indicators {
  bottom: -30px;
  margin: 0;
  justify-content: flex-start;
}
.landingpage-slider .carousel-indicators li {
  border-radius: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(78, 81, 84);
  height: 8px;
  width: 8px;
}
.landingpage-slider .carousel-indicators li.active {
  border: 1px solid #018eff;
  background: #018eff;
}
.landingpage-slider p {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  letter-spacing: -0.29px;
  line-height: 20px;
  height: 48px;
  display: flex;
  align-items: center;
}
.synops-carousel {
  width: 100%;
}
.synops-carousel .landingpage-slider {
  width: 40%;
}
.nav-item .notification-link .active-notification.homepage-notification i {
  color: #637381;
  margin: 0;
}
.assessment-notifylink .notification-link .active-notification.homepage-notification:after {
  top: -3px;
  right: 7px;
}
.landing-container .profileInfo > span {
  line-height: 17px;
}
/* Homepage Chat box new css stars here */
.chat-bot-wrapper.is-active {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.chat-bot-wrapper {
  position: fixed;
  right: 0;
  top: 160px;
  z-index: 9;
  transform: translateX(100%);
  -o-transition: transform 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: transform 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: transform 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95),
    -webkit-transform 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.chat-bot-wrapper .chat-bot-open {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0;
  outline: 0 !important;
  margin: 0;
  line-height: 16px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  text-align: left;
  color: #fff !important;
  /* background-color: rgba(57, 142, 246, 0.9); */
  background: linear-gradient(-225deg, rgb(70, 0, 115) 0%, rgb(42, 163, 229) 100%);
  opacity: 0.9;
  font-family: "Graphik LC Web", Arial, Helvetica, sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px;
  padding: 7px;
  border-bottom: 0 !important;
  z-index: 1;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
}
.chat-bot-wrapper .chat-bot-open:hover,
.chat-bot-wrapper .chat-bot-open:focus {
  background: linear-gradient(-225deg, rgb(70, 0, 115) 0%, rgb(42, 163, 229) 100%);
  opacity: 1;
}
.chat-bot-wrapper .chat-bot-open::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 7px;
  background-image: url(../Images/Synops-navbar-logo.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
.chat-bot-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  animation: chatanimate 1s ease-out;
  animation-iteration-count: infinite;
  box-shadow: 0 0 0 20px #398ef6;
  animation-delay: 0.1s;
  z-index: -1;
  display: none;
}
.chat-bot-wrapper:hover::after {
  display: block;
}
.chat-bot-wrapper .chat-bot-close {
  position: absolute;
  outline: 0 !important;
  padding: 10px 0 10px 10px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  top: 0;
  right: 0;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: -webkit-transform 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -o-transition: transform 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: transform 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: transform 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95),
    -webkit-transform 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

@keyframes chatanimate {
  0% {
    opacity: 0;
    transform: scale(0.1, 0.1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1, 1);
  }
}
/* Homepage Chat box new css ends here */

/* Feedback form css starts here */
.feedback-form-main {
  display: inline-flex;
  position: fixed;
  bottom: 2.8%;
  right: 29.5%;
}
.feedback-form-main .feedback-form-active span.feedback-item,
.feedback-form-main .feedback-form-inactive span {
  width: 40px;
  height: 40px;
  background: #7600c0;
  /* border: 1px solid rgb(161, 0, 255); */
  box-shadow: 0px 0px 12px 0px rgba(176, 176, 176, 0.5);
  display: inline-flex;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.feedback-form-main .feedback-form-active span.feedback-item span::before,
.feedback-form-main .feedback-form-inactive span::before {
  position: absolute;
  content: "";
  display: inline-flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.feedback-form-main .feedback-form-active {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 16px;
}
.feedback-form-main .feedback-form-active span.feedback-item {
  margin-left: 16px;
}

.feedback-form-main .feedback-form-active span.feedback-item.expand:hover {
  width: auto;
  border-radius: 20px;
  box-shadow: 0px 0px 12px 0px rgba(176, 176, 176, 0.5);
}
.feedback-form-main .feedback-form-active span.feedback-item .chatbot-icon span,
.feedback-form-main .feedback-form-active span.feedback-item .feedback-form-icon span {
  display: none;
}
.feedback-form-main .feedback-form-active span.feedback-item:hover .chatbot-icon span,
.feedback-form-main .feedback-form-active span.feedback-item:hover .feedback-form-icon span {
  display: inline-block;
}
.feedback-form-main .feedback-form-active span.feedback-item:hover .chatbot-icon,
.feedback-form-main .feedback-form-active span.feedback-item:hover .feedback-form-icon {
  width: 100%;
  padding-right: 20px;
  padding-left: 40px;
  border-radius: 0;
  display: flex;
  align-items: center;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 20px;
}
.feedback-form-main .feedback-form-active span.feedback-item:hover span.chatbot-icon::before {
  left: 12px;
  right: unset;
}
/* .feedback-form-main .feedback-form-active span.feedback-item:hover span.feedback-form-icon::before {
  left: 12px;
  right: unset;
} */
.feedback-form-main .feedback-icon::before {
  background: url("../Images/comment-icon.svg") no-repeat;
  width: 20px;
  height: 20px;
}
.feedback-form-main span.feedback-item .chatbot-icon::before {
  background: url("../Images/chatbot-icon.svg") no-repeat;
  width: 24px;
  height: 24px;
}
.feedback-form-main span.feedback-item .feedback-form-icon::before {
  background: url("../Images/feedback-form-icon-new.svg") no-repeat;
  background-size: cover;
  width: 56px;
  height: 56px;
  /* filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(450%) contrast(100%); */
}
.feedback-form-main span.feedback-item .feedback-icon-close::before {
  background: url("../Images/feedback-icon-close.svg") no-repeat;
  width: 14px;
  height: 14px;
}
.feedbackFormModal .modal-dialog {
  max-width: 75%;
}
.feedbackFormModal .modal-body {
  padding: 26px 32px 14px;
  max-height: calc(90vh - 128px);
  overflow-y: auto;
  overflow-x: hidden;
}
.feedbackFormModal .modal-content {
  background: rgb(255, 255, 255);
  box-shadow: 2px 0px 24px 0px rgba(33, 43, 54, 0.1), 1px 0px 0px 0px rgb(244, 246, 248);
  border-radius: 16px;
  height: 90vh;
  overflow: visible;
}
.feedbackFormModal .modal-content .modal-header {
  height: 56px;
  background: rgb(128, 0, 204);
  border-radius: 16px 16px 0px 0px;
  padding: 0 0 0 66px;
  display: flex;
  align-items: center;
}
.feedbackFormModal .modal-content .modal-header .close {
  background: url("../Images/close-white.svg") no-repeat;
  margin: auto;
  top: 0;
  bottom: 0;
}
.feedbackFormModal .modal-content .modal-header .close:hover {
  background-color: transparent;
  opacity: 0.9;
}
.feedbackFormModal .modal-content .modal-header .modal-title {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.33px;
  position: relative;
}
.feedbackFormModal .modal-content .modal-header .modal-title::before {
  position: absolute;
  content: "";
  display: inline-flex;
  left: -42px;
  top: 0;
  bottom: 0;
  width: 26px;
  height: 24px;
  margin: auto;
  background: url("../Images/feedback-white.svg") no-repeat;
}
.feedbackFormModal .modal-content .modal-header button,
.feedbackFormModal .modal-content .modal-header button:hover,
.feedbackFormModal .modal-content .modal-header button:active {
  color: #ffff;
  outline: none;
}
.feedbackFormModal .modal-content .modal-footer {
  height: 72px;
  background-color: rgb(244, 246, 248);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 32px;
  flex-wrap: nowrap;
}
.feedbackFormModal .modal-content .modal-footer .success-feedback {
  height: 72px;
  background-color: rgb(244, 246, 248);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feedbackFormModal .modal-content .modal-footer p {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
  padding: 0 32px;
}
.feedbackFormModal .modal-content .modal-footer p span {
  font-weight: 600;
}
.feedbackFormModal .modal-content .modal-footer button,
.feedbackFormModal .modal-content .modal-footer button:hover,
.feedbackFormModal .modal-content .modal-footer button:active {
  background: rgb(161, 0, 255);
  border-radius: 20px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 14px;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 13px 24px;
}
.feedbackFormModal .modal-content .modal-footer .btn.disabled {
  opacity: 0.3;
  background: rgb(161, 0, 255);
}
.feedbackFormModal .modal-content .modal-footer button:active:focus,
.feedbackFormModal .modal-content .modal-footer button:focus,
.feedbackFormModal .modal-content .modal-footer button:hover {
  outline: none;
  box-shadow: none;
  background: rgb(179, 51, 255);
}
.feedback-form-wrapper p {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 22px;
}
.feedbackFormModal .tabs-container ul.nav-pills li {
  width: calc(33.33% - 22px);
  height: 84px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.15);
  border-radius: 8px;
  margin: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.feedbackFormModal .tabs-container ul.nav-pills li:first-child {
  margin-left: 0;
}
.feedbackFormModal .tabs-container ul.nav-pills li:last-child {
  margin-right: 0;
}
.feedbackFormModal .tabs-container ul.nav-pills li span {
  display: none;
}
.feedbackFormModal .tabs-container ul.nav-pills li:hover span {
  position: absolute;
  background: #212b36;
  padding: 14px 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  z-index: 999 !important;
  top: 84px;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}
.feedbackFormModal .tabs-container ul.nav-pills li:hover span:after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: #000;
  transform: rotate(180deg);
}
.feedbackFormModal .tabs-container .nav-pills .nav-link {
  width: 100%;
  line-height: 60px;
  padding: 0px;
  color: rgb(99, 115, 129) !important;
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.35px;
  border-radius: 8px !important;
  padding-top: 24px;
  position: relative;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link.active {
  border-left: none;
  background-color: rgb(255, 255, 255) !important;
  color: rgb(33, 43, 54) !important;
  font-size: 16px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.35px;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link::after,
.feedbackFormModal .tabs-container .nav-pills .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 4px;
  background: rgb(161, 0, 255);
  border-radius: 0px 0px 4px 4px;
  left: 0;
  right: 0;
  width: 0%;
  margin: auto;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link:hover::after,
.feedbackFormModal .tabs-container .nav-pills .nav-link.active::after {
  transition: width 0.5s;
  width: 100%;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link::before {
  position: absolute;
  content: "";
  display: inline-flex;
  width: 24px;
  height: 24px;
  left: 0;
  right: 0;
  top: 16px;
  margin: auto;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link.general::before {
  background: url("../Images/general-inactive.svg") no-repeat;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link.general.active::before {
  background: url("../Images/general-active.svg") no-repeat;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link.enhancement::before {
  background: url("../Images/enhancement-inactive.svg") no-repeat;
  top: 24px;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link.enhancement.active::before {
  background: url("../Images/enhancement-active.svg") no-repeat;
  top: 24px;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link.newidea::before {
  background: url("../Images/newidea-inactive.svg") no-repeat;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link.newidea.active::before {
  background: url("../Images/newidea-active.svg") no-repeat;
}
.feedbackFormModal .tab-content .tab-pane h2 {
  color: rgb(161, 0, 255);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  margin: 0px 0px 6px 0px;
  padding-top: 10px;
}
.feedbackFormModal .tab-content .rating-star {
  margin-bottom: 10px;
}
.feedbackFormModal .tab-content .rating-star span {
  margin-right: 26px;
  cursor: pointer;
  position: relative;
  width: 26px;
  height: 26px;
  display: inline-flex;
}
.sup-rating {
  top: 0px;
  color: #aa0025;
  font-size: 16px;
  font-weight: bold;
}
.feedbackFormModal .tab-content .rating-star span::after {
  content: "";
  display: inline-flex;
  background: url("../Images/star-inactive.svg");
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  width: 26px;
  height: 26px;
}
.feedbackFormModal .tab-content .rating-star span.checked::after {
  content: "";
  display: inline-flex;
  background: url("../Images/star-active.svg");
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  width: 26px;
  height: 26px;
}
.feedbackFormModal .tab-content .form-check .form-check-label {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  cursor: pointer;
}
.feedbackFormModal .tab-content .form-check.checkbox-checked .form-check-label {
  font-family: Graphik-Medium;
  font-weight: 500;
}
.feedbackFormModal .tab-content label.form-check {
  padding: 0px 0 8px 30px;
  margin-top: 8px;
}
.feedbackFormModal .tab-content .form-check .checkmark {
  top: -7px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.feedbackFormModal .tab-content .form-check .checkmark:after {
  left: 8.5px;
  top: 2px;
  width: 6px;
  height: 14px;
}
.form-wrapper .form-group textarea {
  height: 88px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 22px;
  resize: none;
  border-radius: 4px;
  border: 1.5px solid #ced4da;
}
.form-wrapper .form-group label {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: graphik;
  font-weight: 100;
  letter-spacing: -0.35px;
  margin-bottom: 2px;
}
.feedbackFormModal .border-group .form-control {
  resize: none !important;
}
.form-wrapper .form-group textarea:focus {
  background: rgb(244, 251, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border: 1.5px solid rgb(1, 142, 255);
}
.feedback-form-wrapper .form-group label sup {
  color: #aa0025;
  font-size: 16px;
  top: 0;
}
.custom-dropdown-search {
  /* position: relative; */
  display: flex;
  align-items: center;
}

.custom-dropdown-search .search-select-list {
  width: calc(100% - 24px);
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 56px;
  left: 12px;
  z-index: 11111;
  border: none;
}

.search-select-list ul {
  max-height: 281px;
  overflow: auto;
}

.search-select-list .noclients-message {
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  padding: 10px 15px;
}

.custom-dropdown-search ul {
  margin: 0;
}

.custom-dropdown-search ul li {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  padding: 10px 12px 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.custom-dropdown-search ul li:hover {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
}

.custom-dropdown-search-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.feedbackFormModal .custom-dropdown-search-overlay {
  position: absolute;
}

.custom-dropdown-search .custom-dropdownname {
  position: relative;
  width: 100%;
  font-size: 0.85rem;
  cursor: pointer;
  padding-right: 20px;
}
.custom-dropdownname span {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
}
.custom-dropdown-search .custom-dropdownname:after {
  content: "\F078";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  right: 5px;
  top: 0px;
}
.customselectfilter-footer {
  background: #f4f6f8;
  display: flex;
  justify-content: space-between;
  padding: 1em;
}
.customselectfilter-footer .btn {
  border-radius: 16px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  width: 65px;
}
.feedback-form-wrapper .border-group label.form-label {
  color: #637381;
  font-size: 11px;
  padding-top: 5px;
}
.feedbackFormModal .tab-content .search-select-list .form-check .checkmark {
  width: 20px;
  height: 20px;
  top: -4px;
}
.feedbackFormModal .tab-content .search-select-list .form-check .checkmark:after {
  left: 6px;
  top: 1px;
  width: 6px;
  height: 12px;
}
.feedbackFormModal .tab-content .custom-dropdown-search ul li:hover .form-check .form-check-label {
  color: #212b36;
  font-family: Graphik-Medium;
  font-weight: 500;
}
.customfile-upload {
  position: relative;
  height: 20px;
}
.customfile-uploadlink {
  position: relative;
}
.customfile-uploadlink:after {
  content: "\f0c6";
  font-family: "Font awesome\ 5 Pro";
  font-weight: 700;
  position: absolute;
  right: 8px;
  top: 23px;
  color: #919eab;
}
.customfile-upload input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 30px;
}
.customfile-upload .btn {
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  padding: 0;
  cursor: pointer;
}
.sucess-feedback-msg {
  height: 370px;
  padding: 22% 30%;
}
.sucess-feedback-msg p {
  color: rgb(99, 115, 129);
  font-size: 18px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.39px;
  line-height: 22px;
  position: relative;
}
.sucess-feedback-msg p:first-child::before {
  content: "";
  position: absolute;
  background: url("../Images/check-circle.svg");
  left: -56px;
  top: 0;
  width: 40px;
  height: 40px;
}
.sucess-feedback-msg p.click-here {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 18px;
}
.sucess-feedback-msg p.click-here a,
.sucess-feedback-msg p.click-here a:active,
.sucess-feedback-msg p.click-here a:hover {
  color: rgb(1, 142, 255);
  font-size: 14px;
  font-family: graphik;
  text-decoration: underline;
  cursor: pointer;
}
.feedbackFormModal .form-group.border-group:focus-within {
  background: rgb(244, 251, 255);
  border: 1.5px solid rgb(1, 142, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.feedbackFormModal .form-group.border-group input:focus-within,
.feedbackFormModal .form-group.border-group textarea:focus-within {
  background: rgb(244, 251, 255);
}
.feedbackFormModal .form-group.border-group label:focus-within {
  color: rgb(1, 142, 255);
}
.feedbackFormModal .border-group.onFocusColor .form-control {
  background-color: transparent;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
}
.feedback-form-wrapper .form-section .form-group {
  position: relative;
}
.feedback-form-wrapper .featuresearch-list {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  width: 101%;
  position: absolute;
  top: 61px;
  left: -1px;
  z-index: 11111;
}
.feedback-form-wrapper .featuresearch-list ul {
  margin: 16px 0 8px;
  max-height: 140px;
  overflow-y: auto;
}
.feedback-form-wrapper .featuresearch-list ul li {
  padding: 8px 12px;
}
.feedback-form-wrapper .featuresearch-list .categoryfilter-footer {
  position: relative;
}
/* Feedback form css ends here */
/* Landing Page R4 CSS End */
@media screen and (min-width: 960px) and (max-width: 1366px) {
  .landing-container {
    width: 100%;
  }
  .landing-container .aboutSynops h2 {
    font-size: 32px;
  }
  .landing-container .aboutSynops p {
    font-size: 14px;
  }
  .landing-container .list-group-data:first-child {
    padding-top: 6px;
  }
  .landing-container .list-group-data h2::before {
    top: 5px;
    height: 30px;
  }
  .landing-container .login-profile {
    padding: 3% 0px;
  }
  .landing-container .list-group-data h2 {
    font-size: 16px;
  }
  .landing-container .list-group-data ul li span.topText {
    font-size: 12px;
  }
  .landing-container .list-group-data ul li span.bottomText {
    font-size: 12px;
    line-height: 18px;
  }
  .landing-container .list-group-data h2 {
    padding: 12px 0px 6px 26px;
  }
  .landing-container .list-group-data ul li {
    margin-bottom: 0px;
  }
  .landing-container .titleInfo .blueText,
  .landing-container .titleInfo .blackText {
    font-size: 32px;
    line-height: 32px;
  }
  .landing-container .titleInfo {
    line-height: 42px;
    padding-top: 4%;
  }
  .clientJourneyOfferings {
    padding-top: 10%;
  }
  .landing-container .titleInfo .demoLink {
    margin-top: 12px; /* As per R4 Implementation */
    font-size: 15px;
    width: 220px;
    display: inline-block;
    height: 46px;
  }
  .clientJourneyOfferings ul li {
    line-height: 30px;
    padding-right: 70px;
  }
  .clientJourneyOfferings ul li .topText {
    font-size: 28px;
  }
  .clientJourneyOfferings ul li .bottomText {
    font-size: 14px;
  }
  .landing-container .list-group-data h2 span {
    top: 8px;
  }
  .mySuccessStories h2 {
    font-size: 14px;
    padding: 14px 10px 14px 26px;
    margin: 0;
  }
  .client-carousel-root .owl-carousel .owl-item .item,
  .client-carousel-root .owl-carousel {
    height: 135px;
    /* width: 147px !important; */
  }
  .client-carousel-root .owl-carousel .owl-item h4 {
    font-size: 12.5px;
  }
  .clientOffering-Sec .offeringVal {
    font-size: 12.5px;
  }
  .clientOffering-Sec .offeringVal span {
    width: 20px;
    height: 20px;
    font-size: 12.5px;
  }
  .client-carousel-root .owl-carousel .owl-item h4 {
    margin-top: 6px;
  }
  .assessment-notification-modal.notification-modal .modal-dialog {
    top: 40px;
  }
}
.form-section .character-form-label {
  position: relative;
  top: -12px;
  right: 0;
  display: block;
  text-align: right;
  font-size: 11px;
}
/* New Landing Home  UI Revamp CSS starts here */
.myclient-stories {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.myclient-stories .seeall-sec {
  width: 93%;
  cursor: auto;
}
.myclient-stories .custom-headerdropdown .dropdown-toggle {
  background-color: transparent !important;
  padding: 6px;
  cursor: pointer;
  margin-right: 8px;
}
.myclient-stories .custom-headerdropdown .dropdown-toggle:focus,
.myclient-stories .custom-headerdropdown .dropdown-toggle:active {
  background-color: transparent !important;
}
.myclient-stories .custom-headerdropdown .dropdown-toggle::after {
  border: none;
  content: "";
  background: url(../Images/ellipses-white.svg);
  width: 16px;
  height: 4px;
  display: inline-block;
  transform: rotate(90deg);
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.myclient-stories .custom-headerdropdown .dropdown-menu a,
.header-searchbar .clientprojects-dropdown .dropdown-menu a {
  padding-left: 36px;
  font-family: "graphik";
  position: relative;
}
.myclient-stories .custom-headerdropdown .dropdown-menu a.active,
.myclient-stories .custom-headerdropdown .dropdown-menu a:hover,
.offering-dropdownsubmenu .dropdown-menu a:hover,
.offering-dropdownsubmenu .dropdown-menu a.active {
  font-family: "graphik-medium";
}
.myclient-stories .custom-headerdropdown.show .dropdown-toggle,
.myclient-stories .custom-headerdropdown.show .dropdown-toggle:active:focus,
.myclient-stories .custom-headerdropdown .dropdown-toggle:active:focus {
  background-color: transparent !important;
}
.myclient-stories .custom-headerdropdown .dropdown-menu a::before,
.header-searchbar .clientprojects-dropdown .dropdown-menu a::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  top: 0;
  bottom: 0;
  left: 12px;
  margin: auto;
}
.myclient-stories .custom-headerdropdown .dropdown-menu a.addClient::before,
.header-searchbar .clientprojects-dropdown .dropdown-menu a.addClient::before {
  background: url("../Images/addnew-grey.svg");
}
.myclient-stories .custom-headerdropdown .dropdown-menu a.addClient.active::before,
.myclient-stories .custom-headerdropdown .dropdown-menu a.addClient:hover::before,
.header-searchbar .clientprojects-dropdown .dropdown-menu a.addClient.active::before,
.header-searchbar .clientprojects-dropdown .dropdown-menu a.addClient:hover::before {
  background: url("../Images/addnew-hover.svg");
}
.myclient-stories .custom-headerdropdown .dropdown-menu a.viewClient::before,
.header-searchbar .clientprojects-dropdown .dropdown-menu a.viewClient::before {
  background: url("../Images/drafts-grey.svg") no-repeat;
}
.myclient-stories .custom-headerdropdown .dropdown-menu a.gotoConfig::before {
  background: url("../Images/Goto-Config-Grey.svg") no-repeat;
}
.myclient-stories .custom-headerdropdown .dropdown-menu a.viewClient.active::before,
.myclient-stories .custom-headerdropdown .dropdown-menu a.viewClient:hover::before,
.header-searchbar .clientprojects-dropdown .dropdown-menu a.viewClient.active::before,
.header-searchbar .clientprojects-dropdown .dropdown-menu a.viewClient:hover::before {
  background: url("../Images/drafts-hover.svg") no-repeat;
}
.myclient-stories .custom-headerdropdown .dropdown-menu a.gotoConfig.active::before,
.myclient-stories .custom-headerdropdown .dropdown-menu a.gotoConfig:hover::before {
  background: url("../Images/Go-to-config.svg") no-repeat;
}
.clientstories-modal .modal-dialog {
  max-width: 1084px;
}
.clientstories-modal .modal-body {
  padding: 1rem;
}
.clientstories-modal .modal-content {
  min-height: 510px;
}
.header-searchbar {
  display: flex;
  align-items: center;
  padding: 1.5rem;
}
.header-searchbar h4 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.44px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.header-searchbar .searchFilter input[type="search"] {
  width: 225px !important;
}
.header-searchbar .close {
  font-size: 2rem;
  outline: none;
}
.clientprojects-dropdown.custom-headerdropdown .dropdown-toggle,
.clientprojects-dropdown.custom-headerdropdown.show .dropdown-toggle {
  background: rgb(161, 0, 255) !important;
  border-radius: 20px;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0.8rem 2rem 0.8rem 1.5rem;
  display: flex;
  align-items: center;
  height: 40px;
}
.clientprojects-dropdown.custom-headerdropdown .dropdown-toggle:hover,
.clientprojects-dropdown.custom-headerdropdown .dropdown-toggle:active,
.clientprojects-dropdown.custom-headerdropdown .dropdown-toggle:focus {
  opacity: 1;
  background: rgb(161, 0, 255) !important;
  box-shadow: none !important;
  color: #ffffff !important;
}
.clientprojects-dropdown.custom-headerdropdown .dropdown-toggle:hover {
  background: rgb(179, 51, 255) !important;
}

.clientprojects-dropdown.custom-headerdropdown .dropdown-toggle:after {
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  position: absolute;
  top: 13px;
  bottom: 0;
  right: 18px;
}
.clientfilters-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
}
.clientfilters-dropdownname,
.clientfilters-dropdown.sort-icondropdown {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(196, 205, 213);
  height: 32px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.clientfilters-dropdownname:hover,
.clientfilters-dropdown.sort-icondropdown:hover {
  border: 1px solid rgb(161, 0, 255);
  color: rgb(161, 0, 255);
}
.clientfilters-dropdownname:hover .settings-icon,
.clientfilters-dropdown.sort-icondropdown:hover .settings-icon,
.clientfilters-dropdown.custom-headerdropdown:hover .dropdown-toggle {
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(1, 142, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.clientfilters-dropdownname.active,
.clientfilters-dropdown.sort-icondropdown.active {
  border: 1.5px solid rgb(161, 0, 255);
  color: rgb(161, 0, 255);
}
.clientfilters-dropdownname.active .settings-icon,
.clientfilters-dropdown.sort-icondropdown.active .settings-icon,
.clientfilters-dropdown.custom-headerdropdown.active .dropdown-toggle {
  color: rgb(161, 0, 255);
}
.clientfilters-dropdown.custom-headerdropdown .dropdown-menu {
  left: -16px !important;
  min-width: 232px;
}
.offering-dropdownsubmenu .dropdown-menu {
  min-width: 232px;
  padding: 16px;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu {
  max-height: 258px;
  overflow-y: auto;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu span {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu span:after {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  top: 0;
  bottom: 0;
  left: 0px;
  margin: auto;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu a {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  padding: 0.75rem 0.75rem 0.75rem 2rem;
  letter-spacing: -0.25px;
  line-height: 20px;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu span.network-icon:after {
  background: url("../Images/ClientOfferingIcons/Network.svg") no-repeat;
  background-size: cover;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu a:hover span.network-icon:after {
  background: url("../Images/ClientOfferingIcons/Network-gradient.svg") no-repeat;
  background-size: cover;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu span.banking-icon:after {
  background: url("../Images/ClientOfferingIcons/PlatformExperience.svg") no-repeat;
  background-size: cover;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu a:hover span.banking-icon:after {
  background: url("../Images/ClientOfferingIcons/PlatformExperience-gradient.svg") no-repeat;
  background-size: cover;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu span.utilities-icon:after {
  background: url("../Images/ClientOfferingIcons/Utilities.svg") no-repeat;
  background-size: cover;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu a:hover span.utilities-icon:after {
  background: url("../Images/ClientOfferingIcons/Utilities-gradient.svg") no-repeat;
  background-size: cover;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu span.health-icon:after {
  background: url("../Images/ClientOfferingIcons/Health.svg") no-repeat;
  background-size: cover;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu a:hover span.health-icon:after {
  background: url("../Images/ClientOfferingIcons/Health-gradient.svg") no-repeat;
  background-size: cover;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu span.experience-icon:after {
  background: url("../Images/ClientOfferingIcons/PlatformExperience.svg") no-repeat;
  background-size: cover;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu a:hover span.experience-icon:after {
  background: url("../Images/ClientOfferingIcons/PlatformExperience-gradient.svg") no-repeat;
  background-size: cover;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu span.insurance-icon:after {
  background: url("../Images/ClientOfferingIcons/Insurance.svg") no-repeat;
  background-size: cover;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu a:hover span.insurance-icon:after {
  background: url("../Images/ClientOfferingIcons/Insurance-gradient.svg") no-repeat;
  background-size: cover;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu span.compliance-icon:after {
  background: url("../Images/ClientOfferingIcons/PlatformExperience.svg") no-repeat;
  background-size: cover;
}
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu a:hover span.compliance-icon:after {
  background: url("../Images/ClientOfferingIcons/PlatformExperience.svg") no-repeat;
  background-size: cover;
}
.offering-dropdownsubmenu .dropdown-menu.parent-menu {
  left: -100px !important;
}
.offering-dropdownsubmenu .dropdown-submenu:after {
  content: "\f0da";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  color: rgb(99, 115, 129);
  right: 16px;
  top: 16px;
  z-index: -1;
}
.offering-dropdownsubmenu .dropdown-submenu.seeall-menuitem:after {
  display: none;
}
.clientfilters-dropdownname .settings-icon {
  padding-left: 1.5rem;
  position: relative;
  padding-right: 1rem;
}
.clientfilters-dropdownname .settings-icon:before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  top: 0;
  bottom: 0;
  left: 0px;
  margin: auto;
  background: url("../Images/Offering-icon-normal.svg") no-repeat;
  background-size: cover;
}
.clientfilters-dropdown.sort-icondropdown .dropdown-toggle:before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  top: 0;
  bottom: 0;
  left: 0px;
  margin: auto;
  background: url("../Images/sort-icon-normal.svg") no-repeat;
}
.clientfilters-dropdown.sort-icondropdown:hover .dropdown-toggle:before,
.clientfilters-dropdown.sort-icondropdown.active:hover .dropdown-toggle:before {
  background: url("../Images/sort-icon-gradient.svg") no-repeat;
}
.clientfilters-dropdown.sort-icondropdown.active .dropdown-toggle:before {
  background: url("../Images/sort-icon-purple.svg") no-repeat;
}

.clientfilters-dropdownname .settings-icon:after {
  position: absolute;
  content: "";
  background: rgb(223, 227, 232);
  border-radius: 0.5px;
  height: 15px;
  width: 1px;
  top: -2px;
  right: 0;
}
.clientfilters-dropdown.custom-headerdropdown .dropdown-toggle,
.offering-dropdownsubmenu .dropdown-toggle {
  background-color: transparent;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
}
.offering-dropdownsubmenu:hover .dropdown-toggle {
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(1, 142, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.offering-dropdownsubmenu .dropdown-toggle {
  padding: 0.5rem 2rem 0.5rem 1rem;
}
.clientfilters-dropdown.custom-headerdropdown .dropdown-toggle:active,
.clientfilters-dropdown.custom-headerdropdown .dropdown-toggle:focus,
.clientfilters-dropdown.custom-headerdropdown.show .dropdown-toggle,
.offering-dropdownsubmenu .dropdown-toggle:active,
.offering-dropdownsubmenu .dropdown-toggle:focus,
.offering-dropdownsubmenu.show .dropdown-toggle {
  background-color: transparent !important;
  color: rgb(99, 115, 129) !important;
  box-shadow: none !important;
}
.all-offering-dropdown:hover .offering-dropdownsubmenu .dropdown-toggle,
.all-offering-dropdown.active .offering-dropdownsubmenu .dropdown-toggle,
.clientfilters-dropdown.custom-headerdropdown.active .dropdown-toggle {
  color: rgb(161, 0, 255) !important;
}

.clientfilters-dropdown.custom-headerdropdown .dropdown-toggle:after,
.offering-dropdownsubmenu .dropdown-toggle:after {
  border-bottom: 2px solid rgb(145, 158, 171);
  border-right: 2px solid rgb(145, 158, 171);
  position: absolute;
  top: 10px;
  bottom: 0;
  right: 12px;
}
.all-offering-dropdown:hover .offering-dropdownsubmenu .dropdown-toggle:after,
.all-offering-dropdown.active .offering-dropdownsubmenu .dropdown-toggle:after,
.clientfilters-dropdown.custom-headerdropdown:hover .dropdown-toggle:after,
.clientfilters-dropdown.custom-headerdropdown.active .dropdown-toggle:after {
  border-bottom: 2px solid rgb(161, 0, 255);
  border-right: 2px solid rgb(161, 0, 255);
}
.config-navlik a {
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.31px;
  line-height: 20px;
  text-decoration: underline;
}
.config-navlik a:hover {
  color: rgb(161, 0, 255);
  text-decoration: underline;
}
.clientfilters-dropdownname.all-offering-dropdown {
  position: relative;
  cursor: pointer;
}
.clientfilters-dropdownname.all-offering-dropdown .dropdown-submenu.seeall-menuitem span {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 18px;
  background: transparent;
  padding: 0.75rem;
  display: block;
  position: relative;
}
.clientfilters-dropdownname.all-offering-dropdown .dropdown-submenu.seeall-menuitem span.active {
  color: #018eff;
  font-family: Graphik-Medium;
  font-weight: 500;
  /* background: linear-gradient(-225deg,rgba(161,0,255,.1),rgba(0,186,255,.1)); */
  border-radius: 0;
}
.clientfilters-dropdownname.all-offering-dropdown .dropdown-submenu.seeall-menuitem span:hover {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1), rgba(0, 186, 255, 0.1));
  border-radius: 0;
  color: #212b36;
  font-family: Graphik-Medium;
  font-weight: 500;
}
.clientfilters-dropdownname.all-offering-dropdown:hover .settings-icon:before,
.clientfilters-dropdownname.all-offering-dropdown.active:hover .settings-icon:before {
  background: url("../Images/Offering-icon-gradient.svg") no-repeat;
  background-size: cover;
}
.clientfilters-dropdownname.all-offering-dropdown.active .settings-icon:before {
  background: url("../Images/Offering-icon-purple.svg") no-repeat;
  background-size: cover;
}
.clientfilters-dropdownname.all-offering-dropdown:after {
  content: " ";
  background: rgb(223, 227, 232);
  border-radius: 0.5px;
  height: 24px;
  width: 1px;
  position: absolute;
  top: 3px;
  right: -16px;
}
.client-card .client-list-offering.clientfilter-cards .card-body {
  height: 176px;
  background: rgb(255, 255, 255);
  border-radius: 8px;
}
.no-client-stories {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  text-align: center;
  line-height: 24px;
  padding: 30px 82px 70px 82px;
  width: 100%;
}
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}
/* New Landing Home  UI Revamp CSS ends here */
.assessment-notifylink .notification-link.homepage-notificationlink {
  padding-left: 0;
}

/* Css for Empty Favourite  */
.emptyFavourite {
  color: rgb(82, 101, 127);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.86px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.clientname-customtooltip {
  display: none;
}
.clientfilter-cards .card-title {
  position: relative;
}
.clientfilter-cards .card-title:hover .clientname-customtooltip {
  display: block;
  position: absolute;
  left: 0;
  bottom: -35px;
  background: #000;
  color: #fff;
  font-size: 11px;
  font-family: Graphik-Medium;
  padding: 8px;
  border-radius: 4px;
  z-index: 9;
}
.clientfilter-cards .card-title:hover .clientname-customtooltip:after {
  content: "";
  position: absolute;
  top: -12px;
  left: 50%;
  margin-left: -5px;
  border: 6px solid transparent;
  border-top-color: #000;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.custom-headerdropdown.sort-icondropdown .dropdown-menu a.active {
  color: #018eff;
  font-family: Graphik-Medium;
  font-weight: 500;
  background: transparent;
}

/* HomePageInvision Link CSS*/
.aboutSynops.aboutsynops-invisionlink img,
.aboutSynops.aboutsynops-invisionlink .aboutsynops-invisionlinkdata {
  cursor: pointer;
}
.homepageinvisionModal .modal-dialog {
  max-width: 95%;
}
.homepageinvisionModal .modal-content {
  height: calc(100vh - 60px);
}
.homepageinvisionModal .modal-header {
  padding: 24px 32px 8px;
  display: flex;
  justify-content: flex-end;
}
.homepageinvisionModal .modal-header .close {
  top: 32px;
  right: 18px;
}
.homepageinvisionModal .modal-body {
  /* min-height: 160px; */
  display: flex;
  align-items: center;
  padding: 1rem;
}
.homepageinvisionModal .modal-content iframe {
  height: 100%;
  width: 100%;
  border: none;
}
.homepageinvisionModal iframe.fullScreen {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 999;
  margin: 0 auto;
}
.modal-dialog.modal-100w {
  width: 100%;
  max-width: none !important;
  margin: 0 auto;
}
.homepageinvisionModal .close-excel {
  background-color: rgba(169, 169, 169, 0.7);
  top: 0;
  right: 16px;
  position: fixed;
  z-index: 9999;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}
.homepageinvisionModal .btn-maximize {
  background: #a100ff;
  color: #fff !important;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  outline: none !important;
  box-shadow: none !important;
  border: none;
  padding: 10px 12px;
}
/* Minimum height */

.profileInfo .custom-headerdropdown .dropdown-menu {
  min-width: 135px !important;
  padding: 4px;
  top: 3px !important;
}
.profileInfo .custom-headerdropdown .dropdown-toggle,
.profileInfo .custom-headerdropdown.show > .dropdown-toggle,
.profileInfo .custom-headerdropdown .dropdown-toggle:hover,
.profileInfo .custom-headerdropdown .dropdown-toggle:active,
.profileInfo .custom-headerdropdown .dropdown-toggle:focus,
.profileInfo .custom-headerdropdown .dropdown-toggle:focus:active {
  color: rgb(99, 115, 129) !important;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;
  position: relative;
  padding: 0px 16px 0 0;
  height: auto;
}
.profileInfo .custom-headerdropdown .dropdown-toggle::after {
  border: 1px solid transparent;
  border-bottom: 2px solid rgb(99, 115, 129);
  border-right: 2px solid rgb(99, 115, 129);
  top: 0;
  right: 0;
}
.profileInfo .custom-headerdropdown.show .dropdown-toggle::after {
  top: 3px;
  transform: rotate(-135deg);
}
.profileInfo .custom-headerdropdown .dropdown-menu a {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1420px) and (max-height: 690px) {
  .assessment-notification-modal.notification-modal .modal-dialog {
    top: 50px;
  }
}
@media screen and (min-width: 1420px) and (min-height: 700px) {
  .landing-container .list-group-data h2 {
    font-size: 18px;
  }
  .landing-container .list-group-data ul li span.topText,
  .landing-container .list-group-data ul li span.bottomText {
    font-size: 16px;
  }
  .landing-container .login-profile {
    padding: 5% 0px;
  }
  .landing-container .list-group-data ul li {
    line-height: 20px;
  }
  .client-carousel-root .owl-carousel {
    height: 162px;
  }
  .client-carousel-root .owl-carousel .owl-item .item {
    height: 162px;
  }
}

.client-value-display {
  display: flex;
}
.client-value-display li {
  padding-left: 10px;
  padding-right: 10px;
  border-left: 1.5px solid rgb(212, 219, 224);
}
.client-value-display li:first-child {
  padding-left: 0px;
  border-left: none;
}
.client-value-display li:last-child {
  padding-right: 0px;
}
#nanorep1 #nanorep-fw .shared__teaser-opener-icon {
  background-size: contain !important;
}
#nanorep1 #nanorep-fw .shared__teaser,
#nanorep1 #nanorep-fw .shared__teaser-opener {
  width: 40px;
  height: 40px;
}
.shiftLeft {
  right: 28% !important;
  bottom: -0.7% !important;
  position: fixed !important;
}

#nanorep1 #nanorep-fw .shared__teaser-opener-icon {
  background-size: contain !important;
  width: 27px !important;
  height: 27px !important;
  margin-top: 6px !important;
  margin-left: 7px !important;
  border-radius: 0 !important;
}
#nanorep1 #nanorep-fw .shared__teaser,
#nanorep1 #nanorep-fw .shared__teaser-opener {
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  overflow: hidden;
}
.z-100 {
  z-index: 100 !important;
}

.request-access {
  margin-left: 25px;
  color: white;
  text-decoration: underline;
  margin-bottom: 25px;
}

.request-access:hover {
  color: white;
  text-decoration: underline;
}
