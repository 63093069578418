/* Maturity Plan CSS start */
.maturity-plan .main-heading,
.maturity-plan .addservice-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.main-heading .stage-heading {
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 20px;
  margin-bottom: 0;
}

.addservice-component h4 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 20px;
}

.addservice-component .btn-primary {
  border-radius: 20px;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 20px;
  letter-spacing: -0.21px;
}

.maturity-plan .btn-outline {
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid #018eff;
  color: #018eff;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 15px;
  letter-spacing: -0.25px;
}

.maturity-plan .btn-outline:hover {
  border: 1px solid #018eff;
  color: #018eff;
}

.maturity-plan .btn-outline.disabled {
  color: #637381;
  border: 1px solid #c4cdd5;
}

.maturity-plan .btn-outline.disabled:hover {
  color: #637381;
  border: 1px solid #c4cdd5;
}

.noservice-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
  margin-bottom: 30px;
}

.noservice-component .show-pop {
  background: rgb(244, 246, 248);
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noservice-component .show-pop:hover {
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.noservice-component h6 {
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 20px;
  color: #212b36;
}

.noservice-component img {
  margin-bottom: 15px;
}

.noservice-component p {
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
  margin-bottom: 10px;
  letter-spacing: -0.25px;
}

.noservice-component .show-pop .fa-plus {
  color: #637381;
}

.suggested-services h5 {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 20px;
  margin-bottom: 15px;
}

.suggested-services p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
}

.suggestion-list {
  display: flex;
}

.suggestion-list li {
  width: 33%;
  margin-right: 30px;
}

.suggestion-list li:last-child {
  margin-right: 0;
}

.suggestion-list label.form-check {
  background: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  display: flex;
  padding: 25px 20px;
  position: relative;
  width: 100%;
  justify-content: space-between;
  border: 2px solid #fff;
}

.suggestion-list label.labelselected {
  background-color: #f4fbff;
  border: 2px solid rgb(1, 142, 255);
  box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.1);
}

.suggestion-list .service-icon {
  height: 40px;
  width: 40px;
  margin-right: 15px;
}

.suggestion-list .form-check-label {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.25px;
}

.suggestion-list .assessment-date {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 11px;
  margin-bottom: 0;
}

.suggestion-list-data .select-icon {
  position: absolute;
  border: 1px solid rgb(151, 151, 151);
  height: 22px;
  width: 22px;
  right: 10px;
  top: 10px;
  border-radius: 50%;
}

.suggestion-list-data.selected .select-icon {
  background-color: #018eff;
  border: none;
  text-align: center;
}

.suggestion-list-data.selected .select-icon:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  top: -1px;
  position: relative;
}

.service-component-list {
  display: flex;
  flex-wrap: wrap;
}

.service-component {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  padding: 22px 15px;
  margin: 0 15px 15px 0;
  display: flex;
  align-items: center;
  height: 125px;
  width: 251px;
  position: relative;
}

.service-component h4 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin: 0;
}

.service-component p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin-bottom: 0;
}

.service-component .service-icon {
  margin-right: 12px;
  width: 48px;
  height: 48px;
  position: relative;
}

.service-component .service-icon:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  background: url("../Images/ServiceComponentIcon.svg");
}

.service-component:hover .service-icon:before {
  background: url("../Images/ServiceComponentIconRed.svg");
}

.service-component .component-overlay {
  position: absolute;
  left: 12px;
  right: 12px;
  top: 0;
  bottom: 15px;
  background: hsla(0, 0%, 100%, 0.9);
  border-radius: 8px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: scale(0);
  transform: scale(0);
  z-index: 10;
}

.service-component:hover .component-overlay {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.component-overlay button {
  width: 48px;
  height: 48px;
  background: rgb(244, 246, 248);
  border: 1px solid rgb(223, 227, 232);
  color: #919eab;
  margin: 8px;
  border-radius: 50%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer !important;
  align-items: center;
}

.component-overlay button:focus {
  outline: none;
}

.component-overlay button span,
.service-component .view-btn span {
  position: absolute;
  background: #1c262f;
  padding: 5px 8px 4px;
  border-radius: 2px;
  color: #fff;
  bottom: 50px;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation: b 0.5s forwards;
  animation: b 0.5s forwards;
  font-size: 11px;
  left: -40px;
  width: 125px;
  font-family: Graphik-Medium;
  text-align: center;
  z-index: 2;
}

.service-component .view-btn span {
  bottom: -32px !important;
}

.component-overlay button span:after,
.service-component .view-btn span:after {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #1c262f;
  position: absolute;
  left: calc(50% - 10px);
  bottom: -5px;
}

.service-component .view-btn span:after {
  bottom: 22px;
  transform: rotate(180deg);
}

.component-overlay button:hover span,
.service-component .view-btn:hover span {
  -webkit-animation: a 0.5s forwards;
  animation: a 0.5s forwards;
}

.remove-modal .modal-dialog {
  max-width: 610px;
}

.remove-modal .modal-title {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 20px;
}

.notification-message {
  display: flex;
  align-items: center;
}

.notification-message .remove-icon i {
  margin-right: 25px;
  font-size: 50px;
  color: #d5002f;
}

.notification-message .success-icon i {
  margin-right: 25px;
  font-size: 50px;
  color: #7ec344;
}

.remove-message p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 24px;
  margin-bottom: 0;
}

.remove-modal .modal-btn .btn {
  width: 100px;
  height: 40px;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
}

.select-service.modal-body {
  padding: 0 2em;
}

.component-count span {
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.components-added {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.component-allselect label.form-check {
  padding-left: 0;
  padding-right: 2rem;
}

.component-allselect .form-check .checkmark,
.suggestion-list label.form-check .checkmark {
  right: 0;
  left: auto;
}

.suggestion-list label.form-check .checkmark {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  top: -10px;
}

.suggestion-list .form-check-input:checked ~ .checkmark:after {
  left: 7px;
  top: 3.5px;
}

.component-allselect .form-check-label {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  bottom: 1px;
}

.component-list label.form-check {
  display: flex;
  padding: 0.75rem;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(196, 205, 213);
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
}

.component-list .form-check .checkmark,
.suggestion-list .form-check .checkmark {
  position: relative;
}

.select-service .form-check .checkmark {
  width: 20px;
  height: 20px;
}

.select-service .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
}

.service-label {
  display: flex;
  flex-direction: column;
}

.service-label .form-check-label {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 18px;
  margin-left: 0;
}

.service-label .assessment-date {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 11px;
}

.success-notification .modal-body {
  padding: 2em;
}

.success-notification .modal-dialog {
  position: absolute;
  right: 30px;
  width: 100%;
  top: 90px;
}

.success-message h5 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 20px;
}

.success-message p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 28px;
}

.success-notification button.close {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 30px;
  color: #919eab;
}

.component-allselect {
  margin-right: 12px;
}

.remove-modal .modal-footer.component-footer {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.remove-modal .modal-btn .btn.btn-default {
  color: #637381;
}

.selected-service span {
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 20px;
}

.component-list .service-name,
.suggestion-list .service-name {
  display: flex;
  align-items: center;
}

.component-list .service-icon {
  height: 36px;
  width: 36px;
  margin-right: 15px;
}

.component-list label.form-check.labelselected {
  background-color: #f4fbff;
  border: 2px solid rgb(1, 142, 255);
  box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.1);
  padding: 11px;
}

.select-service.modal-body .component-list ul {
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.select-service.modal-body .component-list ul li {
  margin-right: 4px;
}

.saveSubmitReset.disabledBtn button {
  pointer-events: none;
  color: rgb(99, 115, 129);
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(196, 205, 213);
}

/* Maturity Plan CSS End */

/* View PMM CSS Start */
.pmm-view-header .backbtn {
  border: none;
  background: transparent;
  outline: none;
}

.component-name {
  border: none;
  background: transparent;
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  outline: none;
}

.pmm-view-body .nav-tabs .nav-link {
  border: none;
}

.pmm-view-body a {
  color: rgb(145, 158, 171);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.04px;
}

.pmm-view-body .nav-tabs .nav-link.active {
  background: transparent !important;
  border-bottom: 8px solid rgb(0, 142, 255);
  color: rgb(0, 0, 0) !important;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: 0.01px;
  border-top: none;
  border-left: none;
  border-right: none;
}

.pmm-view-body {
  margin-top: 40px;
}

.pmm-tab-body {
  min-height: 666px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 28px 24px;
}

.pmm-view-body .main-tabs .rwt__tab[aria-selected="true"],
.pmm-view-body .main-tabs .rwt__tab:hover {
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 600;
  letter-spacing: 0.01px;
}

.sub-tabs .rwt__tab[aria-selected="true"]:after,
.sub-tabs .rwt__tab:hover:after {
  display: none;
}

.sub-tabs .rwt__tab[aria-selected="true"],
.sub-tabs .rwt__tab:hover {
  color: rgb(0, 142, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 600;
  letter-spacing: 0.06px;
}

.pmm-view-body .tab-content ul li {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

.progress-PMM {
  width: 71%;
}

.progress-PMM .progress {
  background: linear-gradient(
    to right,
    rgba(242, 178, 192, 0.6) 25%,
    rgba(255, 197, 139, 0.6) 25%,
    rgba(255, 197, 139, 0.6) 50%,
    rgba(247, 218, 134, 0.6) 50%,
    rgba(247, 218, 134, 0.6) 75%,
    rgba(184, 214, 158, 0.6) 0%
  );
  height: 24px;
  border-radius: 15px;
}

.progress-PMM .applyRed {
  background: linear-gradient(
    -180deg,
    rgb(242, 178, 192) 0%,
    rgb(213, 0, 47) 52%,
    rgb(170, 0, 37) 100%
  );
}

.progress-PMM .applyOrange {
  background: linear-gradient(
    -180deg,
    rgb(255, 197, 139) 0%,
    rgb(244, 147, 66) 50%,
    rgb(192, 87, 23) 100%
  );
}

.progress-PMM .applyYellow {
  background: linear-gradient(
    0deg,
    rgb(230, 176, 25) 0%,
    rgb(255, 200, 44) 48%,
    rgb(247, 218, 134) 100%
  );
}

.progress-PMM .applyGreen {
  background: linear-gradient(
    -180deg,
    rgb(184, 214, 158) 0%,
    rgb(126, 195, 68) 51%,
    rgb(126, 195, 68) 100%
  );
}

.question-text {
  width: 29%;
  margin-right: 16px;
}

.question-text p {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  text-align: right;
  letter-spacing: -0.01px;
  margin-bottom: 0;
}

.pmm-view-body .tab-content {
  margin-top: 50px;
}

.rounded-span {
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -10px;
  box-shadow: inset 0 0 2px 2px #8a939c, 0 0 2px 2px #b0b5ba;
  background: white;
  width: 24px;
  height: 24px;
  /* z-index: 999; */
  position: absolute;
}

.backbtn i {
  font-size: 24px;
  color: white;
  background: rgb(1, 142, 255);
  border: 1.4px solid rgb(1, 142, 255);
  border-radius: 50%;
  margin-right: 5px;
}

.pmm-view-header {
  display: flex;
  align-items: center;
}

.gapReport-graph {
  height: 400px;
}

.gapReport-content.row {
  margin: 0px;
}

.gapReport-graph canvas {
  width: 100% !important;
  height: 100% !important;
}

.gapReport-data .table thead th {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: Graphik-Bold;
  font-weight: bold;
  letter-spacing: -0.02px;
  border-top: none;
  border-bottom: none;
  padding: 7px 6px;
  text-align: center;
}

.gapReport-data .table tbody td {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.03px;
  text-align: center;
}

.gapReport-data .table tbody tr td:nth-child(1) {
  font-family: Graphik-Semibold;
  font-weight: 600;
}

.gapReport-data .table tbody tr td:nth-child(2),
.gapReport-data .table thead tr th:nth-child(2) {
  text-align: left !important;
  max-width: 398px;
}

.gapReport-data .table td,
.gapReport-data .table th {
  line-height: 12px;
  padding: 15px 6px;
}

.pmm-view-body .main-tabs .rwt__tab {
  color: rgb(145, 158, 171);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0.01px;
}

.sub-tabs .rwt__tab {
  color: rgb(145, 158, 171);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0.06px;
}

#chartjs-bubblePMMTooltip {
  opacity: 1;
  position: absolute;
  background: rgb(28, 38, 47);
  color: rgba(255, 255, 255, 0.87);
  border-radius: 2px;
  padding: 9px 16px 11px;
  min-width: 232px;
  /* max-height: 350px; */
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translate(-50%, -120%);
  transform: translate(-50%, -120%);
  z-index: 1;
  /* overflow: scroll; */
}

div#chartjs-bubblePMMTooltip:after {
  content: "";
  height: fit-content;
  width: fit-content;
  border: 6px solid rgb(28, 38, 47);
  position: absolute;
  margin-left: -6px;
  margin-top: -8px;
  top: 100%;
  left: 50%;
  transform: rotate(45deg);
  z-index: 1;
  /* box-shadow: 2px 5px 5px 0px rgba(33, 43, 54, 0.1); */
}

.bubblePMMTooltip-body {
  padding: 3px 10px 5px;
}

.bubbleTooltip-content {
  color: rgba(255, 255, 255, 0.87);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  line-height: 11px;
}

.bubbleTooltip-content p {
  margin-bottom: 16px;
}

.bubbleTooltip-values {
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.6);
}

/* View PMM CSS End */
.bubble-chart {
  position: relative;
  /*height: 400px;*/
}

.grid-details {
  display: flex;
  justify-content: flex-end;
}

.grid-details img,
.grid-close img {
  cursor: pointer;
}

.bubblechart-data {
  position: absolute;
  width: 550px;
  max-height: 550px;
  overflow-y: auto;
  padding: 20px;
  right: -25px;
  top: -28px;
  bottom: 50px;
  z-index: 1;
  background: #fff;
  border-radius: 0;
  border: 1px solid rgb(238, 238, 238);
}

.bubblechart-data .grid-heading {
  display: flex;
  justify-content: space-between;
}

.bubblechart-data .grid-heading h4 {
  color: rgb(0, 0, 0);
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.06px;
}

.bubblechart-data .table thead th {
  color: rgb(0, 0, 0);
  font-size: 11px;
  font-family: Graphik-Bold;
  font-weight: bold;
  letter-spacing: -0.05px;
  border-top: none;
  border-bottom: none;
}

.bubblechart-data .table thead th span {
  font-family: graphik;
  font-size: 11px;
  font-weight: normal;
  letter-spacing: -0.09px;
}

.bubblechart-data .table tbody td {
  color: rgb(0, 0, 0);
  font-size: 11px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.03px;
  text-align: center;
}

.bubblechart-data .table tbody tr td:nth-child(2) {
  text-align: left !important;
}

.select-component.select-custom::after {
  border-bottom: 2px solid #000000;
  border-right: 2px solid #000000;
  height: 10px;
  width: 10px;
}

div#bubbleLegend {
  position: relative;
  margin-right: 100px;
  display: inline-block;
}

.custom-legend {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.vertical-text {
  text-orientation: sideways;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  /* Safari & Chrome */
  -moz-transform: rotate(270deg);
  /* Firefox */
  -ms-transform: rotate(270deg);
  /* Internet Explorer */
  -o-transform: rotate(270deg);
  /* Opera */
  text-align: center;
  position: absolute;
  bottom: 55px;
  left: -30px;
}

#bubbleLegend span {
  color: rgb(0, 0, 0);
  font-family: graphik;
  font-size: 11px;
  font-weight: normal;
  letter-spacing: -0.01px;
}

.timeCls {
  display: block;
  margin-left: 5px;
  margin-top: 5px;
}

.viewPMM-service .react-progress-bar .progress,
.viewPMM-service .react-progress-bar .progress .progress-bar {
  height: 22px;
}

.service-component .view-btn {
  width: 44px;
  height: 40px;
  background: transparent;
  cursor: pointer;
  position: absolute;
  right: 6px;
  bottom: 0;
}

.service-component .view-btn:hover {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 2px;
}

.service-component-list .service-component .view-btn.eye-icon::before {
  position: absolute;
  content: "";
  display: inline-flex;
  background: url("../Images/eye-grey.svg") no-repeat;
  width: 16px;
  height: 12px;
  left: 0;
  top: 0px;
  bottom: 0;
  right: 0;
  margin: auto;
}

.service-component-list .service-component .view-btn.eye-icon:hover::before {
  background: url("../Images/eye-gradient.svg") no-repeat;
}

.pmm-overallscore {
  position: absolute;
  right: 20px;
  display: flex;
}

.pmm-view-body .pmm-overallscore span,
.pmm-policy-score span {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0.01px;
}

.pmm-view-body .pmm-overallscore span b,
.pmm-policy-score span b {
  font-size: 18px;
}

.pmm-policy-score {
  position: absolute;
  right: 0px;
  top: -3px;
}

#pmmAssessment {
  position: relative;
}

.pmm-policy-legends {
  display: flex;
  justify-content: flex-end;
}

.pmm-view-body .tab-content .pmm-policy-legends ul {
  display: flex;
}

.pmm-view-body .tab-content .pmm-policy-legends ul li {
  width: auto;
  margin-right: 1rem;
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  text-align: right;
  letter-spacing: -0.01px;
}

.pmm-view-body .tab-content .pmm-policy-legends ul li:last-child {
  margin-right: 0;
}

.pmm-view-body .tab-content .pmm-policy-legends ul li span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  margin-right: 10px;
}

.pmm-view-body .tab-content .pmm-policy-legends ul li span.base-color {
  background: rgb(213, 0, 47);
}

.pmm-view-body .tab-content .pmm-policy-legends ul li span.average-color {
  background: rgb(244, 147, 66);
}

.pmm-view-body .tab-content .pmm-policy-legends ul li span.aboveaverage-color {
  background: rgb(255, 200, 44);
}

.pmm-view-body .tab-content .pmm-policy-legends ul li span.bestpractice-color {
  background: rgb(126, 195, 68);
}

.gapReport-data .table tbody .policyscore-displayrow td {
  color: rgb(99, 115, 129);
  font-family: Graphik-Semibold;
  font-weight: 600;
}

.pmm-overallscore .guage-chart {
  position: relative;
  bottom: 55px;
  left: 8px;
}

.pmm-overallscore .guage-chart .speedometer text {
  font-size: 0px !important;
}

.progress-PMM .react-progress-bar .progress {
  overflow: unset;
  position: relative;
}

.react-progress-bar .progress .progress-bar:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.react-progress-bar .progress .progress-bar:nth-last-child(2) {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.ioj-right-btn-controls .category-references {
  position: relative;
  top: unset;
  right: unset;
  margin-right: 12px;
  margin-top: 0px !important;
}

.ioj-component .common-tablayout {
  position: relative;
}

.ioj-component .common-tablayout .category-references.dashboard-category-references.bo-references {
  top: 8px;
  right: 12px;
}

/* SVM fixes */
.data-visualisation-container .title-section .fa-chevron-left {
  margin-right: 24px;
}

.data-visualisation-container .program-box-title-colored svg,
.data-visualisation-container .program-box-title svg {
  font-size: 8px !important;
  margin-left: 0px;
}

.btn-knowMore-container .svg-inline--fa {
  height: 1em;
}

#groove-select-dropdown div.selectmenu-options .searchable-dropdown-content {
  background-color: #fff;
  border: 1px solid #dfe3e8;
  border-radius: 16px;
  height: 32px;
  margin: 12px 16px 11px 13px;
  padding: 0 0 0 10px;
  position: relative;
  width: 95%;
}

.data-visualisation-container .project-tabs-list #groove-select-dropdown svg {
  color: #212b36;
  width: 20px;
  padding: 0px 0px 8px 7px;
}

.details-view-section .svg-inline--fa.fa-w-14 {
  width: 1.875em;

  margin-top: -7px;
}

.data-visualisation-container .program-box-title-label {
  align-content: center;
  align-items: center;
  color: #637381;
  flex-direction: row;
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin: 0;
  padding: 5px 10px 0px 10px;
  word-break: break-word;
}

.modal-custom-BOI-container .modal-body {
  padding: 2rem !important;
}

.modal-custom-BOI-container .modal-body table tr,
.modal-custom-BOI-container .modal-body table tr th,
.modal-custom-BOI-container .modal-body table tr td {
  padding-left: 0px;
  vertical-align: top;
}

.modal-custom-BOI-container p {
  word-break: normal;
}

.parentcontainer {
  display: flex;
}

.parentcontainer:hover::after {
  content: "NEW";
  position: absolute;
  left: 50%;
  width: 32px;
  height: 16px;
  display: table;
  clear: both;
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Graphik-Medium";
  font-size: 0.875em;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.15px;
  text-align: center;
  margin-left: 5px;
  background-color: rgb(224, 32, 32);
  border-radius: 3px;
  margin-top: -4px;
}

/* SVM fixes ends here */

.omid-select {
  opacity: 1;
  color: rgba(99, 115, 129, 1);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.25px;
  text-align: left;
  line-height: 14px;
  margin-bottom: 18px;
}

.omid-select:hover {
  cursor: pointer;
}

.omid-check-title {
  opacity: 1;
  color: rgba(17, 24, 39, 1);
  font-family: "Graphik-Regular";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.25px;
  text-align: left;
  line-height: 14px;
  margin-left: 8px;
}

.omid-selected {
  border-radius: 4px;
  opacity: 1;
  background-image: linear-gradient(
    -63.434948822921996deg,
    rgba(230, 246, 255, 1) 0%,
    rgba(238, 238, 254, 1) 51%,
    rgba(245, 230, 255, 1) 100%
  );
}
