@font-face {
  font-family: graphik;
  src: url(../fonts/Graphik-Regular-Web.eot);
  src: url(../fonts/Graphik-Regular-Web.eot) format("embedded-opentype"),
    url(../fonts/Graphik-Regular-Web.woff) format("woff");
  /*, url(../fonts/Graphik-Regular-Web.ttf) format('truetype'), url(../fonts/Graphik-Regular-Web.svg#Graphik-Regular-Web) format('svg');
	  */
}
@font-face {
  font-family: graphik-light;
  src: url(../fonts/Graphik-Light-Web.eot);
  src: url(../fonts/Graphik-Light-Web.eot) format("embedded-opentype"),
    url(../fonts/Graphik-Light-Web.woff) format("woff");
  /*, url(../fonts/Graphik-Light-Web.ttf) format('truetype'), url(../fonts/Graphik-Light-Web.svg#Graphik-Light-Web) format('svg');
	  */
}
@font-face {
  font-family: graphik-medium;
  src: url(../fonts/Graphik-Medium-Web.eot);
  src: url(../fonts/Graphik-Medium-Web.eot) format("embedded-opentype"),
    url(../fonts/Graphik-Medium-Web.woff) format("woff");
  /*, url(../fonts/Graphik-Medium-Web.ttf) format('truetype'), url(../fonts/Graphik-Medium-Web.svg#Graphik-Medium-Web) format('svg');
	  */
}
@font-face {
  font-family: graphik-semibold;
  src: url(../fonts/Graphik-Semibold-Web.eot);
  src: url(../fonts/Graphik-Semibold-Web.eot) format("embedded-opentype"),
    url(../fonts/Graphik-Semibold-Web.woff) format("woff");
  /*, url(../fonts/Graphik-Semibold-Web.ttf) format('truetype'), url(../fonts/Graphik-Semibold-Web.svg#Graphik-Semibold-Web) format('svg');
	  */
}
@font-face {
  font-family: graphik-bold;
  src: url(../fonts/Graphik-Bold-Web.eot);
  src: url(../fonts/Graphik-Bold-Web.eot) format("embedded-opentype"),
    url(../fonts/Graphik-Bold-Web.woff) format("woff");
  /*, url(../fonts/Graphik-Bold-Web.ttf) format('truetype'), url(../fonts/Graphik-Bold-Web.svg#Graphik-Bold-Web) format('svg');
	  */
}
@font-face {
  font-family: graphik-black;
  src: url(../fonts/Graphik-Black-Web.eot);
  src: url(../fonts/Graphik-Black-Web.eot) format("embedded-opentype"),
    url(../fonts/Graphik-Black-Web.woff) format("woff");
  /*, url(../fonts/Graphik-Black-Web.ttf) format('truetype'), url(../fonts/Graphik-Black-Web.svg#Graphik-Black-Web) format('svg');
	  */
}
@font-face {
  font-family: graphik-super;
  src: url("../fonts/Graphik-Super-Web.eot");
  src: url("../fonts/Graphik-Super-Web.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Super-Web.woff") format("woff");
}
/* html, #root {
	  height: 100%;
  } */
.container-fluid {
  padding-left: 12px;
  padding-right: 12px;
}
.row {
  margin-right: -12px;
  margin-left: -12px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 12px;
  padding-left: 12px;
}
/* .main-container {
	  min-height: 80%;
  }
  */
body {
  font-family: "graphik";
  background-color: #f9fafb;
  position: relative;
  height: 100%;
  color: #4f555b;
  /* padding: 80px 0 48px; */
  /* width: 1263px;
	  margin: 0 auto;
	  */
}
/* for new navbar css start changes */
#root .nolandingHome {
  padding: 90px 0 48px;
}
/* for new navbar css end changes */
#root .landingHome {
  background-image: url(../Images/globe.png) !important;
  height: 100% !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  padding: 0px !important;
}
a:hover,
a:active,
a:focus {
  text-decoration: none;
}
/* .wrapper {
	  display: table;
	  width: 100%;
  }
  */
.ht-100 {
  height: 100%;
}
.wrapper:not(.pt-0) {
  position: relative;
  height: 100%;
  /* overflow-y: auto;
	  overflow-x: hidden; */
  width: 100%;
  /* padding-top: 80px;
	  margin-bottom: -80px; */
}
/* .wrapper:not(.pt-0):after {
	  content: '';
	  display: block;
	  height: 48.67px;
  } */
/* .wrapper::after {
	  content: '';
	  display: block;
	  height: 50px;
  }
  */
.side-border {
  border-left: 7px solid #00baff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}
.footer {
  background: #fff;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  overflow: hidden;
  left: 0;
}
img {
  max-width: 100%;
}
select {
  cursor: pointer;
}
/* overlay */
._loading_overlay_overlay {
  color: #637381 !important;
  position: fixed !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  z-index: 10010 !important;
}
._loading_overlay_spinner {
  background-color: rgba(99, 115, 129, 0.2) !important;
  border-radius: 5px !important;
}
.common-synops-loader {
  width: 102px;
  height: 102px;
  animation: loaderAnimate 2.5s infinite;
  /* background: url('../Images/synops_loader.gif') no-repeat top center; */
}

@keyframes loaderAnimate {
  0% {
    background: url("../Images/Loader/frame_000_delay-0.02s.gif") no-repeat top center;
  }
  0.5% {
    background: url("../Images/Loader/frame_001_delay-0.02s.gif") no-repeat top center;
  }
  1% {
    background: url("../Images/Loader/frame_002_delay-0.02s.gif") no-repeat top center;
  }
  1.5% {
    background: url("../Images/Loader/frame_003_delay-0.02s.gif") no-repeat top center;
  }
  2% {
    background: url("../Images/Loader/frame_004_delay-0.02s.gif") no-repeat top center;
  }
  2.5% {
    background: url("../Images/Loader/frame_005_delay-0.02s.gif") no-repeat top center;
  }
  3% {
    background: url("../Images/Loader/frame_006_delay-0.02s.gif") no-repeat top center;
  }
  3.5% {
    background: url("../Images/Loader/frame_007_delay-0.02s.gif") no-repeat top center;
  }
  4% {
    background: url("../Images/Loader/frame_008_delay-0.02s.gif") no-repeat top center;
  }
  4.5% {
    background: url("../Images/Loader/frame_009_delay-0.02s.gif") no-repeat top center;
  }
  5% {
    background: url("../Images/Loader/frame_010_delay-0.02s.gif") no-repeat top center;
  }
  5.5% {
    background: url("../Images/Loader/frame_011_delay-0.02s.gif") no-repeat top center;
  }
  6% {
    background: url("../Images/Loader/frame_012_delay-0.02s.gif") no-repeat top center;
  }
  6.5% {
    background: url("../Images/Loader/frame_013_delay-0.02s.gif") no-repeat top center;
  }
  7% {
    background: url("../Images/Loader/frame_014_delay-0.02s.gif") no-repeat top center;
  }
  7.5% {
    background: url("../Images/Loader/frame_015_delay-0.02s.gif") no-repeat top center;
  }
  8% {
    background: url("../Images/Loader/frame_016_delay-0.02s.gif") no-repeat top center;
  }
  8.5% {
    background: url("../Images/Loader/frame_017_delay-0.02s.gif") no-repeat top center;
  }
  9% {
    background: url("../Images/Loader/frame_018_delay-0.02s.gif") no-repeat top center;
  }
  9.5% {
    background: url("../Images/Loader/frame_019_delay-0.02s.gif") no-repeat top center;
  }
  10% {
    background: url("../Images/Loader/frame_020_delay-0.02s.gif") no-repeat top center;
  }
  10.5% {
    background: url("../Images/Loader/frame_021_delay-0.02s.gif") no-repeat top center;
  }
  11% {
    background: url("../Images/Loader/frame_022_delay-0.02s.gif") no-repeat top center;
  }
  11.5% {
    background: url("../Images/Loader/frame_023_delay-0.02s.gif") no-repeat top center;
  }
  12% {
    background: url("../Images/Loader/frame_024_delay-0.02s.gif") no-repeat top center;
  }
  12.5% {
    background: url("../Images/Loader/frame_025_delay-0.02s.gif") no-repeat top center;
  }
  13% {
    background: url("../Images/Loader/frame_026_delay-0.02s.gif") no-repeat top center;
  }
  13.5% {
    background: url("../Images/Loader/frame_027_delay-0.02s.gif") no-repeat top center;
  }
  14% {
    background: url("../Images/Loader/frame_028_delay-0.02s.gif") no-repeat top center;
  }
  14.5% {
    background: url("../Images/Loader/frame_029_delay-0.02s.gif") no-repeat top center;
  }
  15% {
    background: url("../Images/Loader/frame_030_delay-0.02s.gif") no-repeat top center;
  }
  16% {
    background: url("../Images/Loader/frame_031_delay-0.02s.gif") no-repeat top center;
  }
  17% {
    background: url("../Images/Loader/frame_032_delay-0.02s.gif") no-repeat top center;
  }
  18% {
    background: url("../Images/Loader/frame_033_delay-0.02s.gif") no-repeat top center;
  }
  19% {
    background: url("../Images/Loader/frame_034_delay-0.02s.gif") no-repeat top center;
  }
  20% {
    background: url("../Images/Loader/frame_035_delay-0.02s.gif") no-repeat top center;
  }
  21% {
    background: url("../Images/Loader/frame_036_delay-0.02s.gif") no-repeat top center;
  }
  22% {
    background: url("../Images/Loader/frame_037_delay-0.02s.gif") no-repeat top center;
  }
  23% {
    background: url("../Images/Loader/frame_038_delay-0.02s.gif") no-repeat top center;
  }
  24% {
    background: url("../Images/Loader/frame_039_delay-0.02s.gif") no-repeat top center;
  }
  25% {
    background: url("../Images/Loader/frame_040_delay-0.02s.gif") no-repeat top center;
  }
  26% {
    background: url("../Images/Loader/frame_041_delay-0.02s.gif") no-repeat top center;
  }
  27% {
    background: url("../Images/Loader/frame_042_delay-0.02s.gif") no-repeat top center;
  }
  28% {
    background: url("../Images/Loader/frame_043_delay-0.02s.gif") no-repeat top center;
  }
  29% {
    background: url("../Images/Loader/frame_044_delay-0.02s.gif") no-repeat top center;
  }
  30% {
    background: url("../Images/Loader/frame_045_delay-0.02s.gif") no-repeat top center;
  }
  31% {
    background: url("../Images/Loader/frame_046_delay-0.02s.gif") no-repeat top center;
  }
  32% {
    background: url("../Images/Loader/frame_047_delay-0.02s.gif") no-repeat top center;
  }
  33% {
    background: url("../Images/Loader/frame_048_delay-0.02s.gif") no-repeat top center;
  }
  34% {
    background: url("../Images/Loader/frame_049_delay-0.02s.gif") no-repeat top center;
  }
  35% {
    background: url("../Images/Loader/frame_050_delay-0.02s.gif") no-repeat top center;
  }
  36% {
    background: url("../Images/Loader/frame_051_delay-0.02s.gif") no-repeat top center;
  }
  37% {
    background: url("../Images/Loader/frame_052_delay-0.02s.gif") no-repeat top center;
  }
  38% {
    background: url("../Images/Loader/frame_053_delay-0.02s.gif") no-repeat top center;
  }
  39% {
    background: url("../Images/Loader/frame_054_delay-0.02s.gif") no-repeat top center;
  }
  40% {
    background: url("../Images/Loader/frame_055_delay-0.02s.gif") no-repeat top center;
  }
  41% {
    background: url("../Images/Loader/frame_056_delay-0.02s.gif") no-repeat top center;
  }
  42% {
    background: url("../Images/Loader/frame_057_delay-0.02s.gif") no-repeat top center;
  }
  43% {
    background: url("../Images/Loader/frame_058_delay-0.02s.gif") no-repeat top center;
  }
  44% {
    background: url("../Images/Loader/frame_059_delay-0.02s.gif") no-repeat top center;
  }
  45% {
    background: url("../Images/Loader/frame_060_delay-0.02s.gif") no-repeat top center;
  }
  46% {
    background: url("../Images/Loader/frame_061_delay-0.02s.gif") no-repeat top center;
  }
  47% {
    background: url("../Images/Loader/frame_062_delay-0.02s.gif") no-repeat top center;
  }
  48% {
    background: url("../Images/Loader/frame_063_delay-0.02s.gif") no-repeat top center;
  }
  49% {
    background: url("../Images/Loader/frame_064_delay-0.02s.gif") no-repeat top center;
  }
  50% {
    background: url("../Images/Loader/frame_065_delay-0.02s.gif") no-repeat top center;
  }
  51% {
    background: url("../Images/Loader/frame_066_delay-0.02s.gif") no-repeat top center;
  }
  52% {
    background: url("../Images/Loader/frame_067_delay-0.02s.gif") no-repeat top center;
  }
  53% {
    background: url("../Images/Loader/frame_068_delay-0.02s.gif") no-repeat top center;
  }
  54% {
    background: url("../Images/Loader/frame_069_delay-0.02s.gif") no-repeat top center;
  }
  55% {
    background: url("../Images/Loader/frame_070_delay-0.02s.gif") no-repeat top center;
  }
  56% {
    background: url("../Images/Loader/frame_071_delay-0.02s.gif") no-repeat top center;
  }
  57% {
    background: url("../Images/Loader/frame_072_delay-0.02s.gif") no-repeat top center;
  }
  58% {
    background: url("../Images/Loader/frame_073_delay-0.02s.gif") no-repeat top center;
  }
  59% {
    background: url("../Images/Loader/frame_074_delay-0.02s.gif") no-repeat top center;
  }
  60% {
    background: url("../Images/Loader/frame_075_delay-0.02s.gif") no-repeat top center;
  }
  61% {
    background: url("../Images/Loader/frame_076_delay-0.02s.gif") no-repeat top center;
  }
  62% {
    background: url("../Images/Loader/frame_077_delay-0.02s.gif") no-repeat top center;
  }
  63% {
    background: url("../Images/Loader/frame_078_delay-0.02s.gif") no-repeat top center;
  }
  64% {
    background: url("../Images/Loader/frame_079_delay-0.02s.gif") no-repeat top center;
  }
  65% {
    background: url("../Images/Loader/frame_080_delay-0.02s.gif") no-repeat top center;
  }
  66% {
    background: url("../Images/Loader/frame_081_delay-0.02s.gif") no-repeat top center;
  }
  67% {
    background: url("../Images/Loader/frame_082_delay-0.02s.gif") no-repeat top center;
  }
  68% {
    background: url("../Images/Loader/frame_083_delay-0.02s.gif") no-repeat top center;
  }
  69% {
    background: url("../Images/Loader/frame_084_delay-0.02s.gif") no-repeat top center;
  }
  70% {
    background: url("../Images/Loader/frame_085_delay-0.02s.gif") no-repeat top center;
  }
  71% {
    background: url("../Images/Loader/frame_086_delay-0.02s.gif") no-repeat top center;
  }
  72% {
    background: url("../Images/Loader/frame_087_delay-0.02s.gif") no-repeat top center;
  }
  73% {
    background: url("../Images/Loader/frame_088_delay-0.02s.gif") no-repeat top center;
  }
  74% {
    background: url("../Images/Loader/frame_089_delay-0.02s.gif") no-repeat top center;
  }
  75% {
    background: url("../Images/Loader/frame_090_delay-0.02s.gif") no-repeat top center;
  }
  76% {
    background: url("../Images/Loader/frame_091_delay-0.02s.gif") no-repeat top center;
  }
  77% {
    background: url("../Images/Loader/frame_092_delay-0.02s.gif") no-repeat top center;
  }
  78% {
    background: url("../Images/Loader/frame_093_delay-0.02s.gif") no-repeat top center;
  }
  79% {
    background: url("../Images/Loader/frame_094_delay-0.02s.gif") no-repeat top center;
  }
  80% {
    background: url("../Images/Loader/frame_095_delay-0.02s.gif") no-repeat top center;
  }
  81% {
    background: url("../Images/Loader/frame_096_delay-0.02s.gif") no-repeat top center;
  }
  82% {
    background: url("../Images/Loader/frame_097_delay-0.02s.gif") no-repeat top center;
  }
  83% {
    background: url("../Images/Loader/frame_098_delay-0.02s.gif") no-repeat top center;
  }
  84% {
    background: url("../Images/Loader/frame_099_delay-0.02s.gif") no-repeat top center;
  }
  85% {
    background: url("../Images/Loader/frame_100_delay-0.02s.gif") no-repeat top center;
  }
  86% {
    background: url("../Images/Loader/frame_101_delay-0.02s.gif") no-repeat top center;
  }
  87% {
    background: url("../Images/Loader/frame_102_delay-0.02s.gif") no-repeat top center;
  }
  88% {
    background: url("../Images/Loader/frame_103_delay-0.02s.gif") no-repeat top center;
  }
  89% {
    background: url("../Images/Loader/frame_104_delay-0.02s.gif") no-repeat top center;
  }
  90% {
    background: url("../Images/Loader/frame_105_delay-0.02s.gif") no-repeat top center;
  }
  91% {
    background: url("../Images/Loader/frame_106_delay-0.02s.gif") no-repeat top center;
  }
  92% {
    background: url("../Images/Loader/frame_107_delay-0.02s.gif") no-repeat top center;
  }
  93% {
    background: url("../Images/Loader/frame_108_delay-0.02s.gif") no-repeat top center;
  }
  94% {
    background: url("../Images/Loader/frame_109_delay-0.02s.gif") no-repeat top center;
  }
  95% {
    background: url("../Images/Loader/frame_110_delay-0.02s.gif") no-repeat top center;
  }
  96% {
    background: url("../Images/Loader/frame_111_delay-0.02s.gif") no-repeat top center;
  }
  97% {
    background: url("../Images/Loader/frame_112_delay-0.02s.gif") no-repeat top center;
  }
  98% {
    background: url("../Images/Loader/frame_113_delay-0.02s.gif") no-repeat top center;
  }
  99% {
    background: url("../Images/Loader/frame_114_delay-0.02s.gif") no-repeat top center;
  }
  100% {
    background: url("../Images/Loader/frame_114_delay-0.02s.gif") no-repeat top center;
  }
}
._loading_overlay_content {
  color: #637381 !important;
  letter-spacing: -0.29px !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-family: graphik-medium !important;
}
/* ul.nav.navbar-nav > li > a {
	  color: #6b737b;
  }
  ul.nav.navbar-nav.navbar-right > li > a {
	  border: 1px solid #9b9b9b;
	  border-radius: 50%;
	  padding: 10px;
  }
  ul.nav.navbar-nav.navbar-right > li:last-child a {
	  padding-right: 0;
  }
  ul.nav.navbar-nav.navbar-right > li a.dropdown-toggle img {
	  margin-right: 10px;
  }
  ul.nav.navbar-nav.navbar-right > li > a.dropdown-toggle {
	  border-radius: 40px;
	  padding: 10px 15px;
  }
  ul.nav.navbar-nav.navbar-right > li.logged a {
	  border-radius: 0;
	  padding: 0;
	  border: 0 none;
  }
  ul.nav.navbar-nav.navbar-right {
	  margin: 14px 0;
  }
  ul.nav.navbar-nav.navbar-right > li {
	  margin: 4px 5px 4px;
  }
  .business-outcomes {
	  background-color: #fff;
	  border-radius: 10px;
	  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
	  padding: 15px;
	  margin: 0 15px 15px;
  }
  */
.bottom-menu p {
  text-align: center;
  font-size: 10px;
}
.bottom-menu {
  margin-top: 200px;
}
.main-container {
  /* margin-top: 40px;
	  padding-bottom: 0;
	  */
  /* 50px */
  /* margin-left: 240px; */
  /* width: 90%;
	  padding: 15px 0;
	  */
}

.readOnlyDate {
  background: #fff;
  padding: 5px 8px;
  border: 1px solid #018eff;
  border-radius: 5px;
  font-size: 10px;
  color: #999;
  display: inline-block;
}
.readOnlyDate span {
  margin-right: 8px;
  color: #018eff;
  font-size: 12px;
}
.readOnlyDate svg {
  vertical-align: text-bottom;
}
.main-component {
  background-color: #f9fafb;
  margin: 24px 0 12px;
  padding: 0 12px 0;
}
.noSide.main-container {
  margin-left: 0;
}
.noSide.main-container .main-component {
  margin-top: 0;
  background-color: inherit;
  margin-left: 0;
}
.shadow-bg {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
  padding: 15px;
}
.navbar-toggle {
  float: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 26px 0px 18px 20px;
}
.navbar-toggle .icon-bar {
  display: block;
  width: 24px;
  height: 4px;
  border-radius: 1px;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #1d2b36;
}
.navbar-toggle .icon-bar + .icon-bar {
  margin: 4px 0;
}
.navbar-default .navbar-toggle,
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background-color: transparent;
}
.navbar-default {
  background-color: #fff;
  margin-bottom: 0;
  border-color: #fff;
  box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.1);
}
.navbar-default .navbar-nav > li > a {
  color: #6b737b;
}
a.navbar-brand {
  height: auto;
  padding: 5px 24px 5px 0;
  margin-right: 1.5rem;
  position: relative;
}
a.navbar-brand:after,
.navbar .select-data:after {
  border-right: 1.5px solid #dfe3e8;
  width: 1px;
  height: 40px;
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -20px;
}
.landingNav a.navbar-brand:after {
  display: none;
}
.landingNav a.navbar-brand h1 {
  font-size: 16px;
  color: #212b36;
  font-family: graphik-medium;
  letter-spacing: -0.25px;
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 0;
}
.login-img img {
  margin-right: 10px;
  border: 1px solid #c9d1d6;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
}
.login-img select {
  background-color: #ffffff;
  border: 1px solid #c9d1d6;
  padding: 3px 5px;
  font-weight: 700;
  font-size: 14px;
  width: 200px;
  color: #434a51;
  border-radius: 12px;
}
.navbar-brand h1 {
  font-size: 20px;
  font-family: graphik-bold;
  color: #2d3a4b;
  display: inline-block;
  margin-left: 8px;
  letter-spacing: -0.5px;
}
a.navbar-brand.no-border:after {
  display: none;
}
/* .select-data-highlight {
	  color: #373737 !important;
  }
  */
.red-font {
  color: red !important;
}
.green-font {
  color: green !important;
}
.light-grey-font {
  color: #979797 !important;
}
.hidden {
  display: none;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.darkest-gray-background {
  background-color: #1d2b36;
}
.light-blue-background {
  background-color: #00baff;
}
.border-bottom-gray {
  border-bottom: 1px solid #d3d3d3;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.login-img select:active,
.login-img select:hover,
.login-img select:focus {
  outline: 0 none;
  box-shadow: none;
}
.login-img {
  /* width: 260px;
	  */
  position: relative;
  padding-left: 60px;
}
span.profileImg {
  background: #919eab;
  display: block;
  border-radius: 50%;
  padding: 2px;
}
span.profileImg img {
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  width: 24px;
  height: 24px;
  vertical-align: bottom;
}
li.logged {
  margin-top: 0 !important;
  padding: 15px;
}
li.logged a {
  padding: 0;
  border-radius: 50%;
}
.login-img span {
  color: #6b737b;
  font-size: 16px;
  font-weight: 700;
}
.login-img span.caret {
  vertical-align: top;
  margin-right: 0;
}
.login-img span i {
  font-style: inherit;
  font-size: 12px;
}
.logged img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  color: #6b737b;
  background-color: #ffffff;
}
ul.nav.navbar-nav.navbar-right > li {
  margin-top: 15px;
}
ul.nav.navbar-nav.navbar-right > li a {
  /* padding-left: 13px;
	  padding-right: 13px;
	  */
  line-height: 24px;
}
span.badge-notify {
  background: #ff0000;
  color: #fff;
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 15px;
  padding: 0 6px;
  position: absolute;
  font-size: 12px;
  top: 8px;
  right: 2px;
  line-height: 18px;
}
.outcome-highlight {
  padding: 16px 16px 10px;
  margin: 12px 0;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}
.sidebar-cont .outcome-highlight {
  padding: 18px;
  position: relative;
}
.padding-big {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.business-filter {
  background-color: #f9fafb;
  border-top: 1px solid #dfe3e8;
  border-bottom: 1px solid #dfe3e8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.376em 0;
  -webkit-box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.1), 0 1px 0 0 #f4f6f8;
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.1), 0 1px 0 0 #f4f6f8;
  /* position: fixed;
	  width: calc(100% - 240px);
	  top: 80px;
	  left: 240px;
	  z-index: 10;
	  */
}
.business-outcomes {
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
  background: #fff;
  margin: 0 0 15px;
}
.outcome-table {
  display: table;
  table-layout: fixed;
  width: 100%;
  /* margin: 14px 0;
	  */
  margin: 5px 0;
}
.dash-settings.outcome-table {
  margin: 20px 0 0;
}
.rd-data .outcome-table {
  margin-bottom: 16px;
}
.outcome-row {
  display: flex;
  flex-wrap: wrap;
}
.outcome-divider {
  width: 50%;
  vertical-align: top;
  padding: 10px 10px 10px 16px;
  position: relative;
  margin: 10px 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
}
.outcome-highlight.no-table-layout .outcome-divider {
  padding: 16px 16px 16px 32px;
}
.outcome-highlight.insightsNew.iiwidth.no-table-layout .outcome-divider .outcome-indicate {
  min-height: 158px;
}
.outcome-highlight.no-table-layout .outcome-table {
  margin-bottom: 18px;
}

.outcome-divider.row-divider {
  display: block;
  margin: 15px 0;
  width: auto;
}

.outcome-divider.no-whiteShadow {
  padding: 5px 10px 5px 16px;
  margin: 5px 0;
}

.flexbox-3 .outcome-divider.no-whiteShadow {
  width: 33.33%;
}
/* .outcome-divider.row-divider:last-child {
    margin: 0;
}
.outerDiv .modal-content{
    width:750px !important;
}
*/
.no-table-layout .outcome-divider {
  /* width: auto;
    min-width: 200px;
    float: left;
    */
  display: block;
  width: auto;
}

.outcome-highlight.no-table-layout {
  /* width: 95%;
    */
  padding: 0;
  margin: 0 auto;
}

.outcome-highlight.no-table-layout h2 {
  margin: 0;
}

.outcome-indicate {
  position: relative;
}

.outcome-indicate:before {
  position: absolute;
  content: "";
  background: #7ec344;
  width: 8px;
  height: 100%;
  border-radius: 15px;
  left: -16px;
  top: 0;
}
.status-color-grey:before {
  background: #979797 !important;
}

.status-color-grey:before {
  background: #979797 !important;
}
.status-color-green:before {
  background: #7ec344 !important;
}
.status-color-red:before {
  background: #d5002f !important;
}
.status-color-amber:before {
  background: #f58430 !important;
}

.outcome-divider.orange-clr .outcome-indicate:before {
  background: #f58430;
}
.outcome-divider.red-clr .outcome-indicate:before {
  background: #d5002f;
}
.outcome-divider p {
  margin: 0 0 12px;
  font-family: graphik-medium;
  letter-spacing: -0.25px;
  font-size: 12px;
  color: #212b36;
  word-break: break-all;
  line-height: 1.3;
}
.insightsNew .outcome-divider p {
  margin: 0;
}
.outcome-divider p:empty {
  display: none;
}

.outcome-divider p.minht,
.outcome-divider div.minht p {
  height: 32px;
  overflow: hidden;
  word-break: normal;
}
.insightsNew .outcome-divider div.minht {
  margin: 0 0 12px;
}
.insightsNew .outcome-divider div.minht p {
  height: 32px;
  overflow: hidden;
  word-break: normal;
}
.outcome-highlight.insightsNew h1 {
  margin-bottom: 0;
}
.outcome-highlight.insightsNew:first-child h1 {
  margin-bottom: 24px;
}
.ht-title {
  margin-right: 12px;
}
/* .ht-title.ml-adjust {
	  margin-left: 12px;
  }
  */
.rd-data + h2.ht-title {
  margin-top: 24px;
}
.rd-data.outcome-highlight h2.ht-title {
  font-size: 16px;
}
.rd-data .outcome-divider {
  text-align: center;
  padding-left: 10px;
}
.rd-data .outcome-indicate:before {
  display: none;
}
.rd-data .outcome-divider p {
  margin: 8px 0 0;
  color: #637381;
  font-family: graphik;
}
.rd-data.outcome-highlight h2 {
  font-size: 14px;
  border-top: 1px solid #dfe3e8;
  margin-top: 12px;
  padding-top: 24px;
  position: relative;
}
.rd-data.outcome-highlight h2:before,
.rd-data.outcome-highlight h2:after {
  content: "";
  position: absolute;
  top: -1px;
  left: -16px;
  background-color: #dfe3e8;
  height: 1px;
  width: 16px;
}
.rd-data.outcome-highlight h2:after {
  left: auto;
  right: -16px;
}
.rd-data.outcome-highlight h2:first-of-type {
  border-top: 0 none;
  margin-top: 0;
  padding-top: 0;
}
.rd-data.outcome-highlight h2:first-of-type::before,
.rd-data.outcome-highlight h2:first-of-type::after {
  display: none;
}
.data-presence .outcome-divider.active-data .outcome-indicate > p {
  color: #018fff;
}
.data-presence .outcome-divider.deactive-data .outcome-indicate > p {
  color: #979797;
}
/* .rd-data .outcome-divider span {
	  color: #20272a;
  }
  */
.outcome-divider h3 {
  margin-top: 5px;
  font-family: graphik-medium;
  font-size: 28px;
  color: #333333;
  margin-bottom: 0;
  letter-spacing: -0.35px;
}
.outcome-divider span.range {
  width: 30%;
  display: inline-block;
  height: 30px;
  background: #7ec344;
  margin: 0;
  vertical-align: text-bottom;
}
.outcome-divider span.range.width-70 {
  width: 70%;
}
.outcome-highlight h1 {
  color: rgb(40, 55, 71);
  font-family: graphik-medium;
  font-size: 18px;
  letter-spacing: -0.32px;
  margin-bottom: 30px;
}
.outcome-highlight h2,
.ht-title {
  font-size: 16px;
  font-family: graphik-medium;
  margin: 0 0 14px;
  letter-spacing: -0.25px;
  color: #212b36;
}
.outcome-highlight h2 + a {
  float: right;
  position: relative;
  top: -22px;
}
.outcome-highlight h2 + a > img {
  margin-left: 2px;
}
.outcome-highlight h4 {
  font-size: 13px;
  font-family: graphik-medium;
  letter-spacing: -0.13px;
  color: #000;
  margin: 6px 0 0;
}
.outcome-divider #actual {
  font-size: 12px !important;
}
.outcome-divider span.metric-details {
  font-size: 12px;
  font-family: graphik;
  color: #637381;
  display: block;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-bottom: 6px;
  text-overflow: ellipsis;
  /* word-break: break-word; */
  white-space: nowrap;
  overflow: hidden;
  height: 12px;
}
.insightsNew .outcome-divider span.metric-details {
  margin-bottom: 0;
}
.insightsNew .parent-metric-details span.metric-details {
  letter-spacing: -0.21px;
  line-height: 14px;
  height: 28px;
}
/*
  .outcome-divider span.metric-details::first-letter {
	text-transform: uppercase;
  }*/
.metric-details b {
  display: block;
  font-weight: normal;
}
.outcome-divider span.metric-details:last-child {
  margin-bottom: 0;
}
/* .outcome-divider .parent-metric-details span.metric-details:last-child {
	  margin-bottom: 0;
  }
  */
.outcome-divider span.metric-digit {
  font-size: 24px;
  font-family: graphik-medium;
  color: #212b36;
  letter-spacing: -0.35px;
  line-height: 24px;
  display: flex;
  align-content: flex-end;
  /* word-break: break-word; */
}
.insightsNew .outcome-divider span.metric-digit {
  font-family: graphik-bold;
  margin-bottom: 12px;
  margin-top: 2px;
}
.outcome-divider span.metric-digit-text {
  color: #637381;
  font-family: graphik;
  font-size: 12px;
  letter-spacing: -0.25px;
  vertical-align: bottom;
  position: relative;
  top: 2px;
  max-height: 24px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
.outcome-divider .rd-progress span.metric-digit-text {
  top: -4px;
}
/* span.metric-digit-text:empty {
	  display: none;
  }
  .outcome-divider span.metric-digit-text:first-child {
	  margin-left: 0;
  } */
.outcome-divider h3 span {
  margin: 0 0 0 5px;
}
/* .blue-cat {
	  margin: 0 0 12px;
  } */
.blue-cat span {
  background: rgba(1, 142, 255, 0.2);
  color: #018eff;
  font-size: 12px;
  padding: 3px 15px;
  border-radius: 14px;
  margin: 2px 5px 5px 0;
  font-family: graphik-medium;
  display: inline-block;
}
/* .metric-digit {
	  font-size: 28px !important;
	  font-weight: 500;
	  font-family: "graphik-medium" !important;
	  margin-right: 10px;
  }
  */
.metric_View {
  color: #4f555b !important;
  float: left;
  cursor: pointer;
  padding: 0 15px 0 0;
}
/* .metric_View:hover {
	  color: #4f555b;
  }
  */
.outcome-highlight.dash-settings h4 {
  font-size: 15px;
  margin: 0;
}
.outcome-highlight.dash-settings h3 {
  font-size: 28px;
  font-family: graphik-medium;
  margin: 5px 0 0;
}
.outcome-highlight.dash-settings h3 img {
  margin-right: 5px;
}
.outcome-divider.dash-settings span {
  color: #7f8e9b;
  font-family: graphik-medium;
}
/* .outcome-highlight.dash-settings .outcome-divider:before {
	  position: absolute;
	  content: "";
	  background: #0386ff;
	  width: 2px;
	  height: 100%;
	  border-radius: 15px;
	  left: 0;
	  top: 0;
  }
  */
.outcome-highlight.dash-settings h2 {
  font-family: graphik-semibold;
  font-size: 20px;
}
.outcome-highlight.dash-settings.move-down {
  margin-top: 150px;
}
.outcome-highlight.dash-settings > .row {
  margin-bottom: 25px;
}
W .outcome-highlight.dash-settings > .row:last-child {
  margin-bottom: 5px;
}
/* .outcome-highlight.dash-settings {
	  padding: 15px;
  }
  */
.outcome-highlight.dash-settings.lg-lt-arrow:after {
  background: transparent url(../Images/line-lt.png) no-repeat scroll 0px 0px;
  width: 107px;
  content: "";
  height: 22px;
  position: absolute;
  left: 96.5%;
  top: 70px;
}
.outcome-highlight.dash-settings.lg-rt-arrow:before {
  background: transparent url(../Images/line-rt.png) no-repeat scroll 0px 0px;
  width: 69px;
  content: "";
  height: 22px;
  position: absolute;
  left: -54px;
  top: 150px;
}
.outcome-list {
  margin: 18px 0;
}
.outcome-list .outcome-table {
  margin: 18px 0 35px;
}
.outcome-list:last-of-type .outcome-table {
  margin-bottom: 0;
}
.outcome-sq-br .outcome-list .outcome-table {
  margin: 0;
}
.outcome-highlight .outcome-list h2 {
  font-size: 18px;
  font-family: graphik-medium;
}
.outcome-highlight .outcome-list h4 {
  font-size: 13px;
  font-family: graphik;
  letter-spacing: -0.27px;
  color: rgba(0, 0, 0, 0.8);
}
.outcome-highlight h4.title-icon {
  font-size: 16px;
  margin: 15px 0;
  font-family: graphik-semibold;
  letter-spacing: -0.32px;
}
.outcome-highlight h4.title-icon img {
  margin-right: 10px;
}
.outcome-graph h4 {
  margin: 12px 0 15px;
}
.outcome-graph {
  margin: 25px 0;
}
/* .outcome-graph:first-of-type {
	  margin-top: 0;
  }
  */
.block-table .outcome-divider {
  display: inline-block;
}
.person-img {
  padding: 50px 0;
  text-align: center;
}
/* .outcome-tab > ul {
	  padding: 0;
	  display: table;
	  width: 100%;
	  margin: 0;
  }
  .outcome-tab .dropdown {
	  text-align: center;
  }
  .outcome-tab li {
	  list-style: none outside none;
	  float: left;
	  margin: 0 5px;
  }
  .outcome-tab li:first-child {
	  margin-left: 0;
  }
  .outcome-tab li .tab-link {
	  display: block;
	  padding: 18px 12px;
	  border-radius: 10px;
	  text-align: center;
	  font-size: 13px !important;
	  position: relative;
	 /* min-width: 174px;
	  cursor: pointer;
	  border: 1px solid transparent;
  }
  .outcome-tab li .tab-link-hide {
	  display: none;
  }
  .outcome-tab li .tab-link img {
	  width: 26px;
	  height: 28px;
  }
  .outcome-tab li:first-child .tab-link img {
	  width: auto;
	  height: auto;
  }
  .outcome-tab li .tab-link span {
	  color: #979797;
	  margin-top: 10px;
	  margin-bottom: 4px;
	  display: block;
	  font-family: graphik-medium;
	  font-size: 13px;
  }
  .outcome-tab li .tab-link span.caret {
	  display: inline-block;
	  margin: 0;
  }
  .outcome-tab li .tab-link:hover span {
	  color: #000000;
  }
  .outcome-tab li .tab-link.active span {
	  color: #000000;
  }
  .outcome-tab li .tab-link.active span.caret {
	  color: #00baff;
  }
  */
/* .outcome-tab.without-bo li:first-child .tab-link:after {
	  display: none;
  }
  */
.bu-title-text {
  display: table-cell;
  vertical-align: top;
  text-align: left;
}
.bu-title-text h3 {
  margin-top: 0;
  margin-bottom: 3px;
  font-family: graphik-black;
  color: #4f555b;
}
.bu-title-text p {
  margin: 0;
  font-size: 14px;
  font-family: roboto;
  color: #4f555b;
  line-height: 18px;
}
.bu-outcomes-title .bu-title-img {
  display: table-cell;
  padding-right: 15px;
  vertical-align: top;
  padding-top: 5px;
}
.bu-outcomes-title {
  display: table;
}
.main-data {
  background: #fff;
  padding: 15px;
  margin-top: 14px;
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.1), 0 1px 0 0 #f4f6f8;
  border-radius: 5px;
  margin-bottom: 24px;
}
.main-data .row:last-child .form-group.col {
  margin-bottom: 0;
}
.label-adjust {
  margin-top: 26px;
}
.btn-sm {
  padding: 0.367rem 0.5rem;
}
.tableFilter {
  /* display: flex; */
  margin: 0 0 8px 0;
}
.userlistloader {
  max-width: 100%;
  margin-left: auto;
  margin-right: 10px;
}
.tableFilter.justify-content-center {
  margin-bottom: 10px;
}
/* .tableFilter .form-control {
	  width: 250px;
	  margin-right: 18px;
	  font-size: 14px;
  } */
.decline-space-btn {
  margin: 10px;
  padding: 7px 20px;
  border-radius: 7px;
}
.svg-inline--fa {
  font-size: 14px;
  cursor: pointer;
}
.float-icon {
  position: absolute;
  right: 50%;
  top: -12px;
  margin-right: 5px;
}
.float-icon span {
  background: #018eff;
  color: #ffffff;
  margin-left: 5px;
  width: 24px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  height: 24px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
}
.float-icon span .svg-inline--fa {
  font-size: 12px;
  vertical-align: baseline;
}
.timeline-content:hover span,
.timeline-box.active .timeline-content span {
  background: #ffffff;
  color: rgb(161, 0, 255);
}
/* .tab-list {
	  padding-top: 50px;
	  padding-bottom: 40px;
	  background: #f9fafb;
  } */
.nav-tabs {
  border-bottom: 2px solid #018eff;
}
.nav-tabs > li {
  margin-bottom: -2px;
}
/* .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
	  background: #f9fafb;
	  border: 2px solid #018eff;
	  border-bottom-color: transparent;
  }
  */
.nav-tabs > li > a.active,
.nav-tabs > li > a.active:hover {
  background: #f9fafb;
  border: 2px solid #018eff;
  border-bottom-color: transparent;
}
.nav-tabs > li > a {
  font-family: "Graphik-Semibold";
  font-size: 14px;
  cursor: pointer !important;
}
/* grid
  .ag-body-viewport.ag-layout-normal::-webkit-scrollbar-track {
	  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	  -moz-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	  border-radius: 10px;
	  background-color: #F5F5F5;
  }
  .ag-body-viewport.ag-layout-normal::-webkit-scrollbar {
	  width: 10px;
	  background-color: #F5F5F5;
  }
  .ag-body-viewport.ag-layout-normal::-webkit-scrollbar-thumb {
	  border-radius: 10px;
	  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	  -moz-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	  background-color: #ccc;
  }
  .ag-floating-filter-input {
	  display: block;
	  width: 100%;
	  height: 34px;
	  padding: 6px 12px;
	  font-size: 14px;
	  line-height: 1.42857143;
	  color: #555 !important;
	  background-color: #fff !important;
	  background-image: none;
	  border: 1px solid #ccc;
	  border-radius: 4px;
	  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
	  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  }
  .btn btn-primary btn-sm {
	  display: inline-block;
	  padding: 6px 12px;
	  margin-bottom: 0;
	  font-size: 14px;
	  font-weight: 400;
	  line-height: 1.42857143;
	  text-align: center;
	  white-space: nowrap;
	  vertical-align: middle;
	  touch-action: manipulation;
	  cursor: pointer;
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  -ms-user-select: none;
	  user-select: none;
	  background-image: none;
	  border: 1px solid transparent;
	  border-radius: 4px;
	  color: #fff;
	  background-color: #018eff;
	  border-color: #2e6da4;
  }
  .ag-root-wrapper.ag-layout-normal {
	  background: #f9fafb;
  }
  .ag-paging-page-summary-panel {
	  height: 43px;
  } */
.ag-table.ag-theme-balham {
  height: 440px;
  width: 100%;
  background-color: transparent;
  margin-bottom: 8px;
}
.ag-root-wrapper-body {
  background-color: #fff;
}
.ag-table.ag-theme-balham .ag-header-cell-label .ag-header-cell-text,
.ag-table.ag-theme-balham .ag-cell {
  font-size: 13px;
}
.ag-theme-balham .ag-root,
.ag-table.ag-theme-balham .ag-header,
.ag-table.ag-theme-balham .ag-paging-panel {
  border-color: #dfe3e8;
}
/* .ag-theme-balham .ag-root {
	  border-bottom: 0 none;
  } */
.ag-table.ag-theme-balham .ag-header-cell::after,
.ag-table.ag-theme-balham .ag-header-group-cell::after {
  margin-top: 10px;
}
.ag-theme-balham [class^="ag-icon"] {
  vertical-align: text-top;
}
.ag-theme-balham .ag-header-select-all [class^="ag-icon"],
.ag-theme-balham .ag-selection-checkbox [class^="ag-icon"] {
  vertical-align: unset;
}
.ag-table.ag-theme-balham .ag-header-cell,
.ag-table.ag-theme-balham .ag-header-group-cell {
  border-style: solid;
  border-color: #dfe3e8;
  line-height: 36px;
}
.ag-table.ag-theme-balham .ag-paging-panel button {
  background-size: 24px 24px;
  height: 24px;
  width: 24px;
}
.ag-table.ag-theme-balham .ag-paging-panel {
  color: rgba(0, 0, 0, 0.65);
  letter-spacing: 0.5px;
  font-size: 14px;
  border-top: 0;
}
.ag-theme-balham .ag-cell {
  line-height: 36px;
}
.ag-theme-balham .ag-paging-panel > span {
  margin-top: 7px;
}
/* .ag-theme-balham .ag-row-odd {
	  background-color: #fafafa;
  } */
.whiteBgGraph {
  padding: 25px 20px 15px;
  background: #fff;
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.1), 0 1px 0 0 #f4f6f8;
  border-radius: 5px;
}
/* .datepicker-label {
	  margin-right:20px;
  }
  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
	  font-size: 1.2rem !important;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
	  font-size: 10px;
  }
  .react-datepicker__input-container {
	  padding-right: 15px;
  }
  .react-datepicker-popper {
	  width: 15%;
  }
  .react-datepicker__month-container {
	  width: 100% !important;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
	  width: 2.3rem !important;
  }
  .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
	  width: 3.5rem;
  } */
.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.input-group.borderless-right > .react-datepicker-wrapper .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 3px;
  border-right: 0;
}
.blue-border .form-control {
  color: #018eff;
}
.blue-border .form-control,
.blue-border .icon-target {
  border-color: #80bdff;
}
.input-group.borderless-right > .react-datepicker-wrapper .form-control:focus,
.input-group.borderless-right > .react-datepicker-wrapper .form-control:active {
  box-shadow: none;
}
.icon-target {
  position: relative;
  background: #ffffff;
  border-color: #ced4da;
  border-left: 0;
  padding-left: 3px;
}
.icon-target label {
  margin: 0;
  color: #a2b9c4;
}
.icon-target label:after {
  background: transparent;
  height: 100%;
  width: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}
.ag-theme-blue select {
  background: #fff !important;
  color: #555 !important;
  overflow: auto;
  height: 100px;
  padding: 10px;
}
.ag-theme-blue input {
  display: block;
  width: 100%;
  height: 34px !important;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555 !important;
  background-color: #fff !important;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.ag-theme-blue .ag-paging-panel {
  border-top: 0 !important;
  margin-top: 20px;
}
.ag-theme-blue .ag-ltr .ag-cell,
.ag-theme-blue .ag-header-cell {
  border-right: 0 !important;
}
.ag-theme-blue .ag-row {
  border-bottom-width: 0 !important;
}
.ag-theme-blue .ag-ltr .ag-cell .grid-heading {
  border-bottom: 1px solid #d9dcde;
  padding: 15px 0;
}
.grid-heading.bg-heading {
  background: #fafafa;
  color: #3a3a3a;
  padding: 12px 12px;
  margin: -15px -15px 8px;
  border-radius: 5px 5px 0 0;
  font-size: 15px;
  border-bottom: 1px solid #eaeaea;
}
.grid-heading.bg-heading + .grid-heading {
  font-size: 14px;
  margin: 14px 0;
}
/* .ag-theme-balham .ag-header-cell-label .ag-header-cell-text {
	  font-weight: bold;
	  font-size: 18px;
  }
  .ag-theme-balham .ag-cell {
	  font-size: 16px;
  }
  .ag-theme-balham .ag-root {
	  border: 0 !important;
  } */
.main-sidebar {
  background: #ffffff;
  box-shadow: inset 1px 0px 0px 0px rgb(244, 246, 248), 1px 0px 0px 0px rgb(223, 227, 232),
    2px 0px 16px 0px rgba(33, 43, 54, 0.1);
  width: 240px;
  position: fixed;
  top: 128px;
  left: 0;
  height: 100%;
  padding: 80px 4px 48px;
  /* margin: 0 0 -80px; */
  z-index: 11;
  overflow: auto;
  display: none;
}
/* .main-sidebar:after {
	  content: '';
	  display: block;
	  height: 48.67px;
  } */
.main-sidebar h4 {
  color: #212b36;
  font-family: graphik-medium;
  font-size: 14px;
  letter-spacing: -0.25px;
  margin: 24px 8px 8px;
}
/* .main-sidebar ul::-webkit-scrollbar-track {
	  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	  background-color: #F5F5F5;
  }
  .main-sidebar ul::-webkit-scrollbar {
	  width: 6px;
	  background-color: #F5F5F5;
  }
  .main-sidebar ul::-webkit-scrollbar-thumb {
	  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	  background-color: #888;
  }
  */
.main-sidebar ul {
  padding: 0;
  list-style: none outside none;
  /* height: 100%;
	  overflow: auto;
	  */
}
.main-sidebar li {
  position: relative;
}
/* .main-sidebar li img {
	width: 20px;
	display: inline-block;
	margin-right: 4px;
	background-color: #018eff;
	vertical-align: top;
	padding: 2px;
	border-radius: 2px;
  } */
.main-sidebar li a {
  color: #212b36;
  font-size: 12px;
  font-family: graphik;
  display: block;
  letter-spacing: -0.25px;
  cursor: pointer;
  line-height: 20px;
  padding: 8px;
  border-radius: 5px;
}
.main-sidebar li a:hover,
.main-sidebar li a.active {
  background-color: rgb(1, 142, 255, 0.08);
  color: #018eff !important;
  font-family: Graphik-Medium;
}
/* .main-sidebar li:last-child {
	  margin-bottom: 15px;
  }
  .main-sidebar li span {
	  color: #212b36;
	  font-family: graphik;
	  font-size: 12px;
  }
  */
.main-sidebar > ul > li.popmenu > .popsubmenu {
  height: auto;
  position: absolute;
  right: -457px;
  top: 0;
  width: 455px;
  padding-left: 15px;
  display: none;
}
.main-sidebar > ul > li.popmenu:hover > .popsubmenu ul:empty:after {
  display: none;
}
.main-sidebar > ul > li.popmenu:hover > .popsubmenu {
  display: block;
}
.main-sidebar > ul > li.popmenu > .popsubmenu ul {
  border-radius: 10px;
  background: #1d2b36;
  float: left;
  position: relative;
}
.main-sidebar > ul > li.popmenu > .popsubmenu > ul:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.main-sidebar > ul > li.popmenu > .popsubmenu > ul:after {
  border-color: rgba(29, 43, 54, 0);
  border-right-color: #1d2b36;
  border-width: 10px;
  margin-top: -10px;
}
/* .main-sidebar > ul > li.popmenu > .popsubmenu > ul:before {
	  border-color: rgba(29, 43, 54, 0);
	  border-right-color: #1d2b36;
	  border-width: 10px;
	  margin-top: -10px;
  }
  */
.main-sidebar > ul > li.popmenu > .popsubmenu > ul li {
  float: left;
  width: 110px;
}
.main-sidebar > ul > li.popmenu > .popsubmenu > ul li a {
  padding: 15px 20px;
  height: 106px;
  text-align: center;
}
.main-sidebar > ul > li.popmenu > .popsubmenu > ul li:first-child a {
  border-radius: 10px 0 0 10px;
}
.main-sidebar > ul > li.popmenu > .popsubmenu > ul li:last-child a {
  border-radius: 0 10px 10px 0;
}
.main-sidebar li span.popimg {
  background: #999;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  padding: 7px;
  text-align: center;
  margin-bottom: 10px;
}
/* .select-data {
	  margin: 0 -1%;
  }
  */
.business-filter .select-data {
  margin: 0;
}
.navbar .select-data {
  position: relative;
  padding-right: 16px;
  margin-right: 28px;
}
.select-data img {
  vertical-align: top;
}
.select-data label {
  display: none;
  font-weight: 700;
}
.select-data select {
  padding: 3px 5px;
  color: #637381;
  font-family: graphik;
  font-size: 12px;
  border: 0px solid transparent;
  letter-spacing: -0.25px;
  width: 130px;
}
.select-data .select-custom {
  margin-right: 8px;
}
.select-data .select-custom:last-of-type {
  margin-right: 0;
}
.select-data input {
  color: rgb(33, 43, 54);
  font-size: 16px;
  letter-spacing: -0.25px;
  /* line-height: 20px;
	  */
  padding: 4px 4px 4px 8px;
  width: 100%;
  border: 1px solid transparent;
  font-family: graphik-medium;
}
.select-data select:disabled {
  opacity: 0.7;
}
.select-data select:focus {
  outline: 0;
}
.para-heading {
  font-family: graphik-black;
  font-size: 19px;
  color: #4f555b;
  line-height: 22px;
  margin-top: 15px;
}
.breadcrumb-title .dropdown {
  float: right;
}
.dropdown-toggle {
  cursor: pointer;
}
.breadcrumb-title .dropdown-menu > li > a {
  font-size: 13px;
  white-space: normal;
  color: #333 !important;
  /* padding: 5px 20px;
	  */
  /* border-bottom: 1px solid #ccc;
	  */
}
.breadcrumb-title .dropdown-menu > li:last-child a {
  border-bottom: 0 none;
}
.breadcrumb-title a.dropdown-toggle {
  font-size: 14px;
  letter-spacing: -0.22px;
  color: #637381;
  padding-right: 6px;
}
/* .title-card {
	  float: left;
  }
  */
.remarks-popup {
  right: 25px;
  position: relative;
}
.remarks-popup a {
  cursor: pointer;
}
.remarks-popup a .fa-info-circle {
  color: red;
  font-size: 24px;
}
.remarks-ioj {
  padding: 25px 15px;
  left: 0 !important;
}
.title-card h3 {
  font-family: graphik-black;
  font-size: 20px;
  color: #4f555b;
  line-height: 24px;
  margin: 0 0 5px;
}
.title-card p {
  margin: 0;
  font-size: 13px;
  font-family: roboto;
  color: #4f555b;
}
.breadcrumb {
  background: #fff;
  box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 15px 0 10px;
  float: right;
  margin-bottom: 15px;
}
.breadcrumb li {
  float: left;
  list-style: none outside none;
  padding: 8px 12px;
}
.breadcrumb li:after {
  content: ">";
  position: relative;
  color: #008eff;
  left: 8px;
  font-size: 14px;
  font-family: graphik-medium;
}
.breadcrumb p {
  float: left;
  padding: 8px 10px;
  border-right: 1px solid #ccc;
  margin: 0;
}
.breadcrumb ul {
  margin: 0;
  float: left;
  padding: 0;
}
.breadcrumb li a {
  color: #008eff;
}
.chart-table td {
  width: 20%;
  background: #ccc;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  padding: 20px;
  font-size: 20px;
  border-right: 2px solid #fff;
}
table.chart-table {
  margin: 20px 0 25px;
}
.chart-table td:last-child {
  border-right: 0;
}
/* .gray-clr {
	  background-color: #919eab !important;
  }
  .dark-gray-clr {
	  background-color: #333333 !important;
  }
  .blue-clr {
	  background-color: #008eff !important;
  }
  .lt-blue-clr {
	  background-color: #3accf8 !important;
  }
  .lightblue-clr {
	  background-color: #00BBFF !important;
  }
  .dark-blue-clr {
	  background-color: #034a85 !important;
  } */
.outcome-divider.hlt-bg-blue {
  padding: 15px 35px;
  background-color: rgba(0, 255, 255, 0.5) !important;
  border: 1px solid rgb(2, 216, 216);
  border-radius: 5px;
}
.outcome-divider.hlt-bg-blue:before {
  height: 62%;
  top: 19%;
  left: 18px;
}
/* .outcome-divider.blue-clr, .outcome-divider.dark-blue-clr, .outcome-divider.gray-clr, .outcome-divider.dark-gray-clr, .outcome-divider.lightblue-clr, .outcome-divider.lt-blue-clr {
	  background: transparent !important;
  } */
.gray-clr.outcome-divider .outcome-indicate:before {
  background-color: #919eab !important;
}
.dark-gray-clr.outcome-divider .outcome-indicate:before {
  background-color: #333333 !important;
}
.blue-clr.outcome-divider .outcome-indicate:before {
  background-color: #008eff !important;
}
.lt-blue-clr.outcome-divider .outcome-indicate:before {
  background-color: #00baff !important;
}
.lightblue-clr.outcome-divider .outcome-indicate:before {
  background-color: #00bbff !important;
}
.dark-blue-clr.outcome-divider .outcome-indicate:before {
  background-color: #034a85 !important;
}
span.text-color {
  color: #7ec344;
}
.red-clr span.text-color {
  color: #d5002f !important;
}
.dark-gray-clr span.text-color {
  color: #333333 !important;
}
.blue-clr span.text-color {
  color: #008eff !important;
}
.lt-blue-clr span.text-color {
  color: #00baff !important;
}
.lightblue-clr span.text-color {
  color: #00bbff !important;
}
.dark-blue-clr span.text-color {
  color: #034a85 !important;
}
span.upwards {
  -webkit-transform: rotate(50deg);
  transform: rotate(50deg);
}
span.downwards {
  -webkit-transform: rotate(-50deg);
  transform: rotate(-50deg);
}
.upwards svg {
  color: #7ec344 !important;
}
.downwards svg {
  color: #d5002f !important;
}
.owl-carousel .owl-nav [class*="owl-"] {
  font-size: 0;
  margin-top: -28px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 56px;
  position: absolute;
  right: -5px;
  top: 50%;
  border: 0 none;
  color: #4f555b;
  vertical-align: baseline;
  border-radius: 4px;
}
.batView .tilecard-border .owl-carousel .owl-nav [class*="owl-"] {
  background: linear-gradient(-225deg, rgb(245, 251, 255) 0%, rgb(171, 216, 253) 100%);
}
.owl-carousel .owl-nav [class*="owl-"]:active,
.owl-carousel .owl-nav [class*="owl-"]:focus,
.owl-carousel .owl-nav [class*="owl-"]:hover {
  outline: 0 none;
  background: #ffffff;
  color: #fff;
  text-decoration: none;
}
.batView .tilecard-border .owl-carousel .owl-nav [class*="owl-"]:active,
.batView .tilecard-border .owl-carousel .owl-nav [class*="owl-"]:focus,
.batView .tilecard-border .owl-carousel .owl-nav [class*="owl-"]:hover {
  background: linear-gradient(-225deg, rgb(245, 251, 255) 0%, rgb(171, 216, 253) 100%);
}
.owl-carousel .owl-nav [class*="owl-"]:hover {
  opacity: 0.8;
}
.owl-carousel .owl-nav .owl-prev {
  left: -5px;
  right: auto;
}
.owl-carousel .owl-nav .owl-next:before,
.owl-carousel .owl-nav .owl-prev:before {
  background: transparent url(../Images/caro-arrow.jpg) no-repeat scroll center;
  width: 12px;
  content: "";
  height: 12px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -6px 0 0 -6px;
  background-size: 100%;
}
.owl-carousel .owl-nav .owl-prev:before {
  background-image: url(../Images/caro-arrow-prev.jpg);
}
.batView .tilecard-border .owl-carousel .owl-nav .owl-prev:before {
  background-image: url(../Images/angle-left.svg);
}
.batView .tilecard-border .owl-carousel .owl-nav .owl-next:before {
  background-image: url(../Images/angle-right.svg);
}
.owl-carousel .owl-nav [class*="owl-"] span {
  vertical-align: text-bottom;
  margin-left: 8px;
}
.owl-carousel .owl-nav .owl-prev span {
  vertical-align: text-bottom;
  margin-left: 0;
  margin-right: 8px;
}
.owl-carousel .owl-nav .disabled {
  opacity: 0;
}
.dropdown-menu.no-design {
  position: inherit;
  float: none;
  box-shadow: none;
  background: transparent;
  border-radius: unset;
  min-width: inherit;
  margin: 0;
  padding: 0;
  border: 0 none;
}
.outcome-highlight.dash-settings.orch-sidebar h2 {
  margin: 15px 0 5px;
  font-family: graphik-medium;
  font-size: 14px;
  letter-spacing: -0.25px;
  color: #000000;
  margin: 10px 0 5px;
}
.outcome-highlight.dash-settings.orch-sidebar {
  /* padding: 5px 20px;
	  */
  position: relative;
}
.sidebar-cont .outcome-highlight {
  margin-top: 18px;
}
.outcome-divider.row-divider.dropdown-toggle {
  margin-bottom: 10px;
  padding-right: 0;
}
.outcome-highlight.dash-settings.orch-sidebar .outcome-table {
  margin-top: 0;
}
span.caret.dropdown-toggle {
  position: absolute;
  top: 0;
  right: 0;
  float: right;
  margin: 25px 0 0;
  border-width: 10px;
  color: #d2d7dd;
}
.outcome-highlight.dash-settings.orch-sidebar .outcome-divider.row-divider {
  /* margin: 15px 0 25px;
	  */
  padding-right: 0;
}
.outcome-highlight.dash-settings.orch-sidebar .outcome-table .outcome-row {
  display: block;
}
span.orch-icon {
  float: right;
}
.timeline-box {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  /* height: 136px;
	  width: 200px;
	  margin: 18px;
	  new design*/
  /* height: 169px; */
  width: 149px;
  margin: 4px 12px 32px;
}
.timeline-content {
  width: 149px;
  height: 100%;
  /* width: 200px;
	  height: 136px;
	  new design */
  position: relative;
  z-index: 1;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  background: #fff;
  border-radius: 8px;
  padding: 16px 16px 40px 16px;
  margin: 0;
  cursor: pointer;
}
.timeline-content:hover {
  background: #018eff;
  color: #ffffff;
}
.timeline-box.active .timeline-content {
  background: #018eff;
  color: #ffffff;
}
.timeline-content img {
  margin-bottom: 5px;
}
.timeline-content p {
  margin-bottom: 5px;
  color: #212b36;
  line-height: 18px;
  letter-spacing: -0.25px;
  font-size: 14px;
  word-break: break-all;
}
.timeline-box.active .timeline-content p,
.timeline-content:hover > p {
  color: #ffffff;
}
.timeline-highlight {
  padding: 14px 0;
  display: table;
  width: 100%;
  /* margin: 0 -10px;
	  */
  overflow: hidden;
  max-width: 730px;
}
/* .corner-stable {
	  display: inline-block;
	  background: #00baff;
	  color: #fff;
	  padding: 8px 30px 8px 25px;
	  border-radius: 40px;
	  text-align: left;
  }
  */
/* .corner-stable p {
	  margin: 0;
	  font-size: 11px !important;
	  font-family: graphik-light;
	  letter-spacing: -0.23px;
  }
  */
.corner-stable {
  position: relative;
  padding-left: 24px;
}
corner-stableIOJ:before {
  width: 1px;
  height: 24px;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
}
.corner-stable:before {
  border-right: 1.5px solid #dfe3e8;
  width: 1px;
  height: 24px;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
}
.corner-stable a,
.corner-stable p {
  color: #637381;
  font-size: 14px;
  letter-spacing: -0.22px;
  margin-right: 6px;
  padding-right: 6px;
  position: relative;
  margin-bottom: 0px;
  display: inline-block;
}
.corner-stable a:after,
.corner-stable p:after {
  content: "";
  background-color: #dfe3e8;
  height: 14px;
  width: 1.5px;
  position: absolute;
  right: -0.75px;
  top: 50%;
  margin-top: -7px;
}
.corner-stable a:last-child:after,
.corner-stable p:last-child:after {
  display: none;
}
.corner-stable a:last-child,
.corner-stable p:last-child {
  margin-right: 0;
  padding-right: 0;
}
.corner-stable a.active,
.corner-stable p.active {
  font-family: graphik-semibold;
  color: #018fff;
}
.corner-stable a i,
.corner-stable p i {
  margin-right: 4px;
}
.client-select {
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
  padding: 20px 50px;
  background: #fff;
  margin: 25px 0;
  text-align: center;
  border-radius: 20px;
}
.client-select h3 {
  font-family: graphik-medium;
  text-transform: uppercase;
  font-size: 16px;
  color: #666;
  letter-spacing: 1px;
  margin: 20px 0 30px;
}
.login-client .main-container {
  position: inherit;
  margin-left: 0;
  top: auto;
  left: auto;
}
.login-client .select-data select {
  padding: 10px;
  border-radius: 20px;
  border-color: transparent;
  background: rgba(0, 12, 25, 0.05);
  height: 45px;
  box-shadow: none;
  width: 100%;
}
.client-select button.btn.btn-primary {
  width: 100%;
  border-radius: 30px;
  padding: 10px;
}
.client-select p {
  margin: 40px 0;
}
.client-select p a {
  color: #888;
  text-decoration: underline;
  font-family: graphik;
}
.login-client .footer {
  position: inherit;
  background: transparent;
  box-shadow: none;
  text-align: center;
}
.login-client .footer-logo {
  float: none;
  padding: 0;
}
.login-client .footer-logo img {
  width: 350px;
}
.login-client p.copyright {
  text-align: center;
  padding-top: 30px;
}
.form-section h3 {
  margin: 8px 0 12px;
  font-size: 16px;
  border-bottom: 1px solid #dedede;
  font-family: graphik-semibold;
  padding-bottom: 5px;
}
.form-section:first-child h3 {
  margin-top: 0;
}
.form-section label {
  color: #6f7880;
  margin-bottom: 3px;
  font-size: 13px;
  letter-spacing: -0.5px;
}
.form-section .form-group p {
  border-bottom: 2px solid #7b8e98;
  padding-bottom: 3px;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
}
.modal-content {
  overflow: hidden;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.15);
}
.modal-header .close {
  background: url(../Images/modal-close.png) no-repeat scroll 0 0 transparent;
  background-size: 16px 16px;
  height: 16px;
  width: 16px;
  opacity: 1;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 16px;
  right: 16px;
  outline: none;
}
.modal-header .close span {
  font-size: 0;
}
.modal-footer .form-group {
  margin-bottom: 0;
}
.modal-footer {
  background-color: #f9fafb;
  border-color: #dfe3e8;
  display: block;
}
.modal-footer .btn,
.radius-btn .btn {
  padding: 0.367rem 1rem;
  border-radius: 24px;
}
.modal-btn {
  display: flex;
  justify-content: flex-end;
}
.modal-title {
  /* font-size: 20px;
	  font-family: graphik-semibold;
	  letter-spacing: -0.35px;
	  */
  display: inline-block;
  color: rgb(33, 43, 54);
  font-family: graphik-medium;
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 20px;
}
/* div[role="dialog"] {
	  display: none;
  }
  div[role="dialog"]:last-of-type {
	  display: block;
  } */
.form-section:last-child .form-group {
  margin-bottom: 12px;
}
.footer-logo {
  float: left;
  padding: 6px 0 5px;
}
p.copyright,
span.copyright {
  color: #919eab;
  font-size: 12px !important;
  font-family: graphik;
  text-align: right;
  padding: 15px 0;
  margin: 0;
  letter-spacing: -0.19px;
}

span.copyright-add {
  margin-left: 91px;
  display: inline-block;
  padding: 10px 0 7px;
  vertical-align: top;
  color: #919eab;
  font-size: 12px !important;
  font-family: graphik;
  text-align: right;
  letter-spacing: -0.19px;
}
span.copyright {
  margin-left: 55px;
  display: inline-block;
  padding: 10px 0 7px;
  vertical-align: top;
}
p.copyright a {
  font-family: graphik;
  color: #919eab;
  letter-spacing: -0.21px;
  margin: 0 0 0 40px;
  font-size: 12px;
}
.insights-heading {
  margin-top: 0;
}
.insights-img {
  width: 32px;
  height: 32px;
}
.insights-header {
  display: flex;
  align-items: center;
  position: relative;
}
.insights-content {
  padding-top: 20px;
}
.insights-title {
  margin-left: 16px;
}
.insights-header .float-icon {
  right: -15px;
  top: -15px;
}
.insights {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.25);
  padding: 16px;
  margin: 0 0 20px;
  position: relative;
}
.big-tab .insights.active,
.big-tab .insights:hover {
  background-color: rgb(1, 142, 255);
}
.insights-title h3 {
  color: rgb(1, 142, 255);
  font-family: graphik-semibold;
  font-size: 16px;
  letter-spacing: -0.35px;
  line-height: 12px;
}
.big-tab .insights.active .insights-title h3,
.big-tab .insights:hover .insights-title h3 {
  color: rgb(255, 255, 255);
}
.insights-title > p {
  color: #637381;
  font-family: graphik;
  font-size: 12px;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-bottom: 0;
}
.big-tab .insights.active .insights-title > p,
.big-tab .insights:hover .insights-title > p {
  color: rgb(223, 227, 232);
}
.insights:after {
  content: "";
  position: absolute;
  top: 25px;
  left: -70px;
  background: url(../Images/share.png) no-repeat scroll 0 0 transparent;
  height: 50px;
  width: 65px;
  z-index: 1;
}
.insights.insights-1:after {
  display: none;
}
/* .insights-content ul {
	  padding: 0;
	  margin-bottom: 15px;
	 min-height: 286px; 
  } */
.insights-list li {
  border-top: 1px solid #dedede;
  padding: 10px 5px 8px;
  font-size: 13px;
}
.insights-list li:last-child {
  border-bottom: 1px solid #dedede;
}
.insights-list li span {
  color: #018fff;
  cursor: pointer;
}
.insights-list li.list-with-icon {
  padding-right: 15px;
  position: relative;
}
.insights-list li span.list-trash {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -9px;
  color: #d5002f;
}
/* .insights-content li.outcome-divider {
	  border-top: 1px solid #dedede;
	  margin-top: 0;
	  margin-bottom: 0;
	  padding: 10px 10px 10px 15px;
	  border-radius: 0;
	  box-shadow: none;
  }
  .insights-content li.outcome-divider:last-child {
	  border-bottom: 1px solid #dedede;
  }
  .insights-content li.outcome-divider:before {
	  height: 70%;
	  top: 15%;
  } */
.insights-footer {
  /* padding: 15px 0 10px;
	  */
  text-align: center;
}
.insights-footer:empty {
  padding-top: 35.67px;
}
.insights-footer a,
.linkSpan {
  color: #018fff;
  font-family: graphik-medium;
  letter-spacing: -0.25px;
  font-size: 14px;
  cursor: pointer;
}
.insights.insights-4 {
  margin-top: 0px;
}
.insights.insights-3 {
  margin-top: 90px;
}
.insights.insights-2 {
  margin-top: 180px;
}
.insights.insights-1 {
  margin-top: 270px;
}
.modal-body .insights-content li.outcome-divider:first-child {
  border-top: 0 none;
}
.modal-body .insights-content ul {
  max-height: inherit;
  padding-bottom: 0;
}
.insights-footer a.btn {
  font-size: 13px;
  padding: 5px 10px;
  margin-top: -5px;
  color: #fff;
}
.big-tab-container > h3 {
  font-family: graphik-black;
  font-size: 30px;
  color: #4f555b;
}
.big-tab li {
  list-style: none outside none;
}
.big-tab .row li {
  padding: 0 8px;
}
.big-tab .row {
  margin: 0 -8px;
}
.big-tab .insights.active {
  background-color: #099fff;
  color: #ffffff;
}
.big-tab .insights.active .insights-title h3 {
  color: #ffffff;
}
/* .big-tab .insights.active:before {
	  top: 100%;
	  left: 50%;
	  border: solid transparent;
	  content: " ";
	  height: 0;
	  width: 0;
	  position: absolute;
	  pointer-events: none;
	  border-color: rgba(255, 255, 255, 0);
	  border-top-color: #099fff;
	  border-width: 12px;
	  margin-left: -12px;
  }
  */
/* .big-tab .insights.active .insights-img {
	  background-color: #ffffff;
  }
  */
.big-tab ul {
  padding: 0;
}
/* .big-tab li {
	  background: #fff;
	  padding: 10px;
	  border-radius: 10px;
  }
  */
.big-tab .insights:after {
  display: none;
}
.big-tab .insights-header {
  padding: 0;
  /* position: relative;
	  */
}
/* .big-tab .insights-header:after {
	  content: ">";
	  position: absolute;
	  top: 50%;
	  right: 0;
	  font-size: 20px;
	  color: rgba(0,0,0,0.5);
	  margin-top: -16px;
  }
  .big-tab .insights.active .insights-header:after, .big-tab .insights:hover .insights-header:after {
	  color: #ffffff;
  }
  */
/* .big-tab .insights-img {
	  width: 50px;
	  height: 50px;
	  padding: 8px 6px;
  }
  */
/* .insights-img img {
	  max-width: inherit;
  }
  */
/* .big-tab .insights-title {
	  margin: 5px 0px 0px 10px;
  }
  .big-tab .insights-title h3 {
	  margin: 0;
	  color: #333;
	  font-size: 18px;
  }
  .big-tab .insights-title p {
	  margin: 0;
	  font-weight: 500;
  }
  */
.big-tab .insights-title h3 {
  margin-bottom: 4px;
}
.big-tab .insights {
  /* margin: 15px 0;
	  */
  position: relative;
  cursor: pointer;
}
.big-tab-content {
  /* background-color: #fff;
	  border-radius: 10px;
	  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
	  padding: 15px;
	  */
  margin: 0 0 50px;
}
.insight-graph h4 {
  font-size: 14px;
  margin-bottom: 10px;
}
.insight-graph h4 + p {
  font-size: 15px;
  margin-bottom: 15px;
  font-family: graphik-bold;
}
.insight-graph {
  text-align: center;
}
.insight-graph-list img {
  max-width: 132px;
}
img.uploadImg {
  max-width: 100%;
}
.full-graph .insight-graph {
  min-height: 320px;
  margin-bottom: 30px;
}
.circle-graph {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 2px solid #000;
  display: inline-block;
}
span.graph-icon {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 3px;
  margin-right: 2px;
}
span.graph-icon.red-clr {
  background: #1b92ed;
}
span.graph-icon.green-clr {
  background: #1dbdff;
}
span.graph-icon.grey-clr {
  background: #555555;
}
span.graph-icon.blue-clr {
  background: #054dff;
}
span.graph-icon.no-clr {
  background: rgb(216, 216, 216);
}
.nodata {
  text-align: center;
}
.insight-graph-spot ul {
  margin: 0;
}
.insight-graph-spot li {
  display: inline-block;
  margin: 16px;
  font-size: 13px;
  color: #515c65;
}
.insight-graph-spot {
  text-align: center;
  margin-top: 10px;
}
.insight-table .table-responsive {
  margin-bottom: 20px;
}
.insight-table {
  border-top: 2px solid #e5e7e9;
  padding: 50px 20px 20px;
  position: relative;
}
.insight-table:after,
.insight-table:before {
  content: "";
  background: #e5e7e9;
  height: 2px;
  width: 15px;
  position: absolute;
  top: -2px;
  left: -15px;
}
.insight-table:before {
  right: -15px;
  left: auto;
}
.insight-table + .insight-table {
  border: 0 none;
  padding-top: 0;
}
.insight-table + .insight-table:after,
.insight-table + .insight-table:before {
  display: none;
}
.insight-table h3 {
  margin: 0 0 15px;
  font-size: 18px;
  font-weight: 500;
}
.insight-table th span,
.insight-table td span.small-font {
  font-size: 11px;
  opacity: 0.8;
}
.insight-table .insight-table .table-bordered > thead > tr > td,
.insight-table .insight-table .table-bordered > thead > tr > th {
  border-bottom-width: 0;
  border-color: #a5b0bb;
}
.insight-table .table-bordered > tbody > tr > td,
.insight-table .table-bordered > tbody > tr > th,
.insight-table .table-bordered > tfoot > tr > td,
.insight-table .table-bordered > tfoot > tr > th,
.insight-table .table-bordered > thead > tr > td,
.insight-table .table-bordered > thead > tr > th {
  border-color: #a5b0bb;
}
.insight-table .table-bordered {
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
}
.insight-table .table-bordered.table > thead:first-child > tr:first-child > th {
  border-top: 1px solid #a5b0bb;
}
.insight-table .table-bordered.table > thead > tr > th {
  border-bottom: 0 none;
}
.insight-table .table > thead > tr > th {
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: nowrap;
}
.row-span span {
  display: block;
}
.insight-table th {
  font-weight: 500;
}
.insight-table td p {
  margin-top: 5px;
}
.status-check {
  font-weight: 700;
}
.status-check.success {
  color: #65ba07;
}
.status-check.warning {
  color: #c28829;
}
.round-back {
  display: inline-block;
  width: 31px;
  height: 31px;
  margin-right: 8px;
  background: transparent url(../Images/back.png) no-repeat scroll 0 0;
  vertical-align: text-bottom;
  cursor: pointer;
}
.radius-tools {
  margin-top: 5px;
}
.radius-tools span {
  display: inline-block;
  border: 1px solid #979797;
  color: #979797;
  border-radius: 12px;
  padding: 5px 8px 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
  margin: 3px 3px 3px 0;
  font-weight: normal;
  letter-spacing: -0.29px;
}
.radius-tools span.active {
  border-color: rgb(161, 0, 255);
  color: rgb(161, 0, 255);
}
.sidebar-cont .radius-tools span {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  border-radius: 20px;
  padding: 4px 10px 3px;
  word-break: break-all;
}
.sidebar-cont .radius-tools span:empty {
  display: none;
}
span.num-tools {
  position: absolute;
  border-radius: 50%;
  background-color: rgb(161, 0, 255);
  color: #ffffff;
  text-align: center;
  z-index: 11;
  font-family: graphik-medium;
  width: 24px;
  height: 24px;
  padding: 4px 0;
  font-size: 12px;
  left: -12px;
  top: -12px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
}
.timeline-content:hover span.num-tools {
  background: #ffffff;
  color: #008eff;
}
.timeline-box.active .timeline-content span.num-tools {
  background: #ffffff;
  color: #008eff;
}
.timeline-content .rainbow-tools {
  position: absolute;
  bottom: 12px;
  left: 12px;
}
.rainbow-tools span {
  display: inline-block;
  width: 24px;
  height: 24px;
  text-align: center;
  background: #ffffff;
  color: rgb(161, 0, 255);
  border: 1px solid rgb(161, 0, 255);
  border-radius: 50%;
  font-size: 10px;
  padding: 5px 0 3px;
  font-family: graphik-medium;
  margin: 4px;
  letter-spacing: -0.25px;
  line-height: 14px;
}
.timeline-box.active .timeline-content .rainbow-tools span {
  border: 1px solid #008eff;
  color: #008eff;
}
.rainbow-tools span.disable {
  opacity: 0.5;
}
.orch-sidebar .rainbow-tools span {
  margin-right: 5px;
  /* vertical-align: top;
	  */
}
.orch-sidebar .rainbow-tools {
  padding: 10px 15px;
}
.rainbow-tools.rainbow-btm {
  margin: 50px 36px 24px;
}
.rainbow-tools td p,
.rainbow-tools p {
  font-size: 12px;
  color: #637381;
  letter-spacing: -0.25px;
  /* display: inline-block;
	  margin: 0 36px 0 0; */
  margin-bottom: 2px;
}
/* span.orange {
	  background: orange;
  }
  span.pink {
	  background: deeppink;
  }
  span.green {
	  background: limegreen;
  }
  span.rose {
	  background: pink;
  }
  */
/* bipin changes starts*/
.outcome-divider.null-clr:before {
  background: #ffffff;
}
.outcome-divider.viewmore {
  text-align: center !important;
  padding-top: 20px !important;
  border-bottom: 0px !important;
}
.off {
  display: none;
}
.on {
  display: block;
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.navbar-default.navbar-fixed-top {
  z-index: 1060;
}
.menu-disable {
  pointer-events: none;
  background-color: #e5e7e9;
}
.outcome-row.singleFlex {
  padding: 12px 0;
}
.outcome-row.singleFlex .outcome-divider {
  padding: 10px 10px 10px 24px;
  width: 100%;
}
/* For GOC: Start */
.additional-info {
  font-size: 16px !important;
}
.goc-chart-container {
  height: 630px;
  overflow-y: scroll;
}
.center-align {
  text-align: center;
}
.gocpage .title-card {
  float: none;
  margin-top: 25px;
}
.gocpage .breadcrumb-title .dropdown {
  margin-top: 5px;
}
.gocpage .title-card h2 {
  font-size: 24px;
}
.gocpage.main-container {
  margin: 0;
}
.gocpage.main-component {
  padding: 0;
}
.main-container.gocpage + .main-container {
  display: none;
}
.title-card h2 {
  font-family: graphik-black;
  color: #4f555b;
  margin: 0 0 5px;
}
/* For GOC: End */
/* addedd by devika for quick links starts */
.quickLink {
  margin: 30px 0;
}
.quickLink ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.quicklinks_grid img {
  margin-right: 8px;
}
.outcome-highlight.quicklinks_grid {
  padding: 20px 20px 10px;
  margin: 25px 0;
}
.quicklinks_grid h2 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #4f555b;
  font-family: graphik-semibold;
  letter-spacing: -0.32px;
}
.quicklinks_grid .radius-tools a {
  display: inline-block;
  margin: 10px;
}
.quicklinks_grid .radius-tools span {
  border-color: #008eff;
  color: #008eff;
  font-size: 16px;
  font-family: graphik-medium;
  text-transform: none;
  padding: 5px 18px;
  border-radius: 36px;
  margin: 0;
  letter-spacing: -0.29px;
}
.quicklinks_grid .radius-tools {
  margin: 0 -10px 10px;
}
/* addedd by devika for quick links ends */
.PermDenie {
  margin: 15%;
  font-size: xx-large;
  text-align: center;
}
/*GOC Start*/
.outcome-table48 .outcome-divider {
  width: auto;
}
.outcome-list.border-rt {
  border-right: 2px solid #b1c0cc;
}
.outcome-table48 .outcome-list .outcome-table {
  margin: 15px 0 25px;
}
.outcome-table48 .insight-graph h4 {
  font-size: 20px;
  margin-bottom: 15px;
  font-family: graphik-semibold;
  letter-spacing: -0.35px;
}
.graph-highlight .insight-graph {
  margin-bottom: 20px;
}
.graph-highlight .insight-graph h4 {
  font-size: 18px;
}
.outcome-list.border-bt.mar-pad {
  margin-bottom: 21px;
  padding-bottom: 25px;
  border-bottom: 1px solid #e5e7e9;
  position: relative;
}
.outcome-list.border-bt:after,
.outcome-list.border-bt:before {
  content: "";
  background: #e5e7e9;
  height: 1.5px;
  position: absolute;
  left: -15px;
  width: 15px;
  bottom: -1px;
}
.outcome-list.border-bt:after {
  left: auto;
  right: -20px;
}
.outcome-sq-br .img-title {
  margin-top: 10px;
  margin-bottom: 24px;
  font-size: 18px;
  color: #4f555b;
}
.img-title img {
  width: 30px;
  margin-right: 5px;
  vertical-align: middle;
  display: inline-block;
}
.outcome-list.sq-right-br,
.outcome-list.sq-top-right-br,
.outcome-list.sq-bottom-left-br,
.outcome-list.sq-top-left-br {
  position: relative;
}
.outcome-list.sq-right-br:after,
.outcome-list.sq-top-right-br:after,
.outcome-list.sq-bottom-left-br:after,
.outcome-list.sq-top-left-br:after {
  content: "";
  background: #b1c0cc;
  height: 2px;
  position: absolute;
  right: 0px;
  width: 250px;
  bottom: -40px;
  z-index: 10;
}
.outcome-list.sq-right-br:before {
  content: "";
  position: absolute;
  right: -55px;
  width: 110px;
  bottom: -95px;
  height: 109px;
  background: transparent url(../Images/OC_img.png) no-repeat scroll 0 0;
  z-index: 20;
}
.outcome-list.sq-top-right-br:after {
  top: 20px;
  bottom: auto;
  right: 0;
  height: 245px;
  width: 2px;
}
.outcome-list.sq-bottom-left-br:after {
  top: auto;
  bottom: -42px;
  left: -31px;
  right: auto;
  height: 245px;
  width: 2px;
}
.outcome-list.sq-bottom-left-br:before {
  content: "";
  background: #b1c0cc;
  height: 2px;
  position: absolute;
  left: -120px;
  width: 180px;
  top: -23px;
  z-index: 10;
}
.outcome-list.sq-top-left-br:after {
  right: auto;
  width: 250px;
  top: 21px;
  bottom: auto;
  left: -30px;
}
.outcome-list.sq-top-right-br:before {
  content: "";
  background: #b1c0cc;
  height: 2px;
  position: absolute;
  right: -90px;
  width: 180px;
  bottom: -12px;
  z-index: 10;
}
.outcome-goc-graph h2 {
  margin-bottom: 15px;
}
.outcome-goc-graph .outcome-graph {
  margin-bottom: 10px;
  margin-top: 0;
}
.outcome-goc-graph p {
  text-align: center;
  margin: 10px 0 0;
  font-size: 12px;
  color: #777;
}
.goc-image-title {
  font-weight: bold;
}
.hlt-blue-bg {
  padding: 25px 35px;
  background-color: #f0fbff;
  border: 1px solid #01bafd;
  border-radius: 5px;
  margin: 12px 0 20px;
}
.outcome-highlight.outcome-sq-br {
  padding: 18px 22px 10px;
}
.outcome-highlight.outcome-sq-br h2 {
  font-size: 22px;
  letter-spacing: -0.32px;
  font-family: graphik-semibold;
}
.outcome-small.outcome-highlight h2.img-title {
  font-size: 18px;
  color: #4f555b;
}
.outcome-highlight.outcome-table48 {
  margin-top: 0;
}
.outcome-highlight.outcome-table48 .outcome-list h2 {
  font-size: 18px;
  font-family: graphik-semibold;
  letter-spacing: -0.39px;
  color: #4f555b;
}
.outcome-highlight.outcome-table48 h2 {
  font-size: 22px;
  letter-spacing: -0.35px;
  color: #000000;
}
/* GOC END*/
.file-upload {
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  background: #fff;
  margin: 25px auto;
  border-radius: 20px;
  width: 540px;
}
.file-upload label {
  /* text-align: center;
	  */
  display: block;
  font-size: 20px;
  font-family: graphik;
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
}
.file-upload p.help-block {
  margin: 15px 0 5px;
}
.file-upload p.help-block.error {
  color: #ff0000;
}
.file-upload p.help-block.success {
  color: #008000;
}
/* Tooltip starts */
.tipcolor {
  background-color: #1c262f !important;
  color: #fff !important;
}
.tipcolor:after {
  border-top-color: #1c262f !important;
}
/* .tipcolor::before {
	  border-style: solid;
	  border-width: 1em 0.75em 0 0.75em;
	  border-color: #3E474F transparent transparent transparent;
	  bottom: 100%;
	  content: "";
	  margin-left: -0.5em;
	  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
	  transform: scale(.6) translateY(-90%);
  }
  */
.help-icon .fa-question-circle {
  /* margin: 10px 0;
	  */
  color: #018eff;
  font-size: 20px;
}
.stage-heading {
  font-size: 20px;
  font-family: graphik-medium;
  margin: 0 0 24px;
  letter-spacing: -0.25px;
  color: #212b36;
  margin-bottom: 0.75rem;
}
/*Disabling Client selection-on deal profile pop up*/
.modal-open .login-img select {
  pointer-events: none;
  opacity: 0.6;
  background: #eee;
}
/* .outcome-tab li .blu-hlt.tab-link {
	  background: #00baff;
	  color: #fff;
	 /* border-color: #00baff;
  }
  .outcome-tab li:first-child .blu-hlt.tab-link .bu-title-text h3, .outcome-tab li:first-child .blu-hlt.tab-link .bu-title-text p {
	  color: #ffffff;
  }
  */
.humansave {
  height: 34px;
  margin: 0 auto;
  margin-right: 512px;
  float: right;
  color: white;
  background-color: blue;
}
.humansubmit {
  height: 34px;
  margin: 0 auto;
  margin-left: 512px;
  float: left;
  color: white;
  background-color: blue;
}
.text-line {
  background-color: transparent;
  outline: none;
  outline-style: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid #eeeeee 1px;
  z-index: 1;
}
.delete {
  position: absolute;
  text-align: center;
  z-index: 11;
  font-family: graphik-semibold;
  font-size: 15px;
  width: 25px;
  height: 25px;
  padding: 4px 0;
  left: 10px;
  top: 0;
  cursor: pointer;
}
.edit {
  position: absolute;
  text-align: center;
  z-index: 11;
  font-family: graphik-semibold;
  font-size: 15px;
  width: 25px;
  height: 25px;
  padding: 4px 0;
  left: 30px;
  top: 0;
  cursor: pointer;
}
.metric-icon {
  /*position: relative;
	  font-family: "graphik-semibold";
	  font-size: 15px;
	  width: 25px;
	  height: 25px;
	  bottom: 3px;
	  color: #00baff;
	  cursor: pointer;
	  margin-left: 10px;
	  float: right;
	  margin-top: 5px;
	  */
  cursor: pointer;
  right: -5px;
  position: absolute;
  bottom: 0;
}
.metric-icon svg {
  display: block;
}
.BO-delete {
  right: -16px !important;
}
.deletehumanmachine {
  position: absolute;
  text-align: center;
  z-index: 11;
  font-family: graphik-semibold;
  font-size: 15px;
  width: 25px;
  height: 25px;
  right: 7px;
  bottom: 0;
  cursor: pointer;
  padding: 0 10px 0 0;
}
.deletewokpi {
  position: absolute;
  text-align: center;
  z-index: 11;
  font-family: graphik-semibold;
  font-size: 15px;
  width: 25px;
  height: 25px;
  left: 200px;
  bottom: 0;
  cursor: pointer;
}
.deletefoundation {
  font-size: 3px;
  cursor: pointer;
  font-size: 8px !important;
  margin-left: 3px !important;
}
.errorMsg {
  color: #721c24;
  letter-spacing: -0.25px;
  font-size: 14px;
  text-align: center;
  background: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  margin: 8px 0;
  display: block;
}
.form-control + .errorMsg {
  margin-bottom: 0;
}
/* .errorMsg {
	  color: #721c24;
	  margin-bottom: 0;
	  letter-spacing: -.25px;
	  font-size: 13px;
	  text-align: center;
	  background: #f8d7da;
	  border: 1px solid #f5c6cb;
	  padding: .4rem .75rem .3rem;
	  border-radius: 4px;
	  margin: 8px 0;
	  display: block;
  } */
.errorMsg:empty {
  display: none;
}
.required {
  color: #dc3545;
}
.ioj {
  margin-top: -90px;
  margin-left: 657px;
}
/* .border-box {
	  border: 1px solid #ccc;
	  margin-top: 12px;
	  padding: 8px 12px;
  } */
.p-4 {
  padding: 15px;
}
.maturity-add {
  padding: 15px 0;
}
/* chat bot */
.rcw-widget-container {
  bottom: 40px !important;
}
.rcw-avatar {
  width: 35px !important;
  height: 35px !important;
}
.rcw-client,
.rcw-response {
  padding: 10px !important;
}
.rcw-conversation-container .rcw-header {
  padding: 0 12px !important;
  text-align: left !important;
  background: #00baff !important;
}
.rcw-launcher {
  background: #00baff !important;
}
.rcw-message-text p {
  font-family: "graphik";
}
.rcw-conversation-container .rcw-title {
  font-size: 20px !important;
  font-family: "graphik-medium";
}
/* Report tabset */
.nav-pills {
  background: #fff;
  border-radius: 4px;
}
/* .nav-item {
	  border-bottom: 1px solid rgba(219,230,236,0.5) 
  }
  */
.nav-tabs > li > a {
  margin-bottom: -2px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-left: 4px solid #3b577d;
  background-color: #eeeeee !important;
  color: #222222 !important;
}
.nav-pills > li {
  float: none;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff !important;
  background: #3b577d !important;
}
.nav-pills .nav-link {
  border-radius: 0 !important;
  padding: 1rem;
  color: #3b577d;
  font-weight: 500;
}
.tab-content {
  background: #fff;
  padding: 25px;
  border-radius: 4px;
  border: 0 !important;
  padding: 0;
}
.padding-40 {
  padding-top: 40px;
}
.note {
  margin-left: 16px;
  font-size: 13px;
}
p.note {
  margin-bottom: 0;
}
/* landing page */
.no-gutters {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.display-3 {
  font-size: 2.5rem !important;
  font-weight: 500;
  line-height: 1.2;
}
.display-4 {
  font-size: 1.5rem !important;
  font-weight: 500;
  line-height: 1.2;
}
.btn-doc {
  background: #1d2b36;
  color: #ffffff;
  margin-left: 10px;
}
.lead {
  font-size: 1rem;
  font-weight: 300;
}
.button-section {
  margin-top: 25px;
}
.d-block {
  display: block;
  padding: 5px 0;
}
.highlight-heading {
  color: #222222;
}
.detail {
  color: #018fff;
}
.detail:hover {
  text-decoration: underline;
}
.leadership-section {
  background: #00baff;
}
.leadership-section h2 {
  color: #fff;
  padding-top: 20px;
}
.shadow-light-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.05) !important;
}
.bg-gray-200 {
  background: #f1f4f8 !important;
}
.highlight-section {
  background: #fff;
  padding-left: 15px;
  padding-right: 15px;
}
/* .upper-div {
	  z-index: 1;
  }
  */
.red {
  color: #ff0000;
}
.shadow-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1) !important;
}
.rounded-lg {
  border-radius: 0.5rem !important;
}
.left-side {
  float: left;
  width: 50%;
}
.top-header {
  font-size: 24px;
  font-weight: 500;
  color: #018eff;
}
.contacts img {
  border: 4px solid #f5f5f5;
}
.contacts span {
  line-height: 1.5;
  display: block;
}
.section-heading {
  padding: 15px;
}
.meassge {
  /* position: absolute;
	  top: 50%;
	  right: 50%;
	  transform: translate(30%, 15%);
	  */
  padding: 35px;
}
.Name {
  display: block;
  font-size: 14px;
  margin-top: 10px;
}
.designation {
  font-size: 11px;
}
.mail-address {
  font-size: 11px;
  text-decoration: underline;
}
.owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  cursor: pointer;
  user-select: none;
}
.owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background: #dfe3e8;
  display: block;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}
.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span {
  background: #637381;
}
label.form-check {
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 1.1rem;
  margin-bottom: 0;
  display: inline-flex;
  margin-left: 3px;
}
.form-check input[type="checkbox"],
.form-check input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.form-check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  border-radius: 3px;
}
.form-check input[type="radio"] + .checkmark {
  border-radius: 50%;
}
.checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: 1px solid #ffffff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  position: absolute;
  display: none;
}
input[type="checkbox"].form-check-input:checked ~ .checkmark {
  background-color: #018eff;
  border-color: #018eff;
}
input[type="radio"].form-check-input:checked ~ .checkmark {
  background-color: white;
  border-color: #018eff;
}
.form-check-input:checked ~ .checkmark:after {
  display: block;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input:disabled ~ .checkmark {
  opacity: 0.7;
}
.form-check input[type="radio"] + .checkmark:after {
  border: 1px solid #018eff;
  background: #018eff;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  transform: initial;
  left: 50%;
  margin-left: -4px;
  top: 50%;
  margin-top: -4px;
}
.form-check-label {
  vertical-align: middle;
  font-size: 14px;
  position: relative;
  margin-left: 4px;
  bottom: 2px;
}
.ag-cell label.form-check {
  display: inline-block;
  vertical-align: text-top;
  margin-top: 2px;
}
/* .ag-theme-blue.add-user-list .ag-cell{
	  top:10px;
  }
  .form-check-label{
	  vertical-align: middle;
	  font-size: 14px;
	  position: relative;
	  margin-left: 4px;
	  bottom: 2px;
  }
  .form-check-label-active{
	  vertical-align: middle;
	  font-size: 14px;
	  position: relative;
	  margin-left: 10px;
	  bottom: 2px;
	  top:-8px;
  } */
/* Create a custom checkbox
  .checkmark, .ag-theme-blue input[type="checkbox"] + span{
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 16px;
	  width: 16px;
	  border: 1px solid #018eff;
	  background-color: white;
	  border-radius: 3px;
  }
  /* Create the checkmark/indicator (hidden when not checked)
  .checkmark::after ,.ag-theme-blue input[type="checkbox"] + span::after{
	  content: "";
	  position: absolute;
	  display: none;
  }
  /* Style the checkmark/indicator
  .checkmark:after, .ag-theme-blue input[type="checkbox"] + span::after {
	  left: 4px;
	  top: 1px;
	  width: 5px;
	  height: 10px;
	  border: 1px solid white;
	  border-width: 0 2px 2px 0;
	  -webkit-transform: rotate(45deg);
	  -ms-transform: rotate(45deg);
	  transform: rotate(45deg);
  }
  /* When the checkbox is checked, add a background
  .form-check-input:checked ~ .checkmark, .ag-theme-blue input[type="checkbox"]:checked + span {
	  background-color: #018eff;
	  border: 1px solid #018eff;
  }
  /* Show the checkmark when checked
  .form-check-input:checked ~ .checkmark:after, .ag-theme-blue input[type="checkbox"]:checked + span:after {
	  display: block;
  } */
.ag-theme-blue .ag-cell-focus {
  border: 0 !important;
}
.react-checkbox-tree {
  display: flex;
  flex-direction: row-reverse;
  font-size: 16px;
  height: 300px;
  overflow: auto;
  border: 1px solid #ced4da;
  margin-top: 3px;
  padding: 10px 0 0;
}
.react-checkbox-tree::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.react-checkbox-tree::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.react-checkbox-tree::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #888;
}
/* MetricGraph */
.metric-description {
  font-size: 12px;
  margin-top: 7px;
  margin-bottom: 0px;
}
/* .metric-details {
	  display: block !important;
  }
  */
.parent-metric-details {
  position: relative;
  top: 0;
  /* text-align: right; */
}
.insightsNew .parent-metric-details {
  position: inherit;
  display: flex;
  justify-content: space-between;
}
.lag-sim {
  /* margin-top: 10px; */
  height: 32px;
}
.insightsNew .lag-sim {
  flex-direction: column;
  height: auto;
}
.lag-sim .metric-digit {
  margin-right: 12px;
}
.insightsNew .lag-sim .metric-digit {
  margin-right: 0;
}
.bell,
.more {
  margin-right: 8px;
  width: 24px !important;
  height: 24px;
}
.fa-chart-line,
.fa-bars {
  color: #00baff;
  margin-top: 3px;
  font-size: 18px;
}
.fa-bars {
  margin-right: 20px;
}
.round-div {
  width: 17px;
  height: 17px;
  background: #ff0000;
  color: #fff;
  padding: 1px 4px;
  border-radius: 50%;
  position: absolute;
  bottom: 13px;
  right: 0;
}
.png-icons {
  position: relative;
  top: -4px;
}
/* .notification-count {
	  background: #ff0000;
	  padding:10px;
	  border-radius: 50%;
	  color: #ffffff;
  }
  */
.fa-arrow-down {
  color: #fc0000;
  margin-left: 6px;
  font-size: 20px;
}
.fa-arrow-up {
  color: #008000;
  margin-left: 6px;
  font-size: 20px;
}
/* .outcome-divider span {
	  font-size: 12px;
	  font-family: graphik;
	  color: #4f555b !important;
	  display: inline-block;
	  letter-spacing: -0.25px;
	  word-break: break-word;
  }
  */
/* right side modal */
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 400px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}
.modal.right .modal-body {
  padding: 15px 15px 80px;
}
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.right.fade.in .modal-dialog {
  right: 0;
}
/* .modal-content {
	  border-radius: 0;
	  border: none;
  }
  */
.layout-modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
  margin-top: 80px;
}
.mr-10 {
  margin-right: 10px;
}
.item-highlighted {
  color: white;
  background-color: #4095bf;
}
.select-data .item {
  padding: 3px 5px;
  font-size: 13px;
}
.row-section label {
  min-height: 40px;
}
/* fix for IE10+ */
.navbar-fixed-top {
  position: fixed;
  padding: 0px;
}
.dashboard-selector-header {
  position: relative;
  display: inline-block;
}
.dashboard-selector-profile-icon {
  /*position: absolute;
	  right:-175%;
	  top:0px;
	  */
  height: 30px;
  width: 30px;
}
.profile-large-icon {
  margin: 20px 0 0 10px;
}
/* .dashboard-selector-profile-icon img{
	  width:55px !important;
	  height:55px !important;
  }
  */
.dashboard-selector-header i {
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: bold;
}
.dashboard-selector-header > .select-data > div {
  width: 180px;
}
.dashboard-selector-header > .select-data > div > input {
  height: 30px;
}
.custom-width-100-per {
  width: 100%;
}
.custom-fontsize-23px {
  font-size: 20px !important;
}
/* .custom-padding-top-9per{
	  padding-top:9%;
  }
  */
/* .form-group.col-lg-3 label{
	  min-height: 40px;
	  font-weight: bold;
	  font-size: 14px;
  } */
.nav > li > a {
  padding: 10px 15px;
  color: #018eff !important;
  text-decoration: none;
}
.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.4;
  border-radius: 4px 4px 0 0;
}
.nav > li,
.nav > li > a {
  position: relative;
  display: block;
}
label {
  /* font-family: graphik-semibold; */
  font-weight: bold;
  font-size: 13px;
}
.btn-primary {
  background-color: #018eff;
  border-color: #018eff;
  color: #fff;
  font-family: Graphik-Medium;
  font-size: 14px;
  box-shadow: none;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: rgb(51, 164, 255);
  border-color: rgb(51, 164, 255);
  box-shadow: none;
  outline: none;
  color: #fff;
}
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
  background: rgb(1, 142, 255);
  opacity: 0.3;
}
/* h3{
	  font-size: 22px !important;
  }
  */
.pull-right {
  float: right !important;
}
/* p{
	  font-size: 13px !important;
  }
  */
/* a{
	  color: #018eff !important;
  }
  */
/* .modal-backdrop.fade{
	  opacity: 0.5;
  } */
:not(.show).fade {
  opacity: inherit;
}
.modal-header {
  padding: 32px 32px 8px;
  /* border-bottom: 1px solid #e5e5e5; */
  border: 0 none;
  position: relative;
  justify-content: normal;
}
/* .modal-header > button{
	  margin-top: 0px !important;
  } */
.form-control,
.input-group-text {
  /* height: 34px !important;
	  line-height: 1; */
  font-size: 0.85rem;
}
.breadcrumb-title .dropdown-menu > li {
  /* font-size: 13px;
	  */
  /* white-space: normal;
	  */
  padding: 5px 30px;
  border-bottom: 1px solid #ccc;
}
/* .source-system-padding{
	  margin-bottom: 17px !important;
  } */
.select-data > .row > .col-lg-3 > label {
  font-size: 13px;
}
.select-data > .row > .col-lg-3 > div > select {
  font-size: 11px;
}
/* .row{
	  margin-left: 0px;
	  margin-right:0px;
  }
  */
.goc-profile-image {
  padding: 0px !important;
}
.navbar-right {
  float: right;
}
ul.nav.navbar-nav.navbar-right > li a {
  font-size: 14px;
}
/* input{
	  font-size: 13px !important;
  }
  select , option{
	  font-size: 12px !important;
  }
  */
.wotile-checkbox {
  position: relative;
}
.wotile-checkbox-label {
  padding-left: 20px;
  padding-right: 10px;
}
.d-datacheck-label {
  vertical-align: middle;
  font-size: 15px;
  position: relative;
  margin-left: 10px;
  bottom: 9px;
}
/* .source-system-padding-d-data{
	  margin-bottom: 27px !important;
  } */
select.form-control[multiple] {
  height: 70px !important;
}
.divers-data-popup-input {
  border: 2px solid #c3c3c3;
  border-radius: 5px;
  max-width: 150px;
  height: 30px;
}
/* .react-datepicker-popper{
	  width: 100% 
  } */
.createDataType {
  float: right;
  margin-right: 10px;
  font-size: 13px;
}
.dataTypeDelete,
.datTypeUnit {
  display: inline-flex;
  padding-left: 10px;
}
.eachDatatype {
  margin-bottom: 10px;
}
.clientRegionPadding {
  margin-right: 10px;
}
.margintb {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #c3c3c3;
}
/* #diverse-data-blue > span{
	  color: #018fff !important;
  }
  */
.similar-processed-graph-filter {
  border: 2px solid #c3c3c3;
  border-radius: 5px;
  max-width: 250px;
  height: 30px;
  display: block;
  margin-bottom: 5px;
}
.textAreaHeight {
  height: 100px !important;
}
/* .ioj-popup-margin{
	  margin-bottom: 20px !important;
  } */
.ioj-popup-width {
  width: 50% !important;
}
#ImpactStartDate,
#ImpactPeakDate {
  border: 1px solid #c3c3c3;
  padding: 5px;
  border-radius: 5px;
}
.ioj-filter {
  padding: 8px 10px;
  border-radius: 7px;
  width: 80%;
  margin: 2px 1% 10px 5%;
  border-color: #888888;
  background: #ecf3f9;
  color: #000;
  font-size: 12px;
  display: block;
  font-weight: 700;
}
.CMA-select {
  padding: 8px 10px;
  border-radius: 7px;
  width: 80%;
  border-color: #888888;
  background: #ecf3f9;
  color: #000;
  font-size: 12px;
  display: block;
  font-weight: 700;
}
.ioj-input {
  padding: 4px 8px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  width: 30%;
  display: inline-block;
  border: 1px solid #888888;
  margin-right: 10px;
}
.CMA-input {
  padding: 4px 8px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  display: inline-block;
  border: 1px solid #888888;
  margin-right: 10px;
}
.ioj-display {
  display: block;
}
.ag-bt1-radius {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ag-bt2-radius {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.ag-bt-inactive {
  color: black;
  background-color: #ecf3f9;
  border-color: #888888;
}
.ai-margin {
  margin: 20px;
}
/* #period ~ .react-datepicker-wrapper, #Base_Month ~ .react-datepicker-wrapper {
	  border-radius: 7px;
	  -webkit-border-radius: 7px;
	  -moz-border-radius: 7px;
	  width: 50%;
	  display: inline-block;
  } */
#period ~ .react-datepicker-wrapper {
  width: 48%;
  margin-left: 4%;
}
#period + .react-datepicker-wrapper {
  margin-left: 0;
}
.block-picker .react-datepicker-wrapper {
  display: block;
}
.react-datepicker-popper {
  z-index: 10001;
}
.fa-info-circle,
.tbledit {
  color: #018eff;
}
.fa-plus {
  color: rgb(161, 0, 255);
}
.list-icon .fa-trash-alt,
.tbldel {
  color: #d5002f;
}
.list-icon .fa-pencil-alt {
  color: #333333;
}
.cmAnalysis > .fa-plus {
  font-size: 20px !important;
}
.m-t-5 {
  margin-top: 5px;
}
.p-l-25 {
  padding-left: 25px;
}
.m-l-7 {
  margin-left: 7px;
}
.btn1 {
  background: #008cba;
  /* background: #555555; */
  /* border: none; */
  color: white;
  font-size: 22px;
  font-size: 16px;
  /* float: right; */
  display: block;
}
.client-card .card {
  text-align: center;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  border: 0 none;
  padding: 4px;
  width: 17%;
  margin: 1.5%;
  cursor: pointer;
}
.client-card .card:hover {
  box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.16);
}
.client-card .card a {
  display: block;
}
.client-card .card-title {
  font-family: graphik-medium;
  font-size: 14px;
  color: #212b36;
  display: block;
  margin-bottom: 0;
}
.navbar-nav.search-nav {
  flex-direction: inherit;
  flex-wrap: wrap;
  /* justify-content: flex-end; */
}
.search-nav .nav-item {
  border-bottom: 0;
  margin-right: 40px;
  cursor: pointer;
}
.tab-list .search-nav .nav-item {
  margin-bottom: 8px;
  position: relative;
}
.tab-list .search-nav .nav-item:after {
  content: "";
  background: #dfe3e8;
  height: 24px;
  width: 2px;
  position: absolute;
  right: -21px;
  top: 6px;
}
/* .tab-list .search-nav .nav-item:last-child:after {
	  display: none;
  } */
.tab-list .search-nav .nav-item:last-child {
  margin-right: 0;
}
.search-nav a.nav-link {
  color: #919eab !important;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-family: graphik-medium;
  position: relative;
  padding-bottom: 10px;
  flex-direction: column;
  display: flex;
  align-items: center;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.search-nav a.nav-link:not(.dropdown-toggle):hover,
.search-nav .active a.nav-link {
  color: #018eff !important;
}
.search-nav .nav-item a.nav-link:not(.dropdown-toggle):after,
.search-nav .nav-item a.nav-link.active:not(.dropdown-toggle):after {
  content: "";
  background: #018eff;
  width: 0;
  height: 5px;
  border-radius: 3px;
  position: absolute;
  bottom: 0;
}
.search-nav .nav-item a.nav-link:not(.dropdown-toggle):hover:after {
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  width: 100%;
}
.search-nav .nav-item.active a.nav-link:not(.dropdown-toggle):after,
.search-nav .nav-item a.nav-link.active:not(.dropdown-toggle):after {
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  width: 100%;
}
.navbar .select-data > div {
  position: relative;
  padding-right: 20px;
  max-width: 103px;
}
.navbar .select-data > div:after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  right: 8px;
  top: 5px;
}
.navbar {
  padding: 1.1845rem 1.5em;
  -webkit-box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.1), 0 1px 0 0 #f4f6f8;
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.1), 0 1px 0 0 #f4f6f8;
}
.navbar.search-nav {
  box-shadow: none;
}
.nav-item i {
  color: #919eab;
  font-size: 24px;
}
.navbar-nav.ml-auto .nav-link {
  padding: 0 0.5rem !important;
}
/* .notify-item {
	  display: none;
  } */
a.nav-link .icon-notify {
  position: relative;
}
a.nav-link .icon-notify:after {
  content: "";
  position: absolute;
  bottom: -14px;
  left: 50%;
  margin-left: -3px;
  background-color: #018fff;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.navbar-nav.ml-auto li.nav-item {
  margin-left: 8px;
}
.navbar-nav.ml-auto li.nav-item:last-child a.nav-link {
  padding-right: 0;
}
.landing-header.navbar {
  padding-bottom: 0.492835rem;
  padding-top: 0.49284rem;
}
.landing-header.navbar .navbar-brand {
  padding: 4.5px 0;
}
.select-snap .dropdown-toggle::after {
  content: "\f141";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  border: 0 none;
  vertical-align: bottom;
  margin-left: 16px;
}
#snap.dropdown-toggle {
  font-family: graphik-medium;
  font-size: 16px;
  letter-spacing: -0.25px;
  color: #212b36;
}
#snap.dropdown-toggle i {
  font-size: 20px;
  margin-right: 8px;
}
.select-snap {
  padding-right: 26px;
  border-right: 1px solid #dfe3e8;
  margin-right: 8px;
}
.dropdown-menu {
  border: 0 none;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
  padding: 8px;
}
.dropdown-item {
  font-family: graphik;
  font-size: 14px;
  letter-spacing: -0.25px;
  color: #212b36;
  padding: 0.4rem 1rem;
  border-radius: 3px;
}
.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover {
  color: #fff;
  background-color: #018fff;
}
[class^="icon"] {
  display: inline-block;
  background-image: url("../Images/sprite.png");
}
.main-sidebar [class^="icon"] {
  height: 18px;
  width: 18px;
  vertical-align: top;
  margin-right: 4px;
}
.icon-notify,
.icon-profileImg {
  display: inline-block;
  height: 24px;
  width: 24px;
}
.icon-notify {
  background-position: -2px -2px;
}
.icon-profileImg {
  background-position: -4px -67px;
}
.icon-layers {
  height: 16px;
  width: 16px;
  background-position: -4px -38px;
  vertical-align: text-bottom;
}
.icon-target {
  background-position: -3px -155px;
}
.icon-small-user {
  background-position: -3px -106px;
}
.icon-connect {
  background-position: -3px -211px;
}
.icon-chart {
  background-position: -5px -131px;
}
.icon-crossing-arrows {
  background-position: -3px -293px;
}
.icon-hiking {
  background-position: -3px -264px;
}
.icon-chart-arrow {
  background-position: -3px -180px;
}
.icon-hand-plant {
  background-position: -5px -237px;
}
.icon-notify:hover {
  background-position: -37px -2px;
}
.icon-profileImg:hover {
  background-position: -35px -67px;
}
.corner-stable a:hover .icon-layers,
.corner-stable p:hover .icon-layers,
.corner-stable a.active .icon-layers,
.corner-stable p.active .icon-layers,
.icon-layers:hover {
  background-position: -44px -38px;
}
.icon-target:hover,
.main-sidebar li a:hover .icon-target,
.main-sidebar li a.active .icon-target {
  background-position: -42px -155px;
}
.icon-small-user:hover,
.main-sidebar li a:hover .icon-small-user,
.main-sidebar li a.active .icon-small-user {
  background-position: -42px -106px;
}
.icon-connect:hover,
.main-sidebar li a:hover .icon-connect,
.main-sidebar li a.active .icon-connect {
  background-position: -42px -211px;
}
.icon-chart:hover,
.main-sidebar li a:hover .icon-chart,
.main-sidebar li a.active .icon-chart {
  background-position: -42px -131px;
}
.icon-crossing-arrows:hover,
.main-sidebar li a:hover .icon-crossing-arrows,
.main-sidebar li a.active .icon-crossing-arrows {
  background-position: -42px -293px;
}
.icon-hiking:hover,
.main-sidebar li a:hover .icon-hiking,
.main-sidebar li a.active .icon-hiking {
  background-position: -42px -264px;
}
.icon-chart-arrow:hover,
.main-sidebar li a:hover .icon-chart-arrow,
.main-sidebar li a.active .icon-chart-arrow {
  background-position: -42px -180px;
}
.icon-hand-plant:hover,
.main-sidebar li a:hover .icon-hand-plant,
.main-sidebar li a.active .icon-hand-plant {
  background-position: -42px -238px;
}
.select-custom {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.select-custom select {
  padding-right: 1.84em;
  /* border: 0;
	  margin: 0;
	  text-overflow: '';
	  */
  text-indent: 0.01px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
}
.select-custom select::-ms-expand {
  display: none;
}
.select-custom::before,
.select-custom::after {
  content: "";
  position: absolute;
  pointer-events: none;
}
.select-custom::after {
  content: "";
  border: 1px solid transparent;
  border-bottom: 1.5px solid #919eab;
  border-right: 1.5px solid #919eab;
  height: 5px;
  width: 5px;
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  position: relative;
  top: -3px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  color: rgba(0, 0, 0, 0.6);
  right: 0.8em;
}
.select-custom::before {
  width: 2em;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
}
/* .select-custom select[disabled] {
	  color: rgba(0,0,0,.25);
  }
  */
.business-filter .dropdown {
  padding-right: 8px;
  margin-right: 8px;
}
.business-filter .dropdown:after {
  border-right: 1.5px solid #dfe3e8;
  width: 1px;
  height: 24px;
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -12px;
}
.business-filter .dropdown-toggle {
  font-family: graphik-medium;
  font-size: 14px;
  color: #212b36;
  letter-spacing: -0.25px;
  padding: 0 10px 0 0;
}
/* .business-filter .dropdown-toggle:after, .main-component .search-nav .search-alpha .nav-item */
.dropdown-toggle:after {
  content: "";
  border: 1px solid transparent;
  border-bottom: 2px solid #919eab;
  border-right: 2px solid #919eab;
  height: 8px;
  width: 8px;
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  position: relative;
  top: 1px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  color: #212b36;
  right: -4px;
}
.business-filter .dropdown-toggle i {
  margin-right: 4px;
  font-size: 16px;
  color: #637381;
}
.business-filter .dropdown-toggle:hover,
.business-filter .dropdown-toggle:hover i {
  color: #018eff;
}
.business-filter .dropdown-toggle:hover:after {
  border-bottom-color: #018eff;
  border-right-color: #018eff;
}
.owl-carousel .owl-stage-outer {
  padding: 0 8px;
  margin: 0 -8px;
}
.business-filter .dropdown-menu {
  min-width: 215px;
}
.px-40 {
  padding: 12px 25px;
}
.client-card {
  margin: 0 -1.5%;
}
/* .card-grid {
	  width: 17%;
	  margin: 1.5%;
  }
  */
.card-row {
  display: flex;
  flex-wrap: wrap;
  /* align-content: space-around; */
}
.card-row .card-body img {
  margin-bottom: 16px;
}
button.favorite-button {
  background: none;
  border: 0 none;
  position: absolute;
  right: 12px;
  bottom: 12px;
  font-size: 14px;
  padding: 5px;
  color: #c4cdd5;
  -webkit-transition: color 0.5s, opacity 0.5s, -webkit-transform 0.2s;
  transition: color 0.5s, opacity 0.5s, -webkit-transform 0.2s;
  transition: transform 0.2s, color 0.5s, opacity 0.5s;
  transition: transform 0.2s, color 0.5s, opacity 0.5s, -webkit-transform 0.2s;
}
button.favorite-button i.active {
  color: #ffc82c;
}
.active-star {
  color: #ffc82c;
}
.main-component .search-nav .search-alpha .nav-item i {
  font-size: 14px;
}
.main-component .search-nav .search-alpha .nav-item,
.main-component .search-nav .search-alpha .nav-item button {
  background: transparent;
  border: 0;
  display: inline-block;
  font-size: 12px;
}
.main-component .search-nav .search-alpha .nav-item button {
  padding: 3px 0 3px 3px;
}
.main-component .search-nav .search-alpha .nav-item a.nav-link {
  display: block;
  align-items: normal;
  flex-direction: unset;
  padding-right: 6px !important;
}
.searchFilter {
  position: relative;
}
.searchFilter:after {
  content: "";
  position: absolute;
  background: #f9fafb;
  width: 24px;
  height: 0px;
  bottom: 0;
  right: 0;
}
.searchFilter input[type="search"] {
  color: #919eab;
  font-size: 12px;
  letter-spacing: -0.25px;
  font-family: graphik-medium;
  border: 1px solid #ccc;
  margin-bottom: 0;
  background: #fff url(../Images/search-filter.png) no-repeat scroll 180px;
  background-size: 16px;
  padding: 8px 30px 8px 12px;
  width: 210px;
  cursor: auto;
  border-radius: 20px;
}
.searchIcon::-webkit-search-cancel-button {
  font-size: 18px;
}
.noAfter::after {
  content: "";
  display: none;
}
/* .searchFilter input[type="search"]:focus {
	width: 165px;
	cursor: auto;
	border-bottom: 1px solid #919eab;
  } */
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}
.outcome-highlight,
.analysisReport,
.analysisFull,
.batOffering-category {
  padding: 16px 16px 10px;
  margin: 12px 0;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.analysisFull.grayed-out,
.analysisFull.grayed-out:hover {
  /* background-color: #e6e9ed;
  border-color: lightgray; */
  cursor: default;
  opacity: 0.5;
  border-color: transparent;
}
.no-whiteShadow {
  background: none;
  border-radius: 0;
  box-shadow: none;
}
.landBanner {
  border-radius: 32px;
  box-shadow: 0px 4px 32px 0px rgba(33, 43, 54, 0.4);
  background: url(../Images/chartGrup.png) no-repeat scroll center bottom darkblue;
  padding: 40px 40px 215px;
}
.landBanner h1 {
  color: #ffffff;
  font-family: graphik-semibold;
  font-size: 28px;
  letter-spacing: -0.75px;
  line-height: 28px;
  margin-top: 24px;
}
.landBanner p {
  color: #dfe3e8;
  font-family: graphik;
  font-size: 24px;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-bottom: 0;
}
.landBanner .acclogo {
  margin-top: -5px;
}
.syn-people-list {
  position: absolute;
  bottom: -205px;
  width: 100%;
  left: 0;
}
.syn-people {
  margin: 15px 0 30px;
  text-align: center;
}
.syn-people h3 {
  color: rgb(33, 43, 54);
  font-family: graphik-medium;
  font-size: 20px;
  letter-spacing: -0.25px;
  line-height: 19px;
  margin-top: 12px;
  margin-bottom: 3px;
}
.syn-people p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  letter-spacing: -0.35px;
  line-height: 19px;
  margin-bottom: 40px;
}
.syn-people img {
  border: 3px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 35px 0px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
.syn-people a {
  background: rgb(255, 255, 255);
  border-radius: 30px;
  border: 1.5px solid rgb(1, 142, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  color: rgb(1, 142, 255);
  font-family: graphik-medium;
  font-size: 15px;
  letter-spacing: -0.25px;
  line-height: 15px;
  padding: 15px 45px;
  display: inline-block;
}
.syn-people a.deactive {
  border: 1.5px solid rgb(145, 158, 171);
  opacity: 0.5;
  background: transparent;
}
.syn-people a:hover {
  background: rgb(1, 142, 255);
  border-color: rgb(1, 142, 255);
  color: #ffffff;
}
.card-overlay {
  position: absolute;
  left: -16px;
  right: 0;
  top: 0;
  bottom: 0;
  background: hsla(0, 0%, 100%, 0.9);
  border-radius: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: scale(0);
  transform: scale(0);
  z-index: 10;
}
.owl-item .card-overlay {
  left: -32px;
  right: -16px;
  top: -16px;
  bottom: -16px;
}
.rd-data .card-overlay {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.outcome-indicate:hover .card-overlay {
  -webkit-transform: scale(1);
  transform: scale(1);
  border-radius: 3px;
}
/* .owl-item .outcome-divider .outcome-indicate {
	  height: 128px;
  } */
.owl-item .outcome-indicate:hover .card-overlay {
  border-radius: 8px;
}
.ioj-full .card-overlay {
  left: -32px;
  right: 0;
  top: -12px;
  bottom: -16px;
}
.ioj-full .item.outcome-divider .card-overlay {
  left: -24px;
  right: -8px;
  top: -8px;
  bottom: -8px;
}
.card-overlay button {
  width: 48px;
  height: 48px;
  border: 0 none;
  margin: 8px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}
.card-overlay button span {
  position: absolute;
  background: #1c262f;
  padding: 5px 8px 4px;
  border-radius: 2px;
  color: #fff;
  bottom: 50px;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation: b 0.5s forwards;
  animation: b 0.5s forwards;
  font-size: 11px;
  width: 80px;
  left: 0px;
}
.card-overlay button span:after {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #1c262f;
  position: absolute;
  left: calc(50% - 10px);
  bottom: -5px;
}
.card-overlay button:hover span {
  -webkit-animation: a 0.5s forwards;
  animation: a 0.5s forwards;
}
button.card-close {
  background: url(../Images/close.svg) no-repeat scroll 0 0 transparent;
}
button.card-chart {
  background: url(../Images/chart.svg) no-repeat scroll 0 0 transparent;
}
button.card-rocket {
  background: url(../Images/rocket.svg) no-repeat scroll 0 0 transparent;
}
button.card-message {
  background: url(../Images/cmt_icon.svg) no-repeat scroll 0 0 transparent;
}
button.card-info-latest {
  background: url(../Images/Info.svg) no-repeat scroll 0 0 transparent;
}
/* Below card-info class is used in BAT */
button.card-info {
  background: url(../Images/Info.svg) no-repeat scroll 0 0 transparent;
}
button.card-form {
  background: #f4f6f8;
  border: 1px solid #dfe3e8;
  border-radius: 50%;
}
button.card-form::after {
  content: "\f31c";
  font-family: "Font Awesome 5 Pro";
  font-size: 26px;
  color: rgb(99, 115, 129);
  line-height: 46px;
}
/* button.card-info:after {
	content: "\f129";
  }  */
@-webkit-keyframes a {
  0% {
    opacity: 0;
    bottom: 46px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    opacity: 1;
    visibility: visible;
    bottom: 50px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes a {
  0% {
    opacity: 0;
    bottom: 46px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    opacity: 1;
    visibility: visible;
    bottom: 50px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.small-link {
  color: rgb(1, 142, 255);
  font-family: graphik-medium;
  font-size: 12px;
  letter-spacing: -0.25px;
}
.small-link span {
  font-size: 18px;
  display: inline-block;
  margin-right: 4px;
  position: relative;
  top: 1px;
}
.pos-right {
  position: absolute;
  right: 16px;
  top: 0;
}
.ioj-full .outcome-indicate > p {
  letter-spacing: -0.35px;
  margin: 0;
  font-size: 16px;
}
.tag-divider {
  color: rgb(99, 115, 129);
  font-family: graphik-medium;
  font-size: 12px;
  letter-spacing: -0.25px;
}
.tag-divider span {
  font-family: graphik;
}
.tag-divider [class^="icon"] {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.ioj-full {
  margin-top: 24px;
}
.ioj-full .outcome-indicate .outcome-bottom p {
  color: rgb(99, 115, 129);
  font-family: Graphik;
  font-size: 12px;
  letter-spacing: -0.25px;
  line-height: 15px;
  margin-bottom: 0;
  margin-top: 6px;
}
.tag-divider b {
  border-right: 1px solid rgb(99, 115, 129);
  padding-right: 5px;
  margin-right: 5px;
  font-weight: normal;
}
.tag-divider b:last-of-type {
  border-right: 0;
}
.tag-divider * {
  vertical-align: top;
}
.ioj-full .item.outcome-divider p {
  font-size: 12px;
  margin-bottom: 8px;
  word-break: normal;
}
.ioj-full .outcome-highlight .item.outcome-divider {
  padding: 8px 8px 8px 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(33, 43, 54, 0.15);
  margin: 4px 0;
}
.ioj-full .outcome-highlight .item.outcome-divider .outcome-indicate {
  margin-right: 0;
}
.ioj-full .item.outcome-divider .outcome-bottom span {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-left: 30px;
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 2px;
}
.ioj-full .item.outcome-divider .outcome-bottom span:first-child {
  margin-left: 0;
}
.ioj-full .item.outcome-divider .outcome-bottom span b {
  font-family: graphik-medium;
  font-weight: normal;
}
.ioj-full .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -18px;
}
/* .ioj-full .owl-carousel .owl-stage-outer {
	  padding: 0 44px;
	  margin: 0 -8px;
  }
  */
.ioj-full .outcome-highlight .outcome-divider {
  padding: 12px 24px 16px 32px;
}
.ioj-full .outcome-highlight {
  margin-bottom: 16px;
}
.ioj-full .outcome-indicate {
  margin-right: 24px;
}
.ioj-full .owl-carousel .owl-nav [class*="owl-"] {
  box-shadow: none;
  background-color: transparent;
}
.ioj-full .owl-carousel .owl-nav .owl-prev {
  left: -30px;
}
.ioj-full .owl-carousel .owl-nav .owl-next {
  right: -30px;
}
.modal-backdrop.fade {
  opacity: 0.7;
}
.modal-backdrop {
  background-color: #ffffff;
}
.modal-body {
  padding: 1em 2em;
}
.rwt__tablist:not([aria-orientation="vertical"]) {
  display: flex;
  border-bottom: 0 none;
  flex-direction: row;
}
.rwt__tab {
  padding: 0;
  margin-right: 40px;
  color: #919eab;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-family: graphik-medium;
  position: relative;
  padding-bottom: 10px;
  flex-direction: column;
  display: flex;
  align-items: center;
}
/* .rwt__tab[aria-selected="true"]:after {
	  display: none;
  } */
.rwt__tab:after {
  content: "";
  background: #018eff;
  width: 0;
  height: 5px;
  border-radius: 3px;
  position: absolute;
  bottom: 0;
}
.rwt__tab[aria-selected="true"]:after,
.rwt__tab:hover:after {
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  width: 100%;
  border-bottom: 0 none !important;
  bottom: 0 !important;
}
.rwt__tab {
  color: #919eab;
}
.rwt__tab[aria-selected="true"],
.rwt__tab:hover {
  color: #018eff;
}
.rwt__tab:hover,
.rwt__tab:focus,
[aria-selected="false"].rwt__tab:hover {
  background-color: transparent !important;
}
.rwt__tablist {
  margin-bottom: 16px;
}
.title-with-icon {
  display: flex;
  justify-content: space-between;
}
.list-icon {
  margin-top: -3px;
}
.list-icon span {
  margin: 0 0 0 24px;
}
.list-icon span:first-child {
  margin-left: 0;
}
.fullflex .outcome-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
}
.fullflex .outcome-row .outcome-divider {
  width: 17.21%;
  display: block;
  text-align: left;
  margin: 14px;
}
.__react_component_tooltip {
  font-size: 12px !important;
  padding: 8px 12px !important;
  letter-spacing: -0.25px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-r-10 {
  padding-right: 10px;
}
.textareaDesign {
  border: 1px solid #ced4da !important;
  width: 100%;
  border-radius: 5px;
  color: #495057;
  outline: none;
}
.displayInline {
  display: inline-block;
}
/* select:focus,option:focus{
	  outline: none;
	  box-shadow: 0 0 10px #719ECE;
  } */
.css-rciaot {
  background: rgba(165, 158, 158, 0.7);
}
.trendGraphModal {
  max-width: 70% !important;
}
.react-checkbox-tree {
  letter-spacing: -0.25px;
}
.rct-node-icon .rct-icon-leaf-open,
.rct-node-icon .rct-icon-leaf-close {
  color: #018eff;
}
.rct-node-icon .rct-icon-parent-open,
.rct-node-icon .rct-icon-parent-close {
  color: #ffe38b;
}
.rct-checkbox .rct-icon-check,
.rct-checkbox .rct-icon-uncheck {
  color: #ced4da;
}
.rct-bare-label .rct-title {
  font-size: 13px;
  font-family: graphik-semibold;
}
.react-checkbox-tree .rct-icon {
  font-size: 18px;
}
.rct-collapse *,
.rct-checkbox *,
.rct-node-icon * {
  width: 18px;
}
.rdg-cell {
  font-size: 13px;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  width: 34px;
}
.display-sm {
  display: none;
}
.table-custom {
  border: 1px solid #dfe3e8;
}
.table-custom th,
.table-custom td {
  font-size: 13px;
  padding: 0.7rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    "Helvetica Neue", sans-serif;
  border-color: #d9dcde;
}
.table-custom thead th {
  background-color: #f5f7f7;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  border-bottom: 1px solid #dfe3e8;
}
.table-custom tbody tr:nth-child(even) {
  background: #fcfdfe;
}
td .form-check {
  top: 2px;
  font-weight: 500;
}
.landbox-overflow {
  height: 218px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 5px 0;
}
.landBox-main {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.7%;
  align-content: flex-start;
  position: relative;
}
.landBox-grid {
  border-radius: 8px;
  border: 1px solid #dfe3e8;
  width: 47%;
  margin: 0.7%;
  cursor: default;
  padding: 3px 16px 14px;
}
.insideArrow .owl-item .item {
  margin: 0;
  width: auto;
}
.landBox .outcome-highlight h2 {
  font-family: graphik-semibold;
  font-size: 16px;
  letter-spacing: -0.25px;
}
.landBox .outcome-highlight h2 svg {
  margin-right: 3px;
  color: #6a6a6a;
  vertical-align: middle;
  font-size: 19px !important;
  height: 1.7em;
}
.landBox-grid a {
  line-height: 16px;
  color: #018eff !important;
  font-family: graphik-medium;
  font-size: 14px;
  letter-spacing: 0;
  display: block;
  margin: 2px;
}
.landBox-grid p {
  color: #778899;
  font-family: graphik;
  font-size: 12px;
  letter-spacing: -0.25px;
  line-height: 15px;
  overflow: hidden;
  margin: 2px 0 0;
}
.link-text {
  color: rgb(1, 142, 255);
  font-family: graphik-medium;
  font-size: 12px;
  letter-spacing: -0.25px;
}
.landBox .fa-plus {
  font-size: 12px;
}
.landBox .outcome-highlight {
  padding-bottom: 12px;
}
.landBox-grid a svg {
  color: #ff0000;
  margin-right: 3px;
}
.insideArrow {
  padding: 0 24px;
}
.insideArrow .owl-carousel .owl-nav [class*="owl-"] {
  height: auto;
  margin-top: 0;
  right: -28px;
}
.insideArrow .owl-carousel .owl-nav .owl-prev {
  left: -28px;
  right: 0;
}
.insideArrow .owl-carousel {
  margin-bottom: 16px;
}
.insideArrow .owl-carousel:last-of-type {
  margin-bottom: 0;
}
.insideArrow .owl-carousel .owl-nav .disabled {
  opacity: 0.5;
}
.landBox .outcome-highlight h6 {
  color: #919eab;
  font-family: graphik-medium;
  font-size: 12px;
  letter-spacing: -0.21px;
  line-height: 13px;
  margin-left: -18px;
}
.landing-page-icons {
  width: 15px !important;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: baseline;
}
.snapList li.list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding: 14px 12px 12px 16px;
}
/* .snapList li.list-group-item:hover {
	  border-color: transparent;
	  box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.16);
  } */
.snapList h4 {
  color: rgb(33, 43, 54);
  font-family: graphik-medium;
  font-size: 16px;
  letter-spacing: -0.35px;
  margin: 0 0 2px;
}
.divider-text {
  color: #637381;
  font-family: graphik-medium;
  font-size: 12px;
  letter-spacing: -0.25px;
}
.divider-text span {
  position: relative;
  padding-right: 18px;
}
.divider-text span.active {
  color: rgb(1, 142, 255);
}
.divider-text span:after {
  background: #637381;
  content: "";
  width: 1px;
  height: 12px;
  position: absolute;
  right: 10px;
  top: 0;
}
.divider-text span:last-of-type:after {
  display: none;
}
.snapList .media > svg {
  font-size: 24px;
  color: #018fff;
  align-self: center;
}
.snapList li.list-group-item .flag-display {
  display: none;
}
.snapList li.list-group-item:hover .flag-display {
  display: block;
}
.flag-display span {
  background: rgb(244, 246, 248);
  border: 1px solid rgb(223, 227, 232);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  padding-top: 3px;
  margin: 0 0 0 16px;
  color: #637381;
  cursor: pointer;
}
.flag-display span:hover {
  background: rgba(1, 142, 255, 0.08);
  border: 1px solid rgb(1, 142, 255);
  color: rgb(1, 142, 255);
}
.faqContainer .main-sidebar {
  position: relative;
  width: auto;
  display: block;
  box-shadow: none;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  padding: 12px 8px;
}
.radius-btn {
  margin: 0 -4px;
}
.radius-btn .btn {
  border-color: #ced4da;
  color: #4f555b;
  margin: 0px 4px 4px;
  font-size: 12px;
}
.radius-btn .btn:hover,
.radius-btn .btn:active,
.radius-btn .btn:focus {
  color: #ffffff;
  border-color: #007bff;
}
.faqContainer .main-sidebar li a:hover,
.faqContainer .main-sidebar li a.active {
  background: #ccc;
  color: #212b36 !important;
}
.collapseTab {
  right: 0;
  position: absolute;
  top: 2px;
  background-color: transparent;
}
.collapseTab:active,
.collapseTab:hover,
.collapseTab:focus {
  box-shadow: none;
}
.collapseTab:after {
  content: "\f106";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: #ccc;
}
.collapsed.collapseTab:after {
  content: "\f107";
}
.faqContainer .card {
  box-shadow: none;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.faqContainer .card .card-header h5 {
  border-bottom: 0;
  font-size: 14px;
  font-family: graphik-medium;
}
.faqContainer .card .card-header {
  background: #fff;
  padding: 1rem 2rem 1rem 1.25rem;
  border-bottom: 0 none;
}
.faqContainer .card p {
  font-size: 14px;
}
div#accordion {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  max-height: 380px;
  overflow: auto;
}
.faqContainer .card .card-body {
  font-size: 14px;
}
.ratioData p.minht,
.insightsNew .ratioData div.minht p {
  color: #919eab;
  font-size: 12px;
}
.ratioData .ratioBig {
  font-size: 48px;
  color: #212b36;
  font-family: graphik-bold;
  letter-spacing: -0.35px;
  display: block;
  text-align: center;
}
.outcome-highlight.ratioData:hover {
  background-color: #018eff;
  color: #fff;
}
.outcome-highlight.ratioData:hover h2,
.ratioData:hover p.minht,
.insightsNew .ratioData:hover div.minht p,
.ratioData:hover .ratioBig {
  color: #fff;
}
div[id^="clone"] {
  position: relative;
}

button.no-btn {
  background: transparent;
  border: 0;
  position: absolute;
  right: 30px;
  color: #d5002f;
  font-size: 14px;
}
.fs-medium {
  font-size: 14px;
  font-family: graphik-medium;
}
.onoffswitch {
  position: relative;
  width: 48px;
  display: inline-block;
  font-size: 80%;
}
.onoffswitch.pull-right {
  float: right;
}
.onoffswitch .onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid hsl(0, 0%, 90%);
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  margin: 0;
}
.onoffswitch .onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -webkit-transition: margin 0.15s ease-in-out;
  -o-transition: margin 0.15s ease-in-out;
  -moz-transition: margin 0.15s ease-in-out;
  transition: margin 0.15s ease-in-out;
}
.onoffswitch .onoffswitch-inner:before,
.onoffswitch .onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 22px;
  padding: 0;
  line-height: 22px;
  font-size: 80%;
  color: #ffffff;
  font-weight: normal;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.onoffswitch .onoffswitch-inner:before {
  content: "";
  padding-left: 10px;
  background-color: #7ec344;
  color: #ffffff;
}
.onoffswitch .onoffswitch-inner:after {
  content: "";
  padding-right: 10px;
  background-color: hsl(0, 0%, 98%);
  color: hsl(0, 0%, 24%);
  text-align: right;
}
.onoffswitch .onoffswitch-switch {
  width: 24px;
  height: 24px;
  margin: 0;
  background: hsl(0, 0%, 100%);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 25px;
  -webkit-transition: right 0.15s ease-in-out;
  -o-transition: right 0.15s ease-in-out;
  -moz-transition: right 0.15s ease-in-out;
  transition: right 0.15s ease-in-out;
}

/* ==========BAT Landing page styles Start========== */
.navbarBat a.navbar-brand:after,
.navbar.navbarBat .select-data:after {
  height: 28px;
  margin-top: -14px;
}

.navbarBat.navbar-default .navbar-nav.search-nav .nav-item a.nav-link:not(.dropdown-toggle):after,
.navbarBat.navbar-default
  .navbar-nav.search-nav
  .nav-item
  a.nav-link.active:not(.dropdown-toggle):after {
  border-radius: 10px 10px 0 0;
  bottom: -22px;
}

.navbarBat.navbar-default .navbar-nav > li > a.active {
  color: #212b36 !important;
}

.navbarBat .search-nav a.nav-link {
  padding-bottom: 8px;
}

.navbarBat.navbar {
  padding: 0.938rem 1.5em;
}

.analysisList h4 {
  color: rgb(33, 43, 54);
  font-family: graphik-medium;
  font-size: 16px;
  letter-spacing: -0.29px;
  margin: 0 0 25px;
}

.analysisList h3 {
  color: rgb(33, 43, 54);
  font-family: graphik-medium;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.29px;
}

.analysisList .analysisUnit {
  color: rgb(51, 51, 51);
  font-family: graphik-medium;
  font-size: 34px;
  letter-spacing: -0.5px;
  line-height: 38px;
  margin-bottom: 25px;
}

.analysisList .analysis-bottom {
  color: rgb(99, 115, 129);
  font-family: graphik-medium;
  font-size: 12px;
  letter-spacing: -0.25px;
  line-height: 12px;
  position: relative;
}

/* span.analysisArrow {
  position: absolute;
  right: 0;
  top: 0;
  border: 2px solid #7ec344;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 4px 5px;
  transform: rotate(45deg);
} */

span.analysisArrow i {
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  border: 2px solid;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 4px 5px;
}

span.analysisArrow .fa-arrow-up {
  color: #7ec344;
  border-color: #7ec344;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

span.analysisArrow .fa-arrow-down {
  color: #fc0000;
  border-color: #fc0000;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.analysisList {
  padding: 6px 24px;
  position: relative;
}

.analysisList p {
  margin-bottom: 0;
}

.analysisList:after {
  content: "";
  width: 1px;
  height: 145px;
  background-color: #dfe3e8;
  border-radius: 0.5px;
  position: absolute;
  top: 0;
  right: -16px;
}

.noAfter:after {
  display: none !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.analysisIcon i {
  width: 48px;
  height: 48px;
  border: 1px solid #0b4cff;
  margin: 0;
  border-radius: 50%;
  padding: 12px 0 0 10px;
  font-size: 24px;
  color: #0b4cff;
  background: -webkit-linear-gradient(-45deg, #0baff0, #0b4cff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.analysisTitle h4 {
  color: rgb(33, 43, 54);
  font-family: graphik-medium;
  font-size: 14px;
  letter-spacing: -0.35px;
  line-height: 18px;
  margin-bottom: 5px;
}

.analysisTitle p {
  color: rgb(99, 115, 129);
  font-size: 12px;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-bottom: 0;
}

.analysisFull {
  padding: 20px 20px 10px;
  border: 2px solid #ffffff;
}

.analysisFull:hover {
  cursor: pointer;
  border: 2px solid rgb(1, 142, 255);
  box-shadow: 0px 2px 24px 0px rgba(33, 43, 54, 0.25);
}

.analysisTitle {
  margin: 12px 0 15px;
}

.analysisHrList h5 {
  color: rgb(33, 43, 54);
  font-family: graphik-medium;
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 20px;
  margin-bottom: 0;
}

.analysisHrList p {
  color: rgb(145, 158, 171);
  font-family: graphik-medium;
  font-size: 10px;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.analysisHr {
  display: flex;
}

.analysisHrList {
  margin-right: 16px;
  max-width: 45%;
}

.analysisHrList:last-child {
  margin-right: 0;
}

.analyticsFilter {
  background: #fff;
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.1), 0 1px 0 0 #f4f6f8;
  /* display: flex;
  align-items: center; */
  padding: 16px 24px;
}

.analyticsFilter .row {
  width: 100%;
}

.analyticsFilter a,
.analyticsFilter .chooseOption span,
.custom-icon-datePicker {
  color: #637381;
  font-family: graphik-medium;
  border: 1px solid #c4cdd5;
  border-radius: 20px;
  padding: 13px 17px 11px;
  letter-spacing: -0.25px;
  line-height: 16px;
  font-size: 14px;
  display: inline-block;
  margin-right: 12px;
  cursor: pointer;
}

.analyticsFilter .chooseOption .owl-item span {
  margin-right: 0;
}

.analyticsFilter .date-picker-holder {
  margin-right: 10px;
  width: 265px;
  margin-left: -0.5%;
  margin-top: -10px;
}

.analyticsFilter .custom-icon-datePicker {
  padding: 9px 32px 8px 18px;
  display: block;
  margin-right: 0;
  cursor: default;
  line-height: 14px;
}

.analyticsFilter .date-picker-holder .react-datepicker-wrapper {
  width: 100%;
  /* margin: 0 1.5%; */
}

.date-picker-holder .react-datepicker-popper {
  min-width: 130px;
}

.analyticsFilter .date-picker-holder .react-datepicker-wrapper .custom-icon-datePicker {
  width: 100%;
}

.date-picker-holder label {
  margin-bottom: 0;
  font-size: 12px;
  display: block;
}

.analyticsFilter .calender-placment {
  color: #919eab;
  font-size: 16px;
  top: 26px;
  right: 16px;
}

a.backPush {
  padding-top: 12.75px;
  min-width: 85px;
}

a.backPush svg {
  color: #919eab;
  margin-right: 4px;
  display: inline-block;
}

/* .analyticsFilter a.fwdPush {
  border-radius: 50%;
  padding: 12.75px 15.75px 11px;
} */

.chooseOption {
  padding: 0 38px 0 22px;
}

.analyticsFilter .divider {
  height: 24px;
  border: 0;
  width: 1.5px;
  background: #dfe3e8;
  margin-right: 10px;
}

.chooseOption .owl-carousel .owl-nav [class*="owl-"] {
  border: 1px solid #c4cdd5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 28.5px;
  box-shadow: none;
}

.chooseOption .owl-carousel .owl-nav .owl-prev {
  left: -45px;
}

.chooseOption .owl-carousel .owl-nav .owl-next {
  right: -55px;
}

.chooseOption .owl-carousel .owl-nav .disabled {
  opacity: 0.5;
}

.analyticsFilter a:hover,
.analyticsFilter .chooseOption span:hover,
.analyticsFilter a.active,
.analyticsFilter .chooseOption span.active {
  background: rgba(1, 142, 255, 0.04);
  border: 1px solid rgb(1, 142, 255);
}

.analyticsFilter.no-whiteShadow .date-picker-holder {
  width: 126px;
  margin-left: 0;
  margin-top: -5px;
}

.analyticsFilter.no-whiteShadow {
  box-shadow: none;
  border-top: 1px solid #dfe3e8;
  border-bottom: 1px solid #dfe3e8;
  position: relative;
  display: flex;
  align-items: center;
}

.analyticsFilter.no-whiteShadow:after,
.analyticsFilter.no-whiteShadow:before {
  content: "";
  height: calc(100% + 2px);
  width: 32px;
  position: absolute;
  border-top: 1px solid #dfe3e8;
  left: -32px;
  border-bottom: 1px solid #dfe3e8;
  top: -1px;
}

.analyticsFilter.no-whiteShadow:after {
  left: auto;
  right: -32px;
}

.analyticsFilter.no-whiteShadow .chooseOption span {
  padding: 12px 17px 10px;
}

.withLabel .toggle-group input[type="checkbox"] {
  position: absolute;
  left: 10px;
}

.withLabel .toggle-group label {
  font-size: 14px;
  width: auto;
  padding-right: 0;
  position: relative;
  margin-bottom: 0;
  top: -7px;
  padding-left: 10px;
  color: #637381;
}

.withLabel .toggle-group label:before {
  content: "";
  position: absolute;
  width: 48px;
  height: 25px;
  left: -48px;
  top: -1px;
}

.batView .outcome-divider span.metric-digit {
  font-family: graphik-semibold;
}

.outcome-divider span.metric-details + span.metric-digit {
  margin-top: 6px;
}

.batView .outcome-divider span.metric-digit-text {
  font-family: graphik-semibold;
  font-size: 16px;
  letter-spacing: -0.35px;
  color: #212b36;
}

.batView .outcome-divider span.metric-details {
  margin-top: 14px;
  font-size: 11px;
  letter-spacing: -0.25px;
  line-height: 14px;
  height: auto;
}

.batView .outcome-divider span.metric-details b {
  font-family: graphik-semibold;
  display: block;
}

.batView .outcome-divider p.minht {
  margin-bottom: 5px;
}

.popover-footer {
  background: #f9fafb;
  border-top: 1px solid #dfe3e8;
  padding: 15px;
  border-radius: 0px 0px 16px 16px;
}

.bs-popover-bottom {
  border: 1px solid #dfe3e8;
  border-radius: 16px;
  box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.15);
  max-width: inherit;
}

.popContent {
  width: 900px;
  padding: 25px 28px 10px;
}

.popContent .form-check-label {
  font-size: 12px;
  font-family: graphik;
  letter-spacing: -0.25px;
  font-weight: normal;
  color: #212b36;
}

.popover .arrow {
  background: none !important;
}

.bs-popover-bottom .arrow::before {
  border-bottom-color: #dfe3e8 !important;
}

/* .analyticsFilter .date-picker-holder .react-datepicker__input-container:after {
  content: "\F073";
  font-family: "Font Awesome 5 Pro";
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #a7a7a7;
  font-weight: bold;
  position: absolute;
  right: 12px;
  top: 13px;
  z-index: 0;
} */

.smallPop.popover-body {
  width: 320px;
  padding: 13px 16px 15px;
}

.h4.modal-title svg {
  color: #637381;
}

.h4.modal-title svg.fa-chevron-left {
  position: absolute;
  left: 25px;
  color: #919eab;
  font-size: 16px;
}

.justify-content-center.modal-header {
  text-align: center;
}

.analyticsFilter .chooseOption .owl-item {
  width: 150px;
}

.demoPass p {
  font-size: 14px;
}

.demoPass p b {
  font-weight: normal;
  font-family: graphik-bold;
}

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  color: #212b36;
  border-radius: 8px;
  padding: 16px;
  min-width: 160px;
  /* max-height: 350px; */
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translate(-50%, -120%);
  transform: translate(-50%, -120%);
  z-index: 1;
  /* overflow: scroll; */
}

div#chartjs-tooltip:after {
  content: "";
  height: fit-content;
  width: fit-content;
  border: 8px solid #ffffff;
  position: absolute;
  margin-left: -8px;
  margin-top: -8px;
  top: 100%;
  left: 50%;
  transform: rotate(45deg);
  z-index: 1;
  /* box-shadow: 2px 5px 5px 0px rgba(33, 43, 54, 0.1); */
}
.tooltip-left-align:after {
  top: 50% !important;
  right: 100% !important;
  left: 0% !important;
  margin-left: -10px !important;
  transform: rotate(0deg) !important;
  border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important;
  border-right: 10px solid #ffffff !important;
  border-left: none !important;
}
.tooltip-right-align:after {
  top: 50% !important;
  left: 100% !important;
  right: 0% !important;
  transform: rotate(0deg) !important;
  border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important;
  border-left: 10px solid #ffffff !important;
  border-right: none !important;
  margin-left: 0px !important;
}

.tooltip-title {
  color: rgb(33, 43, 54);
  font-family: graphik-medium;
  font-size: 12px;
  letter-spacing: -0.25px;
  margin-bottom: 18px;
  cursor: pointer;
}

.tooltip-data p {
  color: rgb(33, 43, 54);
  font-size: 24px;
  font-family: graphik-medium;
  letter-spacing: -0.35px;
  line-height: 28px;
  margin-bottom: 8px;
}

.tooltip-data p.multiple {
  font-size: 16px;
  margin-bottom: 0;
}

.tooltip-data p small {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: graphik;
  letter-spacing: -0.25px;
  line-height: 12px;
  vertical-align: middle;
}

.tooltip-data .viewDetails {
  color: rgb(1, 142, 255);
  font-size: 12px;
  font-family: graphik-medium;
  letter-spacing: -0.25px;
  cursor: pointer;
  padding-left: 0px;
}

.tooltip-data .viewDetails.multiple {
  font-size: 10px;
}

.analyticsFilter .chooseOption .owl-item:last-child {
  margin-right: 0 !important;
}

.modal-btn.temp {
  justify-content: inherit;
}

.onoffswitch .onoffswitch-inner:before,
.onoffswitch .onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 22px;
  padding: 0;
  line-height: 22px;
  font-size: 80%;
  color: #ffffff;
  font-weight: normal;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch .onoffswitch-inner:before {
  content: "";
  padding-left: 10px;
  background-color: #7ec344;
  color: #ffffff;
}

.onoffswitch .onoffswitch-inner:after {
  content: "";
  padding-right: 10px;
  background-color: hsl(0, 0%, 98%);
  color: hsl(0, 0%, 24%);
  text-align: right;
}

.onoffswitch .onoffswitch-switch {
  width: 24px;
  height: 24px;
  margin: 0;
  background: hsl(0, 0%, 100%);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 25px;
  -webkit-transition: right 0.15s ease-in-out;
  -o-transition: right 0.15s ease-in-out;
  -moz-transition: right 0.15s ease-in-out;
  transition: right 0.15s ease-in-out;
}
/* ==========BAT Landing page styles End============ */
.toggle-group {
  position: relative;
  height: 26px;
  display: inline-block;
}
.toggle-group input[type="checkbox"] {
  position: absolute;
  right: 10px;
  display: none;
}
.toggle-group input[type="checkbox"]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.toggle-group input[type="checkbox"]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
  right: 0px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
.toggle-group input[type="checkbox"]:focus ~ .onoffswitch {
  outline: thin dotted #333;
  outline: 0;
}
.toggle-group label {
  position: absolute;
  cursor: pointer;
  padding-right: 48px;
  display: inline-block;
  text-align: left;
  line-height: 24px;
  width: 100%;
  z-index: 1;
  min-height: 24px;
  font-weight: 200;
  color: #7b8e98;
  font-size: 0;
}
.form-group.border-group {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0 10px 10px;
  margin-bottom: 15px !important;
}
.border-group label.form-label {
  font-size: 11.3px;
  margin-bottom: 0px;
  letter-spacing: 0.05px;
}
.border-group .form-control {
  padding: 0;
  border: 0 none;
  height: auto;
}
.border-group select.form-control {
  margin-left: -4px;
  width: calc(100% + 4px);
}
.border-group.disable {
  background: rgba(230, 230, 230, 0.3);
}
.border-group .form-control:disabled {
  background: transparent;
  color: rgba(30, 30, 30, 0.5);
}
.btn-secondary:disabled {
  opacity: 0.5;
}
.toggle-form .tab-content {
  padding: 15px 0 0;
  background: transparent;
  border-radius: 0;
}
.toggle-form .nav-tabs {
  counter-reset: tabCount;
}
.toggle-form .nav-tabs a::before {
  counter-increment: tabCount;
  content: counter(tabCount);
  background: #fff;
  color: #999999;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  margin-bottom: 5px;
  border: 1px solid #bbb;
  position: relative;
  z-index: 10;
  padding-top: 1px;
}
.toggle-form .nav-tabs a.active::before {
  background: #018fff;
  border-color: #018fff;
  color: #fff;
}
.toggle-form .nav-tabs a::after {
  content: "";
  background-color: #ccc;
  width: 150%;
  height: 1.5px;
  position: absolute;
  left: 50%;
  top: 18px;
}
.tabsSmall .nav-tabs .nav-link:last-child:after {
  display: none;
}
.toggle-form .nav-tabs a:nth-last-child(2)::after {
  width: 115%;
}
.tabsSmall .nav-tabs {
  border-bottom: 0;
  position: relative;
  display: flex;
  justify-content: center;
}
.tabsSmall .nav-tabs .nav-link {
  color: #333333;
  display: flex;
  align-items: center;
  flex-flow: column;
  font-family: graphik;
  margin: 0 30px 12px;
  position: relative;
  border: 0;
}
.tabsSmall .nav-tabs .nav-item.show .nav-link,
.tabsSmall .nav-tabs .nav-link.active {
  color: #018eff !important;
  background-color: transparent !important;
  border: 0 none;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: 0 none;
}
.onoffswitch .inact.onoffswitch-inner:after {
  content: "Inactive";
}
.onoffswitch .inact.onoffswitch-inner:before {
  content: "Active";
}
.toggle-check .toggle-group {
  vertical-align: bottom;
}
.toggle-check .toggle-group label {
  padding-right: 70px;
}
.toggle-check .onoffswitch {
  width: 70px;
}
.toggle-check .onoffswitch .onoffswitch-switch {
  right: 55px;
}
.formulaBuild h5.card-header {
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.formulaBuild h5.card-header .toggle-group {
  vertical-align: middle;
}
.formulaBar span {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px 20px;
  display: inline-block;
  font-size: 20px;
  color: #666;
}
.formulaBar select {
  display: inline-block;
  width: auto;
  padding: 5px 0;
  font-size: 20px;
  border: 1px solid #ccc;
  margin: 0 12px;
  border-radius: 5px;
  height: auto;
}
.border-group .form-control:focus {
  box-shadow: none;
  outline: none;
  border-color: transparent;
}
.formulaBar {
  margin: 12px 0 18px;
}
.addSource .fas {
  font-size: 45px;
}
.addSource h5 {
  font-family: graphik-medium;
  font-size: 16px;
  margin-top: 24px;
}
.addIconCircle {
  border-radius: 50%;
  border: 1px solid #ccc;
  height: 32px;
  width: 32px;
  background: #f4f4f4;
  font-family: graphik-bold;
  font-size: 20px;
  color: #777;
  margin-left: 12px;
}
.formulaBuild small.card-text {
  font-size: 65%;
}
.infoModalLabel {
  color: rgb(145, 158, 171);
  font-family: graphik-medium;
  font-size: 12px;
  letter-spacing: -0.25px;
  line-height: 10px;
  font-weight: 500;
}

.infoModalText {
  color: #2e2f30;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 15px;
  font-weight: normal;
}

/***********************20may 2020***********************/
.landbx {
  width: 46%;
  margin: 1%;
  position: relative;
  padding: 6px 12px 14px;
}

.svg-inline--fa {
  vertical-align: middle;
}
.svg-inline--fa.fa-w-18 {
  vertical-align: middle;
}

.insideArrow .owl-item .item {
  width: 100%;
}

.hubBar {
  margin: auto;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  width: 98%;
  /* line-height: 40px; */
  padding: 3px;
}
.hubBar span {
  margin-left: 8px;
}
.hubBar img {
  margin-bottom: 4px;
}
.landing-page-icons {
  width: 25px !important;
}
.svg-inline--fa {
  height: 1.7em;
}
.landBox .outcome-highlight h2 {
  font-size: 16px;
}
.landBox-grid {
  margin: 1%;
  position: relative;
  padding: 3px 12px 14px;
}

.insideArrow .owl-item .item {
  border: none;
}
.landbox-overflow {
  padding-top: 10px;
  margin: 0;
}
.landBox-main {
  padding-top: 10px;
}
.landBox .outcome-highlight h2 {
  margin-bottom: 0;
}
.landBox-grid .tooltiptext {
  visibility: hidden;
  width: 90%;
  height: auto;
  border-radius: 6px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 3px 13px 7px;
  position: absolute;
  z-index: 9;
  opacity: 0;
  right: 0;
  left: 0;
  top: 80px;
  margin: 0 auto;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  font-size: 12px;
}

.landBox-grid:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.landBox-grid:hover {
  border: 1px solid #007bff;
  -webkit-box-shadow: -1px 4px 12px -1px rgba(71, 71, 71, 0.95);
  box-shadow: -1px 4px 12px -1px rgba(71, 71, 71, 0.95);
}

.landBox-grid .tooltiptext::after {
  content: "";
  position: absolute;
  top: -12px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  transform: rotate(180deg);
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.insideArrow .owl-carousel {
  margin-bottom: 3px;
}
.list-icon {
  margin-top: -3px;
  /* position: absolute; */ /* IMPORTANT--- postion:absolute should not be added. Configurator screen + buttons breaks*/
  right: 8%;
  display: inline-block;
  top: -3%;
}
.head-spce {
  width: 83%;
  display: inline-block;
  padding-left: 13px;
  vertical-align: middle;
}
/* .svg-inline--fa{font-size: 19px !important;} */
.landBox .outcome-highlight h2 {
  font-weight: 800;
}
.svg-inline--fa {
  vertical-align: middle;
}
.landing-page-icons {
  vertical-align: middle;
}

.tabBar .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: transparent !important;
  color: #018eff !important;
  border-bottom: 2px solid #018eff;
}
.nav-tabs > li > a.active,
.nav-tabs > li > a.active:hover {
  border-right: none;
  border-left: none;
  border-top: none;
}
.nav-tabs {
  border: none;
}
.list-icon {
  top: 12%;
  vertical-align: middle;
  cursor: pointer;
}
.tabBar .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: transparent !important;
  color: #018eff !important;
  border-bottom: 2px solid #018eff;
}
.tabs {
  width: 100%;
}
.tabBar.nav-tabs {
  padding: 20px 10px 0 10px;
  display: inline-block;
}
.tabBar .title-with-icon {
  flex-wrap: wrap;
}
.tabBar .nav > li > a {
  color: #919eab !important;
  padding: 10px 0;
  text-decoration: none;
  margin-right: 20px;
}
.tabBar .tab-content {
  background: transparent;
}
.tabBar .nav > li,
.nav > li > a {
  float: left;
}
.tabBar .nav-tabs {
  padding: 0px 0 20px 0;
}
.tabBar .tab-content .modal-btn {
  position: absolute;
  top: 0;
  right: 0;
}
.tabBar .show-pop {
  color: #007bff;
  background: transparent;
  box-shadow: none;
  border: none;
  font-weight: 800;
}
.tabBar .show-pop svg {
  margin-right: 10px;
}
.tabBar.show-pop:hover .fa-plus {
  color: #fff;
}
.owlTwoTile .owl-item {
  width: 48% !important;
  float: none;
}
.owlTwoTile .owl-stage {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.owlTwoTile .owl-item {
  margin-bottom: 10px;
  border: 1px solid #dfe3e8;
  border-radius: 8px;
}
.owlTwoTile .owl-stage-outer {
  overflow: visible;
}
.owlTwoTile .owl-item:hover {
  border: 1px solid #007bff;
  -webkit-box-shadow: -1px 4px 12px -1px rgba(71, 71, 71, 0.95);
  box-shadow: -1px 4px 12px -1px rgba(71, 71, 71, 0.95);
}
.owlTwoTile .owl-item .landBox-grid:hover {
  box-shadow: none;
}

.owlTwoTile .owl-stage {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/*.owlTwoTile .landBox-grid .tooltiptext{top:-32%}*/

.owlTwoTile .owl-item .item {
  position: relative;
}

.landBox .outcome-highlight {
  padding-top: 12px;
  position: relative;
}

.landBox-main {
  width: 100%;
  padding-top: 25px;
}

.owlTwoTile .owl-stage-outer {
  padding-top: 35px;
  top: -30px !important;
  position: relative;
}

.owlTwoTile .owl-prev {
  font-size: 0px;
}

.owlTwoTile .owl-next {
  font-size: 0px;
}

.owllandmain {
  padding-bottom: 0;
}

.owlTwoTile {
  width: 100%;
}

.two .owl-stage-outer {
  top: -63px;
}

.owltwo .owl-stage-outer {
  top: -63px;
}

.olthree .owl-stage {
  width: 100% !important;
  display: flex;
  padding-top: 5px;
  margin-bottom: 28px;
  flex-direction: row;
  flex-wrap: wrap;
}

.olthree .owl-item {
  width: 48% !important;
  margin-bottom: 10px;
  border: 1px solid #dfe3e8;
  border-radius: 8px;
}

.olthree .owl-prev {
  font-size: 0px;
  color: #fff;
}

.olthree .owl-next {
  font-size: 0px;
  color: #fff;
}

/* pulse page new styles June 05 2020 by Srihari*/
.landBox-grid p {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: block;
  -webkit-box-orient: vertical;
  /*IE styles */
  white-space: nowrap !important;
  overflow: hidden;
  word-wrap: normal !important;
  -ms-line-clamp: 2;
}

.landBox-grid {
  width: 47%;
  margin: 8px !important;
  height: 80px;
}
.olthree .owl-stage {
  padding-top: 32px;
}

/* Phase 2 BAT Enhancement Style Changes Start*/
.metric-details-icon {
  display: flex;
  justify-content: space-between;
}

.metric-details-icon i {
  font-size: medium;
  margin-right: 10px;
}

.batView .card-overlay {
  flex-direction: column;
  padding-top: 20px;
}

.batView .card-overlay .card-benchmark {
  color: rgb(99, 115, 129);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.28px;
  padding-left: 9px;
}

.benchmarkTooltipDropdown {
  font-size: 14px;
  margin-left: -80px !important;
  width: 459px !important;
}

.benchmarkTooltipDropdown .popContent {
  width: 459px;
}

.benchmarkTooltipDropdown .form-check-label {
  color: rgb(99, 115, 129);
  font-family: Graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.35px;
}

/*BAT - Commented out the style to align the close button of the MetricInsightDetails modal to the right*/
/*.bat-chart-modal .close {
	float: left;
	right: 0 !important;
	left: 16px !important;
  }*/

.bat-offering-details-screen.analyticsFilter .chooseOption span.active {
  cursor: default;
}
.bat-offering-details-screen.analyticsFilter .chooseOption span.grayed-out,
.bat-offering-details-screen.analyticsFilter .chooseOption span.grayed-out:hover {
  cursor: default;
  opacity: 0.5;
  border: 1px solid #c4cdd5 !important;
}
.batOffering {
  width: 26%;
}
.batOffering a.backPush {
  padding-top: 8px;
  padding-bottom: 8px;
}
.carouselOffering {
  width: 73%;
  margin-left: 9px;
}
.calenderRightDivider {
  margin-left: 9px;
}
.calenderSpan {
  /* margin-right: -28px !important;
    margin-left: -20px !important; */
  display: flex;
  padding: 0px;
}
.calenderChooseOption {
  padding: 0px !important;
}
.calenderSpan span {
  padding-left: 14px !important;
  letter-spacing: -0.75px;
  margin-right: 1px !important;
}
.calenderSpan i {
  padding-right: 0px;
  margin-left: 2px;
}
.calenderPopover {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  left: 70px !important;
}
.calenderPopover .arrow {
  left: -70px !important;
}
.calenderPopover .popover-body {
  width: inherit;
}
.calenderPopover .popContent {
  width: inherit;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 5px;
}
.popContentDisplay {
  display: flex;
  justify-content: space-between;
}
.calenderPopoverSpan {
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(223, 227, 232);
  border-radius: 4px;
  padding: 5px 40px 4px 8px;
}
.chooseOption .calenderSpan span {
  /* padding-right: 0px; */
  padding-right: 10px;
}
.calenderPopoverSpan:hover,
.calenderPopoverSpanActive {
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(161, 0, 255);
  border-radius: 4px;
  padding: 5px 40px 4px 8px;
  cursor: pointer;
}

.calenderPopover .react-datepicker {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(209, 217, 222);
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: -3px;
}
.calenderPopover .react-datepicker .react-datepicker__month-container {
  width: inherit;
  height: inherit;
  line-height: 1.8;
}

.calenderPopover .react-datepicker__month .react-datepicker__month-text {
  width: 5rem;
  border: 1px solid rgb(220, 175, 255);
  background: rgb(250, 245, 253);
  border-radius: 0px;
  margin: 0px !important;
  padding: 3px;
}
.calenderPopover .react-datepicker__month-wrapper .react-datepicker__month-text {
  border-right: none;
  border-bottom: none;
}
.calenderPopover .react-datepicker__month-wrapper .react-datepicker__month-text:last-child {
  border-right: 1px solid rgb(220, 175, 255);
}
.calenderPopover .react-datepicker__month-wrapper:last-child .react-datepicker__month-text {
  border-bottom: 1px solid rgb(220, 175, 255);
}
.calenderPopover .react-datepicker__month .react-datepicker__month--disabled {
  background-color: rgb(255, 255, 255);
}
.calenderPopover .react-datepicker__header {
  padding: 4px 0px;
  background: rgb(251, 252, 252);
}
.calenderPopover .popover-footer {
  padding: 8px;
  margin-top: -9px;
}
.CalenderDatepickerLabel {
  color: rgb(99, 115, 129);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.CalenderDatepickerDate {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin-top: 1px;
}
.chart-legend li span {
  display: inline-block;
  width: 25px;
  height: 12px;
  margin-right: 5px;
  margin-left: 20px;
}
.chart-legend ul {
  list-style-type: none;
  margin-top: 3px;
}

.chart-legend ul li {
  float: left;
  cursor: default;
  margin-left: 10px;
}

.industry-legend b {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
}

.industry-legend {
  display: flex;
  justify-content: center;
  margin-top: 31px;
}
.industry-legend ul {
  display: flex;
}
.industry-legend ul li {
  list-style: none;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.33px;
}
.dashspan {
  margin-right: 5px;
  font-size: 25px;
  position: relative;
  bottom: -2px;
}
.vline {
  border-left: 1px solid #dee2e6 !important;
  padding: inherit;
  margin-left: 23px;
  height: 24px;
  padding-left: 25px;
}
.dashsize {
  margin-top: -12px;
  margin-left: 28px;
}
/*Formula Builder Starts*/
.onoffswitch .andor.onoffswitch-inner:after {
  content: "OR";
}

.onoffswitch .andor.onoffswitch-inner:before {
  content: "AND";
}

.queryvalue-tooltip {
  text-overflow: ellipsis;
  display: inherit;
}

.tabsSmall .nav-tabs {
  display: -ms-flexbox;
}

.modal-dialog.modal-95w {
  width: 95vw;
  max-width: none !important;
}

/* Trend Graph Related CSS */
.calenderRightDivider {
  margin-left: 9px;
}
.calenderSpan {
  /* margin-right: -28px !important;
    margin-left: -20px !important; */
  display: flex;
  padding: 0px;
}
.calenderChooseOption {
  padding: 0px !important;
}
.calenderSpan span {
  padding-left: 14px !important;
  letter-spacing: -0.75px;
  margin-right: 1px !important;
}
.calenderSpan i {
  padding-right: 0px;
  margin-left: 2px;
}
.calenderPopover {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}
.calenderPopover .popover-body {
  width: inherit;
}

.calenderPopover .popover-body1 {
  width: 400px;
}
.calenderPopover .popContentI {
  width: 400px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
}

.popContentDisplay {
  display: flex;
  justify-content: space-between;
}

.chooseOption .calenderSpan span {
  /* padding-right: 0px; */
  padding-right: 10px;
}
.CalenderDatepickerLabel {
  color: rgb(99, 115, 129);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.CalenderDatepickerDate {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin-top: 1px;
}
/* I&I trend graph change*/
.popover-footer {
  background: #f9fafb;
  border-top: 1px solid #dfe3e8;
  padding: 15px;
}

.bs-popover-bottom {
  border: 1px solid #dfe3e8;
  border-radius: 16px;
  box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.15);
  max-width: inherit;
}
.analysisHrList {
  margin-right: 16px;
  max-width: 45%;
}

.analysisHrList:last-child {
  margin-right: 0;
}

.analyticsFilter {
  background: #fff;
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.1), 0 1px 0 0 #f4f6f8;
  /* display: flex;	
  align-items: center; */
  padding: 16px 24px;
}

.analyticsFilter a,
.analyticsFilter .chooseOption span,
.custom-icon-datePicker {
  color: #637381;
  font-family: graphik-medium;
  border: 1px solid #c4cdd5;
  border-radius: 20px;
  padding: 13px 17px 11px;
  letter-spacing: -0.25px;
  line-height: 16px;
  font-size: 14px;
  display: inline-block;
  margin-right: 12px;
  cursor: pointer;
}

.analyticsFilter .chooseOption .owl-item span {
  margin-right: 0;
}

.analyticsFilter .date-picker-holder {
  margin-right: 10px;
  width: 265px;
  margin-left: -0.5%;
  margin-top: -10px;
}

.analyticsFilter .custom-icon-datePicker {
  padding: 9px 32px 8px 18px;
  display: block;
  margin-right: 0;
  cursor: default;
  line-height: 14px;
}

.analyticsFilter .date-picker-holder .react-datepicker-wrapper {
  width: 100%;
  /* margin: 0 1.5%; */
}

.date-picker-holder .react-datepicker-popper {
  min-width: 130px;
}

.analyticsFilter .date-picker-holder .react-datepicker-wrapper .custom-icon-datePicker {
  width: 100%;
}

.date-picker-holder label {
  margin-bottom: 0;
  font-size: 12px;
  display: block;
}

.analyticsFilter .calender-placment {
  color: #919eab;
  font-size: 16px;
  top: 20px;
  right: 16px;
}

a.backPush {
  padding-top: 12.75px;
  min-width: 85px;
}

a.backPush svg {
  color: #919eab;
  margin-right: 4px;
  display: inline-block;
}

/* .analyticsFilter a.fwdPush {	
  border-radius: 50%;	
  padding: 12.75px 15.75px 11px;	
} */

.chooseOption {
  padding: 0 38px 0 22px;
}

.analyticsFilter .divider {
  height: 24px;
  border: 0;
  width: 1.5px;
  background: #dfe3e8;
  margin-right: 10px;
}

.chooseOption .owl-carousel .owl-nav [class*="owl-"] {
  border: 1px solid #c4cdd5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 28.5px;
  box-shadow: none;
}

.chooseOption .owl-carousel .owl-nav .owl-prev {
  left: -45px;
}

.chooseOption .owl-carousel .owl-nav .owl-next {
  right: -55px;
}

.chooseOption .owl-carousel .owl-nav .disabled {
  opacity: 0.5;
}

.analyticsFilter a:hover,
.analyticsFilter .chooseOption span:hover,
.analyticsFilter a.active,
.analyticsFilter .chooseOption span.active {
  background: rgba(1, 142, 255, 0.04);
  border: 1px solid rgb(1, 142, 255);
}

.analyticsFilter.no-whiteShadow .date-picker-holder {
  width: 126px;
  margin-left: 0;
  margin-top: -5px;
}

.analyticsFilter.no-whiteShadow {
  box-shadow: none;
  border-top: 1px solid #dfe3e8;
  border-bottom: 1px solid #dfe3e8;
  position: relative;
  display: flex;
  align-items: center;
}

.analyticsFilter.no-whiteShadow:after,
.analyticsFilter.no-whiteShadow:before {
  content: "";
  height: calc(100% + 2px);
  width: 32px;
  position: absolute;
  border-top: 1px solid #dfe3e8;
  left: -32px;
  border-bottom: 1px solid #dfe3e8;
  top: -1px;
}

.analyticsFilter.no-whiteShadow:after {
  left: auto;
  right: -32px;
}

.analyticsFilter.no-whiteShadow .chooseOption span {
  padding: 12px 17px 10px;
}

.withLabel .toggle-group input[type="checkbox"] {
  position: absolute;
  left: 10px;
}

.withLabel .toggle-group label {
  font-size: 14px;
  width: auto;
  padding-right: 0;
  position: relative;
  margin-bottom: 0;
  top: -7px;
  padding-left: 10px;
  color: #637381;
}

.withLabel .toggle-group label:before {
  content: "";
  position: absolute;
  width: 48px;
  height: 25px;
  left: -48px;
  top: -1px;
}

.batView .outcome-divider span.metric-digit {
  font-family: graphik-semibold;
}

.batView .outcome-divider span.metric-digit-text {
  font-family: graphik-semibold;
  font-size: 16px;
  letter-spacing: -0.35px;
  color: #212b36;
}

.batView .outcome-divider span.metric-details {
  margin-top: 14px;
  font-size: 11px;
  letter-spacing: -0.25px;
  line-height: 14px;
  height: auto;
}

.batView .outcome-divider span.metric-details b {
  font-family: graphik-semibold;
  display: block;
}

.batView .outcome-divider p.minht {
  margin-bottom: 5px;
}

.popover-footer {
  background: #f9fafb;
  border-top: 1px solid #dfe3e8;
  padding: 15px;
}

.bs-popover-bottom {
  border: 1px solid #dfe3e8;
  border-radius: 16px;
  box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.15);
  max-width: inherit;
}

.industryPopover {
  left: 36px !important;
}

.industryPopover .arrow {
  left: -36px !important;
}

.popContent {
  width: 900px;
  padding: 25px 28px 10px;
}

.popContent .form-check-label {
  font-size: 12px;
  font-family: graphik;
  letter-spacing: -0.25px;
  font-weight: normal;
  color: #212b36;
}

.popover .arrow {
  background: none !important;
}

.bs-popover-bottom .arrow::before {
  border-bottom-color: #dfe3e8 !important;
}
.analyticsFilter .chooseOption .owl-item {
  width: 150px;
}

#chartjs-tooltip .tooltip-multiple-different-clients {
  width: 281px;
}

#chartjs-tooltip .tooltip-multiple-same-clients {
  width: 257px;
}

#chartjs-tooltip .tooltip-multiple-same-clients p {
  border-bottom: 1px solid #dfe3e8;
  padding-bottom: 5px;
}

#chartjs-tooltip .tooltip-data ul {
  margin-top: 10px;
  margin-bottom: 0;
}

#chartjs-tooltip .tooltip-data li {
  display: flex;
  justify-content: space-between;
  margin-bottom: -14px;
}

#chartjs-tooltip .tooltip-multiple-different-clients .tooltip-data {
  overflow-y: auto;
  max-height: 100px;
}

#chartjs-tooltip .tooltip-multiple-same-clients .tooltip-data .multiple-same-data {
  overflow-y: auto;
  max-height: 70px;
  overflow-x: hidden;
}

#chartjs-tooltip .client-name {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-bottom: 5px;
  cursor: pointer;
}

#chartjs-tooltip .client-name:hover .tooltip-label-text {
  visibility: visible;
  opacity: 1;
}

#chartjs-tooltip .client-name .tooltip-label-text {
  visibility: hidden;
  display: flex;
  flex-flow: column;
  overflow-wrap: break-word;
  width: 180px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 65%;
  margin-left: -58px;
  opacity: 0;
  transition: opacity 0.3s;
}

#chartjs-tooltip .client-name .tooltip-label-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 25%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

#chartjs-tooltip .clients-group {
  border-bottom: 2px solid #e4e4e4;
  margin-bottom: 15px;
}

#chartjs-tooltip .clients-group:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.batView button.rwt__tab:disabled {
  opacity: 0.5;
  pointer-events: none;
}

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  color: #212b36;
  border-radius: 8px;
  padding: 16px;
  min-width: 160px;
  /* max-height: 350px; */
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translate(-50%, -120%);
  transform: translate(-50%, -120%);
  z-index: 1;
  /* overflow: scroll; */
}

div#chartjs-tooltip:after {
  content: "";
  height: fit-content;
  width: fit-content;
  border: 8px solid #ffffff;
  position: absolute;
  margin-left: -8px;
  margin-top: -8px;
  top: 100%;
  left: 50%;
  transform: rotate(45deg);
  z-index: 1;
  /* box-shadow: 2px 5px 5px 0px rgba(33, 43, 54, 0.1); */
}
.tooltip-left-align:after {
  top: 50% !important;
  right: 100% !important;
  left: 0% !important;
  margin-left: -10px !important;
  transform: rotate(0deg) !important;
  border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important;
  border-right: 10px solid #ffffff !important;
  border-left: none !important;
}
.tooltip-right-align:after {
  top: 50% !important;
  left: 100% !important;
  right: 0% !important;
  transform: rotate(0deg) !important;
  border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important;
  border-left: 10px solid #ffffff !important;
  border-right: none !important;
  margin-left: 0px !important;
}

.tooltip-title {
  color: rgb(33, 43, 54);
  font-family: graphik-medium;
  font-size: 12px;
  letter-spacing: -0.25px;
  margin-bottom: 18px;
  cursor: pointer;
}

.tooltip-data p {
  color: rgb(33, 43, 54);
  font-size: 24px;
  font-family: graphik-medium;
  letter-spacing: -0.35px;
  line-height: 28px;
  margin-bottom: 8px;
}

.tooltip-data p.multiple {
  font-size: 16px;
  margin-bottom: 0;
}

.tooltip-data p small {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: graphik;
  letter-spacing: -0.25px;
  line-height: 12px;
  vertical-align: middle;
}

.tooltip-data .viewDetails {
  color: rgb(1, 142, 255);
  font-size: 12px;
  font-family: graphik-medium;
  letter-spacing: -0.25px;
  cursor: pointer;
  padding-left: 0px;
}

.tooltip-data .viewDetails.multiple {
  font-size: 10px;
}

.analyticsFilter .chooseOption .owl-item:last-child {
  margin-right: 0 !important;
}
#chartjs-tooltip .tooltip-data .no-benchmark-value {
  min-width: 183px;
  font-size: 12px;
}
.calenderPopover .popover-footer .btn-sm {
  padding: 0.367rem 1rem;
  border-radius: 2rem;
}
.calenderPopover .popover-footer .btn-sm.btn-primary,
.calenderPopover .popover-footer .btn-sm.btn-primary:active,
.calenderPopover .popover-footer .btn-sm.btn-primary:focus,
.calenderPopover .popover-footer .btn-sm.btn-primary:focus:active,
.calenderPopover .popover-footer .btn-sm.btn-primary:disabled:hover {
  background: rgb(161, 0, 255);
  border: 1.5px solid rgb(161, 0, 255);
}
.calenderPopover .popover-footer .btn-sm.btn-primary:hover {
  background: rgb(179, 51, 255);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
  border: 1.5px solid rgb(179, 51, 255);
}
/* Phase 2 BAT Enhancement Style Changes End*/

.mb-10 {
  margin-bottom: 10px;
}

.emptyView {
  margin-top: 40px;
  margin-bottom: 64px;
}
/* header navbar new styles starts here */
.navbar.newBanner {
  height: 64px;
  background: rgb(128, 0, 204);
  box-shadow: 0px 2px 16px 0px rgba(33, 43, 54, 0.3);
  padding: 0 24px;
}
.landingNav.newBanner a.navbar-brand h1 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  /* opacity: 0.7; */
}
.navbarBat.newBanner a.navbar-brand:after,
.navbar.navbarBat.newBanner .select-data:after {
  background: rgb(223, 227, 232);
  border-radius: 0.5px;
  border: none;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.navbarBat.newBanner a.navbar-brand.synops-analytics:after {
  display: none;
}
.navbar.navbarBat.newBanner .select-data select {
  background: transparent;
  color: rgb(255, 255, 255);
  font-size: 13px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.25px;
  opacity: 0.7;
}
.navbar.navbarBat.newBanner .select-data select option {
  /* background: #018eff; */
  color: rgba(1, 142, 255, 0.7);
}
.navbar.navbarBat.newBanner .select-data select option:hover {
  color: #018eff;
  /* background: #ffffff; */
}
.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link.active,
.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link:hover {
  background-color: transparent;
  color: rgb(255, 255, 255) !important;
  font-size: 13px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.25px;
  opacity: 1;
}
.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link {
  background-color: transparent;
  color: rgb(255, 255, 255) !important;
  font-size: 16px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.25px;
  opacity: 0.7;
}
.navbarBat.newBanner.navbar-default .navbar-nav > li > a.active::after,
.navbarBat.newBanner.navbar-default .navbar-nav > li > a:hover::after {
  display: none;
}
.navbarBat.newBanner ul.navbar-nav,
.navbarBat.newBanner a.navbar-brand {
  display: flex;
  align-items: center;
}
.navbarBat.newBanner .header-selectclient .client-select-dropdown .client-name {
  color: rgb(255, 255, 255);
  opacity: 0.7;
  font-family: Graphik-Semibold;
  font-size: 16px;
}
.navbarBat.newBanner span.profileImg,
.navbarBat.newBanner.navbar-default .navbar-nav > li.logged-img > a.nav-link {
  background: transparent;
  opacity: 1;
}
.navbarBat.newBanner .select-data > div:after {
  color: #fff;
  opacity: 0.7;
}
.business-filter {
  border-top: none;
}
.client-card .client-listing .card:hover::after {
  display: block;
  content: "";
  background: linear-gradient(#a100ff, #00baff);
  position: absolute;
  left: -2px;
  top: -2px;
  bottom: -2px;
  right: -2px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  z-index: -1;
}
/* header navbar new styles ends here */
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  #root .landingHome {
    background-image: url(../Images/globe1280.svg) !important;
  }
  .navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link,
  .navbarBat.newBanner .header-selectclient .client-select-dropdown .client-name {
    font-size: 13px;
    font-family: "graphik-medium";
  }
}

/* Approval popup & Reports checkbox alignment css starts here */
.approvalPopup-check-top {
  margin-top: 35px;
}
.dealData-check-top {
  margin-top: 38px;
}
/* Approval popup & Reports checkbox alignment css ends here */

.navbarBat.newBanner.navbar-default .navbar-nav > li.header-selectclient {
  margin-right: 24px;
  padding-right: 24px;
}
.navbarBat.newBanner.navbar-default .navbar-nav > li {
  margin-right: 4px;
  padding: 0px;
}

.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link,
.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link a,
.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link.active,
.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link:hover,
.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link.active:hover,
.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link.disabled-navlink {
  color: rgb(255, 255, 255) !important;
  font-size: 14px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.25px;
  opacity: 1;
  background: rgba(255, 255, 255, 0.2);
  line-height: 64px;
  padding: 0px 22px !important;
}
.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link,
.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link a,
.navbarBat.newBanner.navbar-default .navbar-nav > li > a.notification-link:hover,
.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link.disabled-navlink {
  background: transparent;
  color: rgba(255, 255, 255, 0.7) !important;
}
.main-component {
  margin-left: 110px;
}
.pt-60 {
  padding-top: 60px !important;
}

/* New Scrollbar CSS */
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar:horizontal {
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dfe3e8;
  border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dfe3e8;
}
/* width */
body::-webkit-scrollbar {
  width: 6px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.userrequest-heading-left {
  align-items: center;
  z-index: 0;
  /* border-bottom: 1px solid rgb(223, 227, 232); */
  clear: both;
  padding: 0 55px 0 7px;
  display: block;
}

.nav-link.active {
  color: white;
  background-color: black;
  border-color: #dee2e6 #dee2e6 #fff;
}

.access-form {
  border-radius: 8px;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  padding-top: 13px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 13px;
  height: 375px;
  overflow: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.fill-details {
  border-radius: 8px;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 20px;
  margin-bottom: 40px;
  height: 253px;
  padding-top: 13px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 13px;
}

.fill-details-main {
  display: flex;
}

.fill-details-col-1 {
  flex: 1;
  margin-right: 16px;
}

.fill-details-col-2 {
  flex: 3;
}

.offering-dropdown {
  border-radius: 0px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  height: 33.99px;
  width: 150.67px;
}

.offering-dropdown::before {
  content: "" !important;
}

.offering-dropdown::after {
  display: none;
}

.offering-dropdown-item {
  text-align: left;
  font-size: 12px;
  width: 125.33px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.suboffering-dropdown-menu {
  min-width: 200px;
}

.client-dropdown {
  border-radius: 8px;
  border: 1px solid rgba(181, 192, 202, 1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  width: 417px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  margin-top: 16px;
}

.client-dropdown::after {
  margin-top: 10px;
  margin-right: 8px;
}

.client-dropdown-menu {
  width: 417px;
}

.client-dropdown-wrapper {
  max-height: 460px !important;
  overflow: auto;
  font-size: x-small;
}

.client-dropdown:hover {
  background-color: rgba(255, 255, 255, 1);
}

.client-dropdown-icon {
  opacity: 1;
  background-color: rgba(145, 158, 171, 1);
  margin-right: 20.5px;
}

.client-dropdown-item {
  opacity: 1;
  color: rgba(99, 115, 129, 1);
  font-family: "Graphik-Regular";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.25px;
  text-align: left;
  line-height: 14px;
  display: flex;
}

.client-dropdown-check {
  border-radius: 4px;
  border: 1px solid rgba(181, 192, 202, 1);
  opacity: 1;
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

.client-dropdown-header {
  opacity: 1;
  color: rgba(99, 115, 129, 1);
  font-family: "Graphik-Medium";
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.25px;
  text-align: left;
  line-height: 11px;
  margin-top: 10px;
  margin-left: 16px;
}

.client-dropdown-body {
  opacity: 1;
  color: rgba(21, 24, 27, 1);
  font-family: "Graphik-Regular";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.35px;
  text-align: left;
  line-height: 16px;
  margin-left: 16px;
  margin-bottom: 13px;
}

.tab-container {
  padding: 10px;
}

.access-form-header {
  opacity: 1;
  color: rgba(17, 24, 39, 1);
  font-family: "Graphik-Semibold";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -0.35px;
  text-align: left;
  line-height: 20px;
}

.access-table {
  border-radius: 5px 5px 0px 0px;
  border: 1px solid rgba(243, 244, 246, 1);
  opacity: 1;
  padding-left: 13.39px;
  opacity: 1;
  margin-top: 13px;
  overflow: auto;
}

.access-table th {
  background-color: rgba(243, 244, 246, 1);
}

.access-table table,
th,
td {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: "Graphik-Medium";
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.43px;
  text-align: center;
  line-height: 14px;
  border: 1px solid #dfe3e8;
  border-collapse: collapse;
  height: 33.99px;
}

.access-form-input {
  border: none;
  outline: none;
  height: 33.99px;
}

.fill-details-input {
  border-radius: 8px;
  border: 1px solid rgba(223, 227, 232, 1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  height: 56px;
  width: 80%;
  margin-top: 16px;
}

.fill-details-remarks {
  border-radius: 8px;
  border: 1px solid rgba(181, 192, 202, 1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  height: 128px;
  /* width: 937px; */
  margin-top: 16px;
  /* margin-left: 16px; */
  width: 100%;
}

.fill-details-success-msg {
  margin-right: 50px;
  font-size: 15px;
  color: #28a745;
  font-family: "Graphik-Medium";
  margin-top: 3px;
}

.guide-link {
  opacity: 1;
  color: rgba(0, 142, 255, 1);
  font-family: "Graphik-Medium";
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.25px;
  text-align: left;
  line-height: 12px;
  text-decoration: underline;
}

/** float container */
.float-container {
  border-radius: 8px;
  border: 1px solid rgba(223, 227, 232, 1);
  /* background-color: rgb(55, 65, 81); */
  box-sizing: border-box;
  margin-bottom: 16px;
  padding: 0 8px;
  position: relative;
  width: 100%;
  /** active label */
}
.float-container input {
  /* border: none;
	  font-size: 16px;
	  outline: 0;
	  padding: 16px 0 10px;
	  width: 100%; */

  border: none;
  outline: 0;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  /* height: 56px; */
  width: 100%;
  margin-top: 16px;
}
.float-container-remarks {
  height: 128px;
}
.float-container-cxloal {
  height: 56px;
}
.float-container textarea {
  border: none;
  /* font-size: 16px; */
  outline: 0;
  /* padding: 16px 0 10px;
	  width: 100%; */
  resize: none;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  height: 80px;
  /* width: 937px; */
  margin-top: 16px;
  /* margin-left: 16px; */
  width: 100%;
}
.float-container label {
  font-size: 16px;
  position: absolute;
  transform-origin: top left;
  transform: translate(0, 16px) scale(1);
  transition: all 0.1s ease-in-out;

  opacity: 1;
  color: rgba(21, 24, 27, 1);
  /* background-color: rgb(55, 65, 81); */
  font-family: "Graphik-Regular";
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.35px;
  text-align: left;
  line-height: 20px;
}
.float-container.active label {
  transform: translate(0, 4px) scale(0.75);
}

.char-count {
  opacity: 0.641397;
  color: rgba(99, 115, 129, 1);
  font-family: "Graphik-Regular";
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.25px;
  text-align: left;
  position: absolute;
  right: 13px;
  bottom: 11px;
}

.dark-nav-tabs .nav-link.active {
  color: white;
  background-color: black;
}

/* To position the datepicker at the bottom of the field */
.fd-report-datepicker-container .react-datepicker-popper[data-placement^="top"] {
  transform: none !important;
  top: 55px !important;
}
.fd-report-datepicker-container .react-datepicker {
  position: absolute !important;
  top: 0px !important;
}

.fd-report-datepicker-container .react-datepicker__day {
  height: 1.6rem !important;
}

.genesys-mxg-expand {
  max-height: 80% !important;
}
