.casestudies-section {
  padding: 2rem 1rem;
}
.casestudies-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.casestudies-heading h5 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
}
.casestudies-heading .addcasestudy-heading button {
  background: linear-gradient(-225deg, rgba(161, 0, 255) 0%, rgb(0, 186, 255) 100%);
  border-radius: 20px;
  height: 40px;
  color: #fff;
  width: 152px;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.welcome-casestudiesmsg {
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.welcome-casestudiesmsg h6 {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 24px;
  font-weight: 500;
  height: 16px;
  letter-spacing: -0.43px;
}
.welcome-casestudiesmsg span i {
  color: #00baff;
  font-size: 45px;
  margin-bottom: 1rem;
}
.addcaseStudy-modal .modal-dialog {
  max-width: 848px;
}
.casestudy-content {
  display: flex;
}
.casestudyimage-section {
  border-right: 1px solid #dfe3e8;
  padding-right: 25px;
  margin-right: 25px;
  width: 21%;
}
.casestudyfields-section {
  width: 79%;
}
.casestudy-cardlist {
  margin-top: 2rem;
}
.casestudy-cardlist .casestudy-card {
  height: 220px;
  position: relative;
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232);
  margin-bottom: 1.5rem;
}
.casestudy-cardlist .casestudy-card img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.casestudy-cardcharacter {
  position: absolute;
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);
  display: flex;
  border-radius: 0px 0px 8px 8px;
  justify-content: space-between;
  height: 52px;
  width: 100%;
  bottom: 0;
  color: #fff;
  padding: 0.75rem 1rem;
}
.casestudy-cardcharacter p {
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 8px;
  margin-bottom: 0px;
}
.casestudy-cardcharacter .character-limittext span {
  font-family: Graphik-Medium;
  font-size: 8px;
  font-weight: 500;
  letter-spacing: -0.18px;
  line-height: 8px;
}
.casestudy-morelink .morelink-icon,
.keycontactlist-more .morelink-icon {
  color: #fff;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}
.casestudy-morelink .morelink-icon:hover,
.keycontactlist-more .morelink-icon:hover {
  background: rgba(255, 255, 255, 0.24);
}
.casestudy-morelink .dropdown-menu,
.keycontactlist-more .dropdown-menu {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
  width: 129px;
  right: 0 !important;
  left: auto !important;
}
.casestudy-morelink .dropdown-menu .dropdown-item,
.keycontactlist-more .dropdown-menu .dropdown-item {
  color: #637381 !important;
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.casestudy-morelink .dropdown-menu .dropdown-item i,
.keycontactlist-more .dropdown-menu .dropdown-item i {
  margin-right: 5px;
}
.casestudy-morelink .dropdown-menu .dropdown-item:hover,
.keycontactlist-more .dropdown-menu .dropdown-item:hover {
  color: #212b36 !important;
  font-family: Graphik-Medium;
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 4px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
}
.casestudy-morelink .dropdown-menu .dropdown-item:hover i,
.keycontactlist-more .dropdown-menu .dropdown-item:hover i {
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(0, 186, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.casestudynotification-modal .modal-dialog {
  max-width: 620px;
}
.casestudynotification-body {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.casestudynotification-body .casestudynotification-icon i {
  font-size: 40px;
  color: #7ec344;
  font-weight: 100;
}
.casestudynotification-body.removeasset-modal .casestudynotification-icon i {
  color: #d5002f;
  font-weight: 100;
}
.casestudynotification-icon {
  padding-right: 25px;
  margin-bottom: 1rem;
}
.casestudynotification-body .casestudynotification-data p {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
}
.removeasset-modal .casestudynotification-data p {
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
}
.casestudystatus-modal .modal-dialog {
  position: relative;
  top: 0;
  width: 100%;
  margin: 0 auto;
}
.casestudystatus-modal .modal-body {
  padding: 0.5rem 1rem;
}
.casestudystatus-modal .modal-content {
  border-radius: 0 0 8px 8px;
}
.casestudystatus-modal .casestudynotification-body {
  margin-top: 0;
  justify-content: center;
}
.casestudystatus-modal .casestudynotification-icon {
  margin-bottom: 0;
  padding-right: 15px;
}
.casestudystatus-modal .casestudynotification-icon i {
  font-size: 27px;
}
.casestudystatus-modal .casestudynotification-data p {
  margin-bottom: 0;
  color: rgb(0, 145, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
}
.modal-footer.footer-btns button {
  width: 120px;
  height: 40px;
}
.casestudyimage-section span {
  display: block;
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-bottom: 10px;
  text-align: center;
}
.casestudy-count {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.casestudy-count span {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  height: 14px;
  letter-spacing: -0.25px;
  margin-right: 10px;
}
.modal-title.casestudy-title {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.35px;
}
.casestudycard-content p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 16px;
}
.casestudyimage-section img {
  border-radius: 100%;
  margin-bottom: 10px;
}

.casestudy-contactlist {
  display: flex;
  justify-content: space-between;
}
.keycontacts-list {
  display: flex;
  margin-bottom: 10px;
}
.keycontacts-list .keycontact-heading {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
  margin-right: 5px;
}
.keycontacts-list ul {
  margin: 0;
}
.keycontacts-list li {
  display: inline-block;
  color: rgb(1, 142, 255);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  border-right: 1px solid #dfe3e8;
  padding-right: 10px;
  margin-right: 10px;
  line-height: 14px;
}
.keycontacts-list li:last-child {
  border-right: none;
}
.csupdated-info {
  border-bottom: 1px solid #c4cdd5;
  margin-bottom: 10px;
}
.csupdated-info p {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 1rem;
}
.uploaded-copyrights p {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  text-align: right;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
}

.casestudy-modalimg {
  margin-bottom: 1.25rem;
}
.casestudy-modalimg img {
  border-radius: 8px;
}
.casestudy-controllers span {
  position: absolute;
  top: 50%;
  cursor: pointer;
}
.keycontactlist-more span {
}
.casestudy-controllers span i {
  color: #637381;
  font-size: 28px;
}
.casestudy-detailmodal .modal-content {
  overflow: visible;
}
.casestudy-controllers span.left-controller {
  left: -35px;
}
.casestudy-controllers span.right-controller {
  right: -35px;
}
.casestudy-statustag {
  height: 23px;
  width: 40px;
  background-color: #d5002f;
  position: absolute;
  right: 0;
  top: 16px;
}
.casestudy-statustag span {
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.43px;
  position: relative;
  left: 5px;
  top: -1px;
}
.casestudy-modalimg .close-excel {
  background-color: rgba(169, 169, 169, 0.7);
  top: 0;
  right: 0;
  position: fixed;
  z-index: 9999;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}
.casestudy-modalimg iframe.fullScreen,
iframe.fullScreen {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 999;
  margin: 0 auto;
}
.casestudy-modalimg .fullScreen {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 999;
  margin: 0 auto;
}
.modal-body-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-panel-toggle {
  position: absolute;
  width: 15px;
  height: 40px;
  top: 37%;
  border: 1px solid #bbb5b5;
  border-radius: 10px 0 0 10px;
  background-color: #bbb5b5;
  text-align: center;
  cursor: pointer;
  z-index: 100;
  opacity: 7.07;
  padding-top: 5px;
  right: 0;
}
.visible-filter-panel-toggle {
  right: 300px;
}
.visible-filter-panel {
  position: absolute;
  top: 20%;
  right: 0;
  height: 515px;
  width: 300px;
  background-color: white;
  z-index: 100;
  display: block;
  visibility: visible;
}
.visible-filter-panel #gph-filter-panel,
.visible-filter-panel #filter-panel,
.visible-filter-panel #filter-panel-drill {
  height: 515px;
  width: 300px;
}

.hide-filter-panel {
  position: absolute;
  visibility: hidden;
}

.sisense-css-module .tablewidget-widget-content #grid_paginate .paginate_button,
.sisense-css-module
  .tablewidget-widget-content
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button {
  display: inline-table !important;
  line-height: 1px !important;
}
