.asset-management-container {
  padding: 60px 24px;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.form-control {
  font-size: 14px;
}

.asset-management-container table {
  font-size: 14px;
}

.asset-management-container th {
  background-color: #f8f9fa;
  font-weight: 600;
}

.asset-management-container .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.asset-management-container .table td {
  vertical-align: middle;
}

.asset-management-container .form-control:disabled,
.asset-management-container .form-control[readonly] {
  background-color: #f8f9fa;
  opacity: 1;
}

.asset-management-container .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.asset-management-container .date-picker-wrapper {
  width: 100%;
}

.asset-management-container .react-datepicker-wrapper {
  width: 100%;
}

.status-deployed {
  color: #28a745;
}

.status-sunset {
  color: #dc3545;
}

.status-to-be-deployed {
  color: #ffc107;
}

.asset-management-container .custom-dropdown-asset {
  position: relative;
  width: 100%;
}

.custom-dropdown-asset .form-control {
  height: 30px;
}

.custom-dropdown-asset .dropdown-content {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.offerings-dropdown-item-container {
  display: inline-block;
  white-space: normal;
  word-wrap: break-word;
}

.pending-approval-header-btns-container {
  display: flex;
  text-align: right;
  align-items: center;
  gap: 1rem;
}

.pending-approval-header-btns-container > button {
  width: 90px;
  height: 30px;
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.asset-adoption-inventory-container {
  margin-top: 50px;
}

.header-selectclient .asset-inventory-dropdown {
  max-width: none !important;
  display: flex;
  align-items: center;
}

.navbarBat.newBanner .header-selectclient .asset-inventory-dropdown::after {
  right: 0;
  top: -3px;
}

.header-selectclient .asset-inventory-dropdown .client-name {
  overflow: hidden;
  white-space: nowrap;
  /* top: 4px; */
  cursor: pointer;
  display: inline-block;
  color: #212b36;
  padding-left: 0px;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-family: graphik-medium;
}

.header-selectclient .asset-inventory-dropdown .client-name:after {
  top: 2px;
  font-size: 16px;
  cursor: pointer;
}

.header-selectclient .asset-inventory-dropdown .client-select-list {
  left: 0;
  box-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 38px;
  width: 290px;
  border-radius: 8px;
  z-index: 9999 !important;
  background-color: #fff;
  padding-right: 4px;
}

.navbar .select-data > div.asset-inventory-dropdown {
  padding-right: 21px;
}

.navbarBat.newBanner .header-selectclient .asset-inventory-dropdown .client-name {
  color: rgb(255, 255, 255);
  opacity: 0.7;
  font-family: Graphik-Semibold;
  font-size: 16px;
}

@media screen and (min-width: 1280px) and (max-width: 1365px) {
  #root .landingHome {
    background-image: url(../Images/globe1280.svg) !important;
  }
  .navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link,
  .navbarBat.newBanner .header-selectclient .asset-inventory-dropdown .client-name {
    font-size: 13px;
    font-family: "graphik-medium";
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.popup-content h3 {
  margin: 0;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
}

.popup-content p {
  margin-bottom: 20px;
  font-size: 16px;
}

.popup-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.popup-actions .btn {
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
}

.asset-management-container .btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
}

.asset-management-container .btn-secondary {
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
}

.asset-management-container .btn-primary:hover,
.asset-management-container .btn-secondary:hover {
  opacity: 0.9;
}

.asset-management-container .btn-primary:focus,
.asset-management-container .btn-secondary:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}

/* tooltip */
#assetStatusTooltip,
#synopsClassificationTooltip {
  text-align: left !important;
  z-index: 9999 !important;
  max-width: 500px;
}

.asset-tooltip {
  text-align: left !important;
  z-index: 9999 !important;
  max-width: 300px;
}

.asset-management-container .input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Adjust table cell padding to accommodate error message */
.asset-management-container td {
  padding: 8px;
  vertical-align: top;
}

/* Style for invalid input */
.asset-management-container .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: 0.75rem;
  background-image: none !important;
}

.invalid-feedback {
  text-align: left !important;
}

.header-buttons {
  display: flex;
  gap: 5px;
}

.approval-header {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.asset-action-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.asset-action-button {
  background-color: transparent !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.asset-action-button:disabled {
  opacity: 0.4;
}

.asset-action-button > i {
  color: #018eff;
}

.asset-action-button:focus {
  box-shadow: none !important;
}

/* custom dropdown */
.custom-dropdown-asset .dropdown-option {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.custom-dropdown-asset .dropdown-option:hover {
  background-color: #f8f9fa !important;
}

/* table custom styles */
.asset-table-container {
  margin-top: 20px;
  border: 1px solid #bdc3c7;
  height: 440px;
  width: 100%;
  overflow: auto;
  position: relative;
  background-color: #fff;
}

.asset-table-row,
.asset-table-header {
  display: flex;
  width: 100%;
  width: max-content;
}

.asset-table-header {
  display: flex;
  width: max-content;
  background-color: #f5f7f7;
  border-bottom: 1px solid #bdc3c7;
  position: sticky;
  top: 0;
  z-index: 100;
}

.asset-table-header .asset-table-cell {
  color: rgba(0, 0, 0, 0.54);
  font-weight: 600;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    Helvetica Neue, sans-serif;
}

.asset-table-header .asset-table-cell:not(:last-child) {
  border-right: 1px solid rgba(189, 195, 199, 0.5);
}

.asset-table-body {
  width: max-content;
}

.asset-table-body::-webkit-scrollbar {
  width: 6px;
}

.asset-table-row {
  border-bottom: 1px solid #d9dcde;
}

.asset-table-body .asset-table-row {
  background: #fff;
}

.asset-table-row .asset-table-cell {
  font-size: 12px;
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    Helvetica Neue, sans-serif;
  font-weight: 400;
}

.asset-table-cell {
  padding: 6px 8px;
  display: flex;
  align-items: center;
  word-break: break-word;
  width: 200px;
}

.asset-table-cell > div:first-child {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.asset-table-cell:nth-child(2) {
  width: 200px;
}

.asset-table-cell:nth-child(4) {
  width: 150px;
}

.asset-table-cell:nth-child(5) {
  width: 150px;
}

@media screen and (max-width: 1700px) {
  .asset-table-cell:nth-child(1) {
    width: 150px;
  }
  .asset-table-cell:nth-child(3) {
    width: 150px;
  }
  .asset-table-cell:nth-child(6) {
    width: 180px;
  }
  .asset-table-cell:nth-child(7) {
    width: 150px;
  }
  .asset-table-cell:nth-child(8) {
    width: 120px;
  }
  .asset-table-cell:nth-child(10) {
    width: 130px;
  }
}

.asset-table-cell:nth-child(11) {
  width: 80px;
  justify-content: center;
}

@media screen and (min-width: 1700px) {
  .asset-table-cell:nth-child(1) {
    min-width: 150px;
  }
  .asset-table-cell:nth-child(3) {
    min-width: 150px;
  }
  .asset-table-cell:nth-child(6) {
    min-width: 180px;
  }
  .asset-table-cell:nth-child(7) {
    min-width: 150px;
  }
  .asset-table-cell:nth-child(8) {
    min-width: 120px;
  }
  .asset-table-cell:nth-child(10) {
    min-width: 130px;
  }
}

.asset-table-cell:nth-child(9) {
  width: 250px;
}

.custom-dropdown-asset,
.asset-table-cell .react-datepicker__input-container .form-control {
  height: calc(1.5em + 0.75rem + 2px);
}

.custom-dropdown-asset .dropdown-header {
  font-size: 12px;
  overflow: hidden;
  padding: 0.375rem;
  border-radius: 0.25rem;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #ced4da !important;
  text-align: left;
}

.asset-management-container .input-container input,
.asset-table-cell .react-datepicker__input-container .form-control {
  font-size: 12px;
  padding: 0.375rem;
}

.asset-table-actions-cell {
  position: sticky;
  right: 0;
  z-index: 99;
  background-color: white; /* Match your table background */
}

.asset-table-header .asset-table-actions-cell {
  position: sticky;
  right: 0;
  z-index: 1001; /* Higher than regular header to stay on top */
  background-color: #f5f7f7; /* Match your header background */
}

.asset-table-actions-cell::before {
  content: "";
  position: absolute;
  left: -8px;
  top: 0;
  bottom: 0;
  width: 8px;
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.05));
}

.asset-table-truncate-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* datepicker position fix */
.asset-table-date-cell {
  position: relative;
}

.asset-table-date-cell .react-datepicker-popper {
  transform: none !important;
  top: 28px !important;
  left: 17px !important;
}

.asset-table-date-cell .react-datepicker__close-icon {
  top: 6px !important;
}

/* pagination */
.asset-pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
  gap: 10px;
  margin-right: 1rem;
}

.asset-pagination-button {
  border: none;
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.asset-pagination-button > img {
  height: 1rem;
  width: 1rem;
}

.asset-pagination-button:first-child::before,
.asset-pagination-button:last-child::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 1rem;
  background-color: #637381;
}

.asset-pagination-button:first-child::before {
  left: 15px;
}

.asset-pagination-button:last-child::before {
  right: -2px;
}

.asset-pagination-button:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.asset-pagination-container .pagination-control {
  display: flex;
  align-items: center;
}

.asset-pagination-button:hover:not(:disabled) {
  background-color: #f0f0f0;
}

.pagination-info,
.asset-pagination-pages {
  color: rgba(0, 0, 0, 0.65);
  letter-spacing: 0.5px;
  font-size: 14px;
}

.asset-pagination-button-prev {
  transform: rotate(180deg);
}

.asset-pagination-button:disabled {
  opacity: 0.3;
}

.custom-dropdown-asset .dropdown-option.highlighted {
  background-color: #f8f9fa !important;
}

.custom-dropdown-asset .dropdown-header:focus-visible,
.custom-dropdown-asset .dropdown-header:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border-color: #80bdff;
  outline: none;
}

.mdl-body {
  padding: 0px;
}

.btn-container {
  display: flex;
  gap: 8px;
  padding-left: 32px 0;
  margin-left: 16px;
}

.pri-btn,
.sec-btn {
  min-width: 65px;
  height: 40px;
  box-sizing: border-box;
}

.ag-cell.active-column {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.asset-table-info-icon {
  margin-left: 0.5rem;
}
