.client-dropdown .dropdown-toggle {
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  border-radius: 20px;
  border: 1.5px solid rgb(1, 142, 255);
  padding: 12px 20px;
  color: #018eff;
  margin-left: 15px;
}

.client-dropdown .dropdown-toggle:hover {
  color: #018eff;
}

.client-dropdown .dropdown-toggle:after {
  border-bottom: 2px solid #018eff;
  border-right: 2px solid #018eff;
}

.client-dropdown .dropdown-menu {
  margin-top: 10px;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  padding: 8px 0;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.client-dropdown .dropdown-item {
  color: #637381 !important;
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
}

.client-dropdown .dropdown-item:hover {
  color: #637381;
  background-color: #f4f6f8;
}

.clientprojectModal .modal-dialog {
  max-width: 1000px;
}

.clientprojectModal .toggle-form .nav-tabs a:nth-last-child(2)::after {
  width: 214%;
}

.clientproject-tabcontent {
  display: flex;
  padding: 0 20px;
}

.clientproject-tabcontent .upload-section {
  padding-right: 25px;
}

.clientproject-tabcontent .choose-file {
  background: rgb(249, 250, 251);
  border: 1px solid rgb(213, 218, 223);
  height: 128px;
  width: 128px;
  border-radius: 50%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 25px;
}
.ColorpickerforClientProfile {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  position: relative;
  background-color: transparent;
}
.ColorpickerforClientProfile.noimage {
  margin-bottom: 26px;
}
.Choosecoloroptions svg {
  margin-top: 6px;
}

.ColorpickerforClientProfile::-webkit-color-swatch-wrapper {
  padding: 0;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.ColorpickerforClientProfile::-webkit-color-swatch {
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-top: 18px;
}
.imagecolorpicker {
  margin-top: 17px;
  margin-right: 28px;
  width: 41px !important;
  height: 41px !important;
}
.ColorpickerforClientProfile:focus {
  box-shadow: none !important;
}
.Choosecoloroptions {
  display: flex;
  position: relative;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 0px;
  text-align: center;
  margin: 0px 16px;
}

.clientproject-tabcontent .choose-file img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.clientfields-section {
  width: 100%;
}

.clientproject-tabcontent h4 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.clientproject-tabcontent p {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
}

.upload-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-wrapper .btn {
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
}

.clientproject-tabcontent .access-details {
  width: 100%;
  height: 430px;
  overflow-y: auto;
  padding-right: 20px;
}

.access-details .label-group {
  display: flex;
  justify-content: space-between;
}

.notification-content {
  display: flex;
}

.notification-data h3 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 18px;
  font-weight: 500;
}

.notification-data {
  width: 80%;
}

.notification-data p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  line-height: 28px;
}

.notification-status i {
  font-size: 50px;
  color: #7ec344;
}

.notification-status {
  padding-right: 25px;
}

.modal-footer .btn.notification-btn {
  background: rgb(126, 195, 68);
  border-radius: 24px;
  height: 48px;
  width: 134px;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  border: none;
}

.main-component .search-nav .search-alpha .nav-item i {
  margin-right: 5px;
}
/* Tab steps custom css starts here. created by Srihari 8th Sep 2020 */
.clientprojectModal .toggle-form .nav-tabs a::before {
  border-color: rgb(162, 185, 196);
  color: #fff;
  background: rgb(162, 185, 196);
  height: 27px;
  width: 27px;
  box-shadow: 0px 0px 0px 8px #d4e3e8;
  padding: 0;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.clientprojectModal .toggle-form .nav-tabs a.active::before,
.clientprojectModal .toggle-form .previousActive.nav-tabs a.active::before {
  border-color: rgb(66, 180, 238);
  color: #fff;
  background: rgb(66, 180, 238);
  height: 27px;
  width: 27px;
  box-shadow: 0px 0px 0px 8px rgb(212, 235, 247);
  padding: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clientprojectModal .toggle-form .nav-tabs a::after {
  height: 20px;
  width: 210%;
  top: 12px;
  background: rgb(219, 230, 233);
}

.clientprojectModal .tabsSmall .nav-tabs .nav-link {
  color: rgb(107, 137, 151);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  margin: 0 80px 12px;
  cursor: default;
}

.clientprojectModal .tabsSmall .nav-tabs .nav-link.active,
.clientprojectModal .tabsSmall .previousActive.nav-tabs .nav-link.active {
  color: rgb(66, 180, 238);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}

.clientprojectModal .tabsSmall .previousActive.nav-tabs .nav-link {
  color: rgb(126, 195, 68);
}

.clientprojectModal .toggle-form .previousActive.nav-tabs a::before {
  background: rgb(126, 195, 68);
  border-color: rgb(162, 185, 196);
  box-shadow: 0px 0px 0px 8px rgb(229, 243, 218);
  margin-bottom: 10px;
}

.clientprojectModal .toggle-form .previousActive.nav-tabs a::after {
  background-image: -webkit-linear-gradient(
    to right,
    rgb(229, 243, 218) 50%,
    rgb(221, 238, 243) 50%
  );
  background-image: linear-gradient(to right, rgb(229, 243, 218) 50%, rgb(221, 238, 243) 50%);
  background-image: -ms-linear-gradient(to right, rgb(229, 243, 218) 50%, rgb(221, 238, 243) 50%);
}
/* Tab steps custom css ends here. created by Srihari 8th Sep 2020 */
.upload-logowrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 128px;
  height: 128px;
}

.upload-logowrapper .btn {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  margin-top: 16px;
}

.upload-logowrapper-max-size-text {
  font-size: 9px;
  margin-top: 4px;
  color: #c2c2c2;
}

.upload-logowrapper-max-size-text:last-child {
  margin-top: 0;
}

.choose-image .errorMsg {
  position: absolute;
  bottom: 128px;
}

.upload-logowrapper .btn img {
  width: 20px;
  height: 20px;
  display: block;
  margin: 0 auto;
}

.clientprojectModal .modal-footer .btn {
  width: 120px;
  height: 48px;
  font-size: 16px;
}

.access-details .upload-btn-wrapper {
  margin-bottom: 15px;
  border-color: #c4cdd5;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.access-details .upload-btn-wrapper .btn {
  position: relative;
  color: #637381;
  font-family: graphik;
  display: flex;
  align-items: center;
}

.access-details .upload-btn-wrapper .btn i {
  margin-right: 10px;
}

.access-details .upload-btn-wrapper .btn p {
  margin-bottom: 0;
  font-family: graphik;
}

.onboard-btn {
  display: flex;
  justify-content: flex-end;
}

.choose-image {
  width: 128px;
  height: 128px;
  z-index: 1;
}

.upload-logowrapper {
  position: absolute;
  top: 0px;
  overflow: hidden;
}

.choose-image .upload-logowrapper {
  display: none;
}

.choose-image:hover .upload-logowrapper {
  display: block;
  z-index: 2;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 50%;
  height: 128px;
}

.choose-image:hover .upload-logowrapper .btn {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
}

.clientfields-section .border-group .form-control {
  height: 17px;
}
/* View Client Project Start */
.view-projects {
  margin-top: 0;
  padding: 0.5em 1em 1em;
  /* background-color: #fff; */
}

.view-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.viewproject-buttons {
  display: flex;
  justify-content: space-between;
}

.view-search .back-btn {
  border-radius: 20px;
  border: 1px solid #008eff;
  height: 40px;
  width: 95px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: rgb(0, 142, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}

.view-search .back-btn i {
  margin-right: 10px;
}

.searchFilter input[type="search"] {
  background: #fff
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAIAAABL1vtsAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAJUSURBVDhPnZPfb9JQFMcPraysrjAmjIQSNohOQF4gBk1EnPFhTxr9J01MTGaiMVFjRDZHgmT4QJSBQpgDAbtWVgYrpcFbejFQVwx+Hnrv+d7e7/11jknqy6Bj0BX5RlWQACgn61y+yFAmPHIuUxaK8CW5Vy4J06Ym0s5evhcPOi5gQccfC7n2KfU6L6Kl0RyKWXJZFwD63eMO11PUcRMduBPf9NBqfxrNQml+fLN9cAZAOq5e24r4mckFO9XdnXz+WAaT2Xfr7ta63kW1kMqpx3u8ZPDHCLmWefu8eAYLK5v3E4FFrGoQAPV0jkf7d0WM5iPMbCwRd5HQ59PZMtbGEFCtlHoAi+5YyGi+Bh2+4bEDSIdHhSGWNAiuJaLrYrw+FivGWP2+ZYCh2GhgQYPgTtAtgtXu1OKZ2OxL6CuLp1qIQXcxBzRlxr0JCIZW1e5pW4tnoggdNYnI6XUJ1smgRmjW1aSazfBrpYUahvVoMYYAr9OFSqBVTv8YZaExXLZSQ2+x6g6gvJ2AgIXg7aAFXVJh50MBva4B3aPMK5RaYAlHNyisYdRjOSKRMDpNn0++eLevqzEVmTtIPX1fF4dAr29EL5FYHjMus0F992Umf6J2abvTv7bCrrrpXv2n8KvwrcmNdkdRpCQpwHgePbiunn3MRLEPxUo2kyyK0nTyqVBM+GYsKueeoFJC4bTLhIXGoH1YrZa/i10ttDB+79oVt220e0X4nHq239a5/GXxD85xmdcCoXeZL8FHkPZQ4mHUpj5thyvz/7MLDUUo5kp0KOahfwPCFwI2lQhyzAAAAABJRU5ErkJggg==)
    no-repeat scroll 20px !important;
  padding: 5px 30px 5px 40px !important;
  background-size: 14px !important;
  font-size: 14px !important;
  height: 40px;
  line-height: 13px;
  width: 385px !important;
}

.addproject-btn .btn {
  border-radius: 20px;
  border: 1px solid rgb(1, 142, 255);
  height: 40px;
  width: 124px;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}

.viewproject-buttons .btn-group {
  border-radius: 4px;
  border: 1px solid rgb(194, 205, 214);
  background-color: #fff;
}

.viewproject-buttons .btn-group .btn {
  color: rgb(95, 115, 131);
  font-family: Graphik-Medium;
  font-size: 14px;
  width: 114px;
}

.viewproject-buttons .btn-group .btn:hover,
.viewproject-buttons .btn-group .btn.active {
  background: #e9f6ff;
  color: #008eff;
}

.viewproject-buttons .btn-group .btn:focus {
  box-shadow: none;
}

.view-search .back-section {
  display: flex;
  align-items: center;
}

/* view project accordion css starts here */
.view-project-accordion {
  position: relative;
  padding-top: 26px;
}

.view-project-accordion #accordion {
  border: none;
  max-height: none;
  overflow: initial;
}

.view-project-accordion #accordion .card {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  margin-bottom: 12px;
  padding: 2px;
}

.view-project-accordion #accordion .card .card-header {
  border: none;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px 14px 54px;
}

.view-project-accordion #accordion .card .card-body {
  padding: 0 20px 0px 46px;
  position: relative;
}

.view-project-accordion .header-content {
  display: flex;
  align-items: center;
}

.view-project-accordion .header-content h6 {
  margin: 0;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
}

.view-project-accordion .header-content ul {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  margin: 0;
  margin-top: 5px;
}

.view-project-accordion .header-content ul li {
  padding-right: 20px;
  margin-right: 20px;
  position: relative;
  display: inline-block;
}

.view-project-accordion .header-content ul li:first-child {
  padding-left: 0;
}

.view-project-accordion .header-content ul li:last-child {
  padding-right: 0;
}

.view-project-accordion .header-content ul li::after {
  content: "";
  position: absolute;
  height: 16px;
  width: 2px;
  background-color: #ddd;
  display: inline-block;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.view-project-accordion .header-content ul li:last-child::after {
  display: none;
}

.view-project-accordion .header-right-info span {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.view-project-accordion .header-right-info .toggle-group {
  display: flex;
  align-items: center;
}

.header-right-info .onoffswitch .onoffswitch-inner:before,
.header-right-info .onoffswitch .onoffswitch-inner:after {
  height: 21px;
}

.header-right-info .onoffswitch .onoffswitch-switch {
  height: 23px;
}

.view-project-accordion .header-right-info {
  display: flex;
  align-items: center;
}

.view-project-accordion .header-right-info .status {
  border-radius: 16px;
  height: 32px;
  width: 123px;
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-project-accordion .header-right-info .status.project-approved {
  background-color: rgb(229, 243, 218);
  color: rgb(82, 134, 38);
}

.view-project-accordion .header-right-info .status.project-rejected {
  background-color: rgb(254, 230, 231);
  color: rgb(186, 97, 99);
}

.view-project-accordion .header-right-info .status.project-awaiting {
  background-color: rgb(255, 244, 213);
  color: rgb(230, 176, 25);
}

.view-project-accordion .header-right-info span {
  margin-left: 18px;
}

.view-project-accordion .card-header .card-link {
  position: relative;
  width: 75%;
}

.view-project-accordion .card-link::before,
.view-project-accordion .card-link.collapsed::before {
  content: "\F0DA";
  position: absolute;
  top: 45%;
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: #637381;
  line-height: 2px;
  left: -22px;
  transform: rotate(0deg);
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
}

.view-project-accordion .card-link::before {
  transform: rotate(90deg);
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
}

.view-project-accordion .card .card-body {
  display: flex;
  justify-content: space-between;
}

.view-project-accordion .card .card-body .left-info {
  width: 70%;
  margin-bottom: 14px;
}

.view-project-accordion .card .card-body .right-info {
  width: 30%;
  margin-bottom: 14px;
}

.view-project-accordion .card .card-body .right-info h6 {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
  margin-bottom: 8px;
}

.view-project-accordion .card .card-body .right-info p {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 16px;
  margin: 0;
}

.view-project-accordion .card .card-body .left-info .roles-data p,
.view-project-accordion .card .card-body .left-info .roles-data ul li {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
  margin: 0;
}

.view-project-accordion .card .card-body .left-info .roles-data ul {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.view-project-accordion .card .card-body .left-info .roles-data ul li {
  color: rgb(99, 115, 129);
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(223, 227, 232);
  padding: 5px 6px;
  display: flex;
  align-items: center;
  margin: 4px 8px 4px 0px;
}

.view-project-accordion .card .card-body .left-info .roles-data ul li span {
  padding-left: 4px;
}

.view-project-accordion .card .card-body .left-info .roles-data ul li i {
  font-size: 20px;
}

.view-project-accordion .card .card-body .left-info .roles-data {
  display: flex;
  padding-left: 8px;
  align-items: center;
}

.view-project-accordion .card .card-body .left-info .roles-data p {
  width: 25%;
}

.roles-data .fa-user-circle:before,
.view-project-accordion .header-right-info span svg {
  color: #919eab;
}

.header-rightinfo-icons svg {
  font-size: 18px;
}

.view-project-accordion .no-data-found {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 24px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.view-project-accordion .no-data-found p::before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  background: rgb(219, 225, 230);
  height: 130px;
  width: 130px;
  top: 14px;
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
}

.no-data-found p {
  padding-top: 10%;
}

.client-select-list .searchFilter {
  padding: 12px 12px 6px 12px;
}

.client-select-list .searchFilter input[type="search"] {
  width: 256px !important;
  height: 32px;
  border: 1px solid rgb(223, 227, 232);
  border-radius: 16px;
}

.client-select-dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.client-select-dropdown .client-select-list {
  width: 280px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: absolute;
  top: 45px;
  left: 0;
  z-index: 11111;
  padding-bottom: 12px;
}

.client-select-list ul {
  margin: 0;
}

.client-select-list ul li {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  padding: 10px 12px 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.client-select-list ul li:hover {
  background: rgb(244, 246, 248);
}

.client-select-list-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
/* view project accordion css ends here */
.client-select-dropdown .client-logo {
  margin-right: 10px;
}

.client-select-dropdown .client-name {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 24px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.client-select-dropdown .client-name:after {
  content: "\F107";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  right: -25px;
  top: 4px;
  color: #212b36;
  font-size: 20px;
}

.client-select-list ul li img {
  margin-right: 5px;
}

.header-right-info .toggle-group input[type="checkbox"] {
  top: 10px;
}

.clientproject-tabcontent .form-group.disabled,
.clientproject-tabcontent .form-group.disabled .form-control,
.clientproject-tabcontent
  .form-group.disabled
  .selectsuboffering-dropdown.nested-dropdown-wrapper
  button.dropdown-toggle {
  background-color: #dfe3e8 !important;
  pointer-events: none;
}

.clientproject-tabcontent .form-group.border-group[disabled] {
  background-color: #dfe3e8;
  pointer-events: none;
}

.onboard-btns {
  display: flex;
}

.client-select-list .clientresult-list {
  max-height: 275px;
  overflow-y: auto;
  margin-right: 10px;
}

.view-project-accordion .card .card-body .left-info .roles-data {
  min-height: 40px;
}

.view-project-accordion .roledata-list {
  display: flex;
  width: 75%;
  flex-wrap: wrap;
}

/* React select style starts */
.react-select-custom .css-yk16xz-control {
  height: 17px;
  border: none;
  flex-wrap: inherit;
  min-height: inherit;
}

.react-select-custom .css-1pahdxg-control {
  height: 17px;
  border: none;
  flex-wrap: inherit;
  min-height: inherit;
  box-shadow: none;
}

.react-select-custom .css-g1d714-ValueContainer {
  padding: 0;
  height: 17px;
}

.react-select-custom .css-1wa3eu0-placeholder {
  margin: 0;
  top: 100%;
  font-size: 0.85rem;
  color: #495057;
}

.react-select-custom .css-1uccc91-singleValue {
  margin: 0;
  top: 100%;
  font-size: 0.85rem;
  color: #495057;
}

.react-select-custom .css-1okebmr-indicatorSeparator {
  display: none;
}

.react-select-custom .css-tlfecz-indicatorContainer {
  color: #495057;
}

#approvalEmailFile {
  display: none;
}

.approvalFileBtn {
  color: rgb(1, 142, 255);
  border: none;
  background: transparent;
  margin: 0;
  font-family: Graphik-Medium;
  font-size: 14px;
  cursor: pointer;
}

.filedrop-msg p {
  margin-bottom: 0px;
}
/* .fileupload-browse{
    position: absolute;
    margin-top: -13px;
    margin-left: -90px;
} */
.fileupload-browse .approval-attach {
  font-family: graphik;
  margin-left: 5px;
  color: rgb(99, 115, 129);
  font-size: 12px;
}

.access-details .upload-btn-wrapper {
  border: 2px dashed #c4cdd5;
  border-radius: 4px;
}

.upload-btn-wrapper .file-details h5 {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.31px;
  margin-bottom: 4px;
}

.upload-btn-wrapper .file-details p {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin-bottom: 0;
}

.upload-btn-wrapper .close {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAYAAACdkl3yAAAAAXNSR0IArs4c6QAAAWBJREFUOBGVkrtOwzAUhn0cQjqzVELAwEMgBm5bpYrAG/AgZUAMsMFLMLFBgyJ1A8qAeAUkBm5SF1ZKq9T4t+XIaV0nOYN9rp9y/phukv4RMbYbBuFJu735yWpYmj6vjLPxqWDsgW67/S/BxDIRvTUWo71Wa+OjCqvXe1kdjv7uhRDrxOibM2IXGEQCBTSUgWyI6pUMfhhvX3LOjqvCpiGYBUPKo61799iZTNgZonlruiAH+zvnakZj9OmD+SAzICRcMOSNsPCxjvkSxLB8NR3qcxqGLH4GbhcEeScIBRuGGDYPomqqw3FEYeNKqv6Tl6Svcnmi6PBiqCMjrNxnKa9L3/fOZlYzEFsTwMqeRgHkgpi/Y2vmemc5yAcx6/lgClQFUgajOhAfjP+Ohte2sEYTM+S60YM3hRpmweBytwUpniDinSoQAzYwNQtGmr5GWTZoxvHWu2mqcyfJ01oQNAf/DvAdLLXmDpgAAAAASUVORK5CYII=)
    no-repeat scroll 0 0 transparent;
  background-size: 12px 12px;
  height: 12px;
  width: 12px;
  opacity: 1;
  outline: none;
}

.upload-file-wrapper {
  padding: 19px 24px;
  justify-content: space-between !important;
}

/* toggle switch css */

.header-right-info .onoffswitch .onoffswitch-inner:before,
.header-right-info .onoffswitch .onoffswitch-inner:after {
  height: 32px;
}

.header-right-info .onoffswitch .onoffswitch-inner:before {
  background: #018eff;
}

.header-right-info .onoffswitch .onoffswitch-switch {
  height: 28px;
  top: 3px;
  width: 28px;
}

.header-right-info
  .toggle-group
  input[type="checkbox"]:checked
  ~ .onoffswitch
  .onoffswitch-label
  .onoffswitch-switch:before {
  content: "\f058";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  left: -1px;
  top: 4px;
  font-size: 29px;
  color: #018eff;
}
/* toggle switch css end */
.clientproject-tabcontent .border-group :disabled.form-control {
  padding-bottom: 0px; /*for disabled fields the text was getting hidden */
}

.clientfields-section .react-select-custom .css-1wa3eu0-placeholder {
  top: 35%;
  left: 2%;

  /* client dropdown text getting hidden in edit mode - still when we open the dropdown the search here text disappears */
}

.access-details .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  border-style: dashed;
}

.access-details .upload-btn-wrapper .btn {
  font-family: Graphik-Medium;
  font-size: 14px;
}

.access-details .upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.downloadEmail {
  cursor: pointer;
}

.disableDownloadEmail {
  pointer-events: none;
}

.access-details .border-group .form-control {
  padding: 0.375rem 0;
}

/* Onboarding Enchancements */
.custom-dropdown-search .searchFilter {
  padding: 12px 12px 6px 12px;
}

.custom-dropdown-search .searchFilter input[type="search"] {
  width: 100% !important;
  height: 32px;
  border: 1px solid rgb(223, 227, 232);
  border-radius: 16px;
}

.custom-dropdown-search {
  /* position: relative; */
  display: flex;
  align-items: center;
}

.custom-dropdown-search .search-select-list {
  width: 100%;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: absolute;
  top: 54px;
  left: 0;
  z-index: 11111;
  padding-bottom: 12px;
  border: 1px solid #ccc;
}

.search-select-list ul {
  max-height: 281px;
  overflow: scroll;
  overflow-x: hidden;
}

.search-select-list .noclients-message {
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  padding: 10px 15px;
}

.custom-dropdown-search ul {
  margin: 0;
}

.custom-dropdown-search ul li {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  padding: 10px 12px 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.custom-dropdown-search ul li:hover {
  background: rgb(244, 246, 248);
}

.custom-dropdown-search-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.custom-dropdown-search .custom-dropdownname {
  position: relative;
  width: 100%;
  font-size: 0.85rem;
}

.custom-dropdown-search .custom-dropdownname:after {
  content: "\F078";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  right: 5px;
  top: 0px;
}

.clientfields-section .form-group.border-group {
  height: 54px;
  position: relative;
}

.clientprojectModal .custom-dropdown-search-overlay {
  position: absolute;
}

.clientprojectModal .modal-content {
  overflow: initial;
}

.access-details .border-group .form-control::-webkit-input-placeholder {
  /* Edge */
  color: #dfe3e8;
}

.access-details .border-group .form-control:-ms-input-placeholder {
  /* Internet Explorer */
  color: #dfe3e8;
}

.access-details .border-group .form-control::placeholder {
  color: #dfe3e8;
}

.view-project-accordion .header-right-info .selecttooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.view-project-accordion .header-right-info .selecttooltip .selecttooltiptext {
  visibility: hidden;
  min-width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  font-size: 12px;
  letter-spacing: -0.19px;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  position: absolute;
  z-index: 1;
  top: -28px;
  line-height: 14px;
  right: -50px;
}

.view-project-accordion .header-right-info .selecttooltip .selecttooltiptext::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 45%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
  transform: rotate(0deg);
}

.view-project-accordion .header-right-info .selecttooltip:hover .selecttooltiptext {
  visibility: visible;
  opacity: 1;
}

.choose-image.hideLogoHover:hover .upload-logowrapper {
  background-color: transparent;
}

.choose-image.hideLogoHover:hover .upload-logowrapper .btn {
  background-color: transparent;
  font-size: 0;
  width: 128px;
  height: 128px;
}

.choose-image.hideLogoHover:hover .upload-logowrapper .btn img {
  display: none;
}

.modal-footer .btn-outline-secondary:disabled,
.modal-footer .btn-outline-secondary:disabled:hover {
  background-color: #f6f5f5;
  border: none;
  outline: none;
}
.header-rightinfo-icons.selecttooltip i {
  font-size: 20px;
  vertical-align: middle;
  position: relative;
  top: 2px;
}
